import baseBg from './base-background.jpg';
import logoPortl from '../portl-logo-black.png';
import uber from '../uberLogo.svg';
import lyft from '../lyftLogo.svg';
import plus from '../plusWhite.svg';

const theme = {
  primary: '#043650',
  secondary: '#169aff',
  ternary: '#ffffff',
  baseBg,
  logoPortl,
  logoRide: { uber, lyft },
  plus,
  welcomeInterimBg: '/PortlSafetyTips.webp',
  brightnessIcon: 'brightness_high.png',
  volumeIcon: 'volume.png',
  batteryIcon: 'battery_black_',
  welcomeBgColor: '#e7e7fb',
  appBackground: 'rgb(241,241,241)',
};

export default theme;
