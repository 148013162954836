import React, { useEffect } from 'react';
import { socket } from '../../socket';
import { getStrapiQuiz } from '../../api';
import { Background, OkayButton, Arrow } from './style';

export const STRAPI_NOTHING_QUIZ_ID = 2;

export const QuizInfo = ({
  onStepChange, setQuestions, _isMounted, clearTimer, onEnded,
}) => {
  useEffect(() => {
    const id = setTimeout(() => {
      onEnded();
    }, 30000);

    const getQuestions = async () => {
      const response = await getStrapiQuiz(STRAPI_NOTHING_QUIZ_ID);

      if (response.error || response.Question.length === 0) return onEnded();

      const opt = response.Question[0].Options;

      socket.once('startQuiz', () => {
        socket.emit('quizResultStarted', response.Question.length,
          [opt[0].numberOfVotes, opt[1].numberOfVotes, opt[2].numberOfVotes, opt[3].numberOfVotes]);
        onStepChange();
      });

      if (_isMounted) {
        setQuestions(response);
      }
      return null;
    };

    clearTimer();
    getQuestions();

    return () => {
      clearTimeout(id);
      socket.off('startQuiz');
    };

    // eslint-disable-next-line
      }, []);

  return (
    <Background src="/assets/NothingQuizIntro.jpg">
      <OkayButton onClick={onStepChange}>
        <p>Okay, let`s go!</p>
        <Arrow src="https://portl-assets.s3.amazonaws.com/nothing_arrow.svg" />
      </OkayButton>
    </Background>
  );
};
