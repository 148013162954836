import React, { useRef } from 'react';
import queryString from 'query-string';
import { serverUrl } from '../config';
import { generateCode } from '../utils/helper';
import Quiz from '../views/Quiz';

import {
  GAME_NAME, Question,
} from './QuizzleComponents/style';
import { ScorePage } from './QuizzleComponents/ScorePage';
import { HeaderComponent } from './QuizzleComponents/HeaderComponent';
import { QuizBegin } from './QuizzleComponents/QuizBegin';
import { QuizLeaderBoard } from './QuizzleComponents/QuizLeaderBoard';
import { StartScreen } from './QuizzleComponents/StartScreen';
import { OptionsComponent } from './QuizzleComponents/OptionsComponent';

const QuestionComponent = ({ text }) => (
  <Question>
    {text}
  </Question>
);

const NUM_OF_QUESTIONS = 10;

const Quizzle = ({ onEnded, clearTimer, resetTimer }) => {
  const parsed = queryString.parse(window.location.search);
  const IMEI = localStorage.getItem('IMEI');

  const privateCode = useRef(parsed.remoteControl || generateCode(15));

  return (
    <Quiz
      clearTimer={clearTimer}
      resetTimer={resetTimer}
      onEnded={onEnded}
      quizName={GAME_NAME}
      quizCategory="Entertainment: Video Games"
      qrCode={`${serverUrl}/rider/${GAME_NAME}/${IMEI}/${privateCode.current}`}
      privateCode={privateCode.current}
      numOfQuestions={NUM_OF_QUESTIONS}
      OptComponent={OptionsComponent}
      StartScreen={StartScreen}
      ExplainerScreen={QuizBegin}
      ScorePage={ScorePage}
      LeaderboardPage={QuizLeaderBoard}
      HeaderComponent={HeaderComponent}
      QuestionComponent={QuestionComponent}
    />
  );
};

export default Quizzle;
