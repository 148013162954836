import React from 'react';
import QRCode from '../../components/QRCodeWithData';
import { riderUrl } from '../../config';
import {
  Background, CongratsContainer, CongratsHeader, CongratsText, CongratsMugImg,
  QRCodeContainer, DripImg,
} from './style';

// eslint-disable-next-line import/prefer-default-export
export const WinComponent = ({
  appName, code, IMEI, isSocket,
}) => (
  <Background>
    <CongratsContainer>
      <CongratsHeader>congratulations!!!</CongratsHeader>
      <CongratsText>
        Turns out you have the luck of the Irish!
        <br />
        <br />
        On behalf of Portl, we&apos;d like to congratulate you for winning
        Four Leaf Fortune!
        <br />
        <br />
      </CongratsText>
      {(!isSocket)
        ? (
          <CongratsText>
            Scan this QR Code to claim your prize, and happy St. Patrick&apos;s Day to ye!
          </CongratsText>
        )
        : (
          <CongratsText>
            Please follow the instructions on your phone to claim your price,
            and happy St. Patrick&apos;s Day to ye!
          </CongratsText>
        )}
    </CongratsContainer>
    <CongratsMugImg src="/assets/four-leaf-fortune/glass_empty.webp" />
    <DripImg src="/assets/four-leaf-fortune/drip.webp" />
    {(!isSocket) && (
      <QRCodeContainer>
        <QRCode
          name="FourLeafFortuneWin"
          size={220}
          redirectUrl={`${riderUrl}/${appName}Win/${IMEI}/${code.current}`}
        />
      </QRCodeContainer>
    )}
  </Background>
);
