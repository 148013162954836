import React from 'react';
import {
  LegendContainer, Column, LegendText, Row, ExitModal,
} from './style';
import { PinIcon } from './components';

const LegendModal = (props) => {
  const { tourGuideCategories, setLegendModalVisible } = props;

  return (
    <LegendContainer>
      <Column>
        <Row>
          <PinIcon fill={tourGuideCategories[0]?.color} />
          <LegendText>{tourGuideCategories && tourGuideCategories[0].title}</LegendText>
        </Row>
        <Row>
          <PinIcon fill={tourGuideCategories[1]?.color} />
          <LegendText>{tourGuideCategories && tourGuideCategories[1].title}</LegendText>
        </Row>
      </Column>
      <Column>
        <Row>
          <PinIcon fill={tourGuideCategories[2]?.color} />
          <LegendText>{tourGuideCategories && tourGuideCategories[2].title}</LegendText>
        </Row>
        <Row>
          <PinIcon fill={tourGuideCategories[3]?.color} />
          <LegendText>{tourGuideCategories && tourGuideCategories[3].title}</LegendText>
        </Row>
      </Column>
      <Column>
        <Row>
          <PinIcon fill={tourGuideCategories[4]?.color} />
          <LegendText>{tourGuideCategories && tourGuideCategories[4].title}</LegendText>
        </Row>
        <Row>
          <PinIcon fill={tourGuideCategories[5]?.color} />
          <LegendText>{tourGuideCategories && tourGuideCategories[5].title}</LegendText>
        </Row>
      </Column>
      <ExitModal src="/assets/exit.png" onClick={() => setLegendModalVisible(false)} />
    </LegendContainer>
  );
};

export default LegendModal;
