import React from 'react';
import FlipGame from '../views/FlipGame';

import { HowToPlayImage } from './EastergEggGameComponents/style';
import { WinComponent } from './EastergEggGameComponents/WinComponent';
import { GameComponent } from './EastergEggGameComponents/GameComponent';

const HowToPlay = ({ handlePlayClick }) => (<HowToPlayImage onClick={handlePlayClick} />);

// Set startingSquares to game grid layout (5 x 3 in this case)
const generateRandomTiles = () => {
  // STATES
  // N - neutral
  // W - win
  // L1|L2|L3|L4|L5 - lose
  const setOfSquares = [
    'W', 'W', 'W', 'L1', 'L2', 'L3', 'L4', 'L5', 'N', 'N', 'N', 'N', 'N', 'N', 'N',
  ];
  const getRandInt = () => Math.floor(Math.random() * setOfSquares.length);

  const startingSquares = new Array(3);
  for (let i = 0; i < 3; i += 1) {
    startingSquares[i] = new Array(5);
    for (let j = 0; j < 5; j += 1) {
      const index = getRandInt();
      startingSquares[i][j] = {
        show: false,
        value: setOfSquares[index],
      };
      // remove element from array so it doesn't get used again
      setOfSquares.splice(index, 1);
    }
  }

  return startingSquares;
};

const EasterEggGame = ({ resetTimer }) => (
  <FlipGame
    appName="EasterEggGame"
    resetTimer={resetTimer}
    GameRenderComp={GameComponent}
    HowToPlayComponent={HowToPlay}
    WinComponent={WinComponent}
    winCondition={3}
    generateRandomTiles={generateRandomTiles}
  />
);

export default EasterEggGame;
