import React from 'react';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import {
  ImgWrapper, DescriptionContainer, FullScreen, Logo,
  // FullscreenStyled,
  PageWrapper, Text, LinksContainer, LinkWrapper, LogoContainer, PlayIcon,
} from './style';
import { trackMixpanel } from '../../analytics';

const VideosGrid = (props) => {
  const {
    data, fullScreen, currentVideo, link,
    // setFullscreen,
  } = props;
  const history = useHistory();

  const redirect = (page) => (history.replace(`/app/${page}`));
  const nextVideo = (video) => {
    switch (video) {
      case 'CSI': return 'SoHelpMeTodd';
      case 'SoHelpMeTodd': return 'FireCountry';
      case 'FireCountry': return 'CSI';
      default: return 'CSI';
    }
  };

  const playCSI = () => {
    redirect('CSI');
    trackMixpanel('CSI', data.blobURL, true);
  };

  const playSoHelpMeTodd = () => {
    redirect('SoHelpMeTodd');
    trackMixpanel('SoHelpMeTodd', data.blobURL, true);
  };

  const playFireCountry = () => {
    redirect('FireCountry');
    trackMixpanel('FireCountry', data.blobURL, true);
  };

  const playVideo = (type) => {
    switch (type) {
      case 'CSI':
        playCSI();
        break;
      case 'SoHelpMeTodd':
        playSoHelpMeTodd();
        break;
      case 'FireCountry':
        playFireCountry();
        break;
      default: playCSI();
    }
  };

  return (
    <>
      {
        fullScreen ? (
          <FullScreen>
            <div>
              <ReactPlayer
                url={link}
                playing
                controls
                height="100%"
                width="100%"
                onEnded={() => redirect(nextVideo(currentVideo))}
                loop={false}
              />
              {/* <FullscreenStyled
                src="/assets/fullscreen.png"
                onClick={() => setFullscreen(false)}
              /> */}
            </div>
          </FullScreen>
        )
          : (
            <PageWrapper>
              <ImgWrapper>
                <ReactPlayer
                  url={link}
                  playing
                  controls
                  height="100%"
                  width="100%"
                  onEnded={() => redirect(nextVideo(currentVideo))}
                  loop={false}
                />
                {/* <FullscreenStyled
                  src="/assets/fullscreen.png"
                  onClick={() => setFullscreen(true)}
                /> */}
              </ImgWrapper>
              <DescriptionContainer>
                <Text size="22px" lineHeight={1.7} bold>
                  {data && data.title}
                </Text>
                <Text size="22px" lineHeight={1.7}>
                  {data && data.description}
                </Text>
              </DescriptionContainer>
              <LinksContainer>
                <LinkWrapper
                  src={data.videos[0].img}
                  onClick={() => playVideo(data?.videos[0]?.video)}
                >
                  <PlayIcon src="/assets/portl-spotlight/play.png" />
                </LinkWrapper>
                <LinkWrapper
                  src={data.videos[1].img}
                  onClick={() => playVideo(data?.videos[1]?.video)}
                >
                  <PlayIcon src="/assets/portl-spotlight/play.png" />
                </LinkWrapper>
                <LinkWrapper
                  src={data.videos[2].img}
                  onClick={() => playVideo(data?.videos[2]?.video)}
                >
                  <PlayIcon src="/assets/portl-spotlight/play.png" />
                </LinkWrapper>
              </LinksContainer>
              <LogoContainer>
                <Logo src={data.introImg} />
              </LogoContainer>
            </PageWrapper>
          )
    }
    </>
  );
};

export default VideosGrid;
