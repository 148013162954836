import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { Form, Button } from 'react-bootstrap';

import './aboutDriver.css';

class LogoutButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordInput: undefined,
      askForPassword: false,
    };
    this.driverPassword = 'Portl';
    this.handleRequestLogout = this.handleRequestLogout.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleRequestLogout() {
    this.setState({ askForPassword: true });
  }

  handleInput(e) {
    this.setState({ passwordInput: e.target.value });
  }

  handleSubmit() {
    const { passwordInput } = this.state;
    const { driverLogout } = this.props;

    if (passwordInput === this.driverPassword) {
      console.log('Correct passphrase');
      driverLogout();
    } else {
      console.log('Incorrect passphrase');
      this.setState({ incorrectPass: true, passwordInput: '' });
    }
  }

  render() {
    const { theme } = this.props;
    const { primary, secondary, ternary } = theme;
    const { askForPassword, incorrectPass } = this.state;

    return (
      <>
        <div className="aboutDriverLogoutWrapper">
          <Button
            variant="secondary"
            type="submit"
            className="aboutDriverLogoutButton"
            style={{ backgroundColor: primary, color: secondary }}
            onClick={askForPassword ? this.handleSubmit : this.handleRequestLogout}
          >
            {askForPassword ? 'Confirm' : 'Logout'}
          </Button>
          {askForPassword
          && (
          <Form.Control
            type="text"
            // ref={f => this.form = f}
            onChange={this.handleInput}
            className="aboutDriverPasswordForm"
            style={{
              borderColor: primary,
              color: primary,
              backgroundColor: ternary,
            }}
            placeholder={incorrectPass ? 'Incorrect Password' : 'enter passphrase'}
          />
          )}
        </div>
        {incorrectPass && <p className="incorrectText">Incorrect Pasword</p>}
      </>
    );
  }
}

export default withTheme(LogoutButton);
