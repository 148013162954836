import React from 'react';
import QRCode from 'qrcode.react';
import { QRWrapper, StartButton } from './style';
import QuizBackground from '../../components/QuizBackground';

// eslint-disable-next-line import/prefer-default-export
export const StartScreen = ({ onClick, qrCode }) => (
  <QuizBackground src="/assets/movie-quiz-start.webp">
    <StartButton onClick={onClick}>
      GO!
    </StartButton>
    <QRWrapper>
      <QRCode
        value={qrCode}
        size={127}
        level="Q"
        renderAs="svg"
      />
    </QRWrapper>
  </QuizBackground>
);
