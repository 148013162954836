import React, { useState } from 'react';

import VideosGrid from '../components/VideosGrid';
import { data } from './CorusEntertainment/components';

const FireCountry = () => {
  const [fullscreen, setFullscreen] = useState(false);
  return (
    <VideosGrid data={data[2]} fullScreen={fullscreen} link="/assets/FireCountry.mp4" setFullscreen={setFullscreen} currentVideo="FireCountry" />
  );
};

export default FireCountry;
