import React from 'react';
import styled from 'styled-components';
import { trackMixpanel } from '../../analytics';

const Container = styled.div`
    background-color: #ffffff;
    text-align: center;
    padding: 0 60px;
`;

const ThumbsUpSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={68} height="65.465" viewBox="0 0 68 65.465">
    <defs>
      <filter id="4fnxb925za" width={68} height="65.465" x={0} y={0} filterUnits="userSpaceOnUse">
        <feOffset dy={3} />
        <feGaussianBlur result="blur" stdDeviation={3} />
        <feFlood floodOpacity=".161" />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#4fnxb925za)">
      <path fill="#1fb6ff" d="M28.124 2.7l-12.4 13.566a5.138 5.138 0 0 0-1.3 3.453v24.465a4.715 4.715 0 0 0 4.476 4.9h20.145a4.515 4.515 0 0 0 4.118-2.963l7.3-18.635c1.88-4.848-1.365-10.236-6.177-10.236H31.637l2.126-11.217a3.924 3.924 0 0 0-.918-3.355 3.132 3.132 0 0 0-4.721.022zM5.476 49.081a4.715 4.715 0 0 0 4.476-4.9V24.594a4.715 4.715 0 0 0-4.476-4.9A4.715 4.715 0 0 0 1 24.594v19.59a4.715 4.715 0 0 0 4.476 4.897z" transform="translate(8 4.38)" />
    </g>
  </svg>
);

const ThumbsDownSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={68} height="65.469" viewBox="0 0 68 65.469">
    <defs>
      <filter id="8d9r7zjbda" width={68} height="65.469" x={0} y={0} filterUnits="userSpaceOnUse">
        <feOffset dy={3} />
        <feGaussianBlur result="blur" stdDeviation={3} />
        <feFlood floodOpacity=".161" />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#8d9r7zjbda)">
      <path fill="#af161c" d="M23.533 49.382l12.379-13.567a5.138 5.138 0 0 0 1.3-3.453V7.9A4.715 4.715 0 0 0 32.733 3H12.609a4.464 4.464 0 0 0-4.1 2.963L1.216 24.6c-1.9 4.849 1.343 10.236 6.156 10.236h12.647l-2.127 11.216a3.924 3.924 0 0 0 .918 3.355 3.133 3.133 0 0 0 4.723-.025zM46.186 3a4.715 4.715 0 0 0-4.477 4.9v19.589a4.715 4.715 0 0 0 4.477 4.9 4.715 4.715 0 0 0 4.477-4.9V7.9A4.715 4.715 0 0 0 46.186 3z" transform="translate(8.34 3)" />
    </g>
  </svg>
);

const Question = styled.p`
    font-size: 20px;
    color: #272a2c;
    letter-spacing: -0.8px;
    line-height: 1.75;
    > span {
        font-size: 25px;
        font-weight: 600;
    }
`;

const FlexBox = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0;
`;
const RedText = styled.p`
    color: #af161c;
    font-size: 20px;
    font-weight: 500;
`;
const BlueText = styled(RedText)`
    color: #1fb6ff;
`;

const Dismiss = styled.p`
    padding: 5px;
    color: #757575;
    font-size: 20px;
    font-weight: 500;
`;
const FeedbackItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const mapGameToName = {
  PortlShorts: 'Portl Shorts',
  LoveToWin: 'Love To Win',
  MovieQuiz: 'The Quiz About Movies',
  NothingQuiz: 'The Quiz About Nothing',
  NewsArticle: 'News by Portl',
  PortlTips: 'Covid Tips by Portl',
};

const FeedbackNotification = ({ closeToast, gameName }) => {
  const handleFeedbackClick = (score) => {
    trackMixpanel('GameFeedback', { score, name: gameName });
    closeToast();
  };

  return (
    <Container>
      <Question>
        How was your experience with
        {' '}
        <span>{mapGameToName[gameName] || gameName}</span>
        {' '}
        ?
      </Question>
      <FlexBox>
        <FeedbackItem onClick={() => handleFeedbackClick(0)}>
          <ThumbsDownSvg />
          <RedText>bad</RedText>
        </FeedbackItem>
        <FeedbackItem onClick={() => handleFeedbackClick(1)}>
          <ThumbsUpSvg />
          <BlueText>great!</BlueText>
        </FeedbackItem>
      </FlexBox>
      <Dismiss onClick={closeToast}>prefer not to answer</Dismiss>
    </Container>
  );
};

export default FeedbackNotification;
