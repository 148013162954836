import React from 'react';
import styled from 'styled-components';
import InfoPage from '../../components/Info';

const ReloadButtonStyled = styled.div`
    background: #1fb6ff;
    width: 10%;
    border-radius: 25px;
    height: 50px;
    font-size: 22px;
    color: white;
    margin: 0 auto;
    text-align: center;
    line-height: 50px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.16);
    font-weight: 500;
    //transform: translateY(40vh); //InfoPage runs a 30vh transform.
    transform: translateY(-50vh); //somehow auto translates down...

    display: ${(props) => (props.show ? 'block' : 'none')};
`;

const ReloadButton = ({ show }) => {
  React.useEffect(() => {
    const timeout = setTimeout(() => window.location.reload(), 10 * 60 * 1000); // 10 minutes
    return () => clearTimeout(timeout);
  }, []);

  return (
    <ReloadButtonStyled show={show} onClick={() => window.location.reload()}>
      Reload
    </ReloadButtonStyled>
  );
};

const Loading = () => {
  const [showReload, setReload] = React.useState(false);
  React.useEffect(() => {
    const timeout = setTimeout(() => setReload(true), 30 * 1000); // 30 seconds
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <InfoPage title="Loading Experience" message="Please Wait" />
      <ReloadButton show={showReload} />
    </>
  );
};

export default Loading;
