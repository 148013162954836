import React, { useEffect } from 'react';
import QuizBackground from '../../components/QuizBackground';

const QuizInfo = ({ onStepChange, onEnded }) => {
  useEffect(() => {
    const id = setTimeout(() => {
      onEnded();
    }, 30000);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line
      }, []);

  return <QuizBackground src="/assets/christmasStart.webp" onClick={onStepChange} />;
};
export default QuizInfo;
