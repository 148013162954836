import parseVAST from 'iab-vast-parser';
import {
  getMedia, getMagniteAd,
} from '../../api';

/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/prefer-default-export
export const requestMagnite = async (incrementProgrammatic) => {
  incrementProgrammatic('magniteAdReq');
  const magniteAd = await getMagniteAd();
  if (magniteAd.error) return magniteAd;

  if (!magniteAd.ad) {
    return { error: magniteAd, empty: true, msg: 'No advertisement found' };
  }

  try {
    const parsed = parseVAST(magniteAd.ad);

    const ad = parsed && parsed._ads && parsed._ads.get(0);
    console.log(ad);
    if (!ad) {
      return { error: parsed, msg: '_ads not found in VAST object', empty: true };
    }

    const creative = ad._creatives.get(0);
    const impression = ad._impressions[0];
    const mediaFile = creative._linear._mediaFiles[0];
    const duration = creative._linear._duration;
    const pop = impression._uri;

    const response = await getMedia(mediaFile._uri, 'magnite');
    if (response.error) {
      return response;
    }

    mediaFile.blobURL = response;

    return {
      mediaFile,
      duration,
      pop,
      adPartner: 'magnite',
    };
  } catch (error) {
    console.log('Error parsing Magnite VAST file', error);
    return { error };
  }
};
