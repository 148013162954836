import styled from 'styled-components';
import Loading from '../../components/Loading';

export const PageContainer = styled.div`
  min-height: 100%;
  width: 100%;
  padding: 30px 55px;
  display: flex;
  flex-direction: column;
`;

export const VideoPageWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const Header = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const Logo = styled.h1`
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  color: ${(props) => props.theme.secondary};
  padding-right: 10px;
  margin: 0;
`;

export const Title = styled.p`
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;
  text-align: left;
  color: #2f2f2f;
`;

export const VideoListContainer = styled.div`
  width: 100%;
  height: 622px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const VideoCardWrapper = styled.div`
  width: 370px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const VideoImage = styled.img`
  width: 370px;
  height: 245px;
`;

export const VideoCardTitle = styled.p`
  width: 100%;
  max-width: 300px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.8px;
  color: #2f2f2f;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const ViewsTimeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const ViewsText = styled.p`
  font-size: 14px;
  text-alight: left;
  font-weight: 600;
  color: #1fb6ff;
  margin-right: 5px;
`;

export const LengthText = styled.p`
  font-size: 14px;
  text-align: left;
  color: #2f2f2f;
`;

export const Dot = styled.div`
  background-color: #000000;
  border-radius: 50%;
  height: 4px;
  width: 4px;
  margin-right: 5px;
  position: relative;
  top: 5px;
`;

export const ImageLoading = styled(Loading)`
  width: 370px;
  height: 245px;
`;
