import React, { useState, useEffect, useRef } from 'react';
import mixpanel from 'mixpanel-browser';

import CountdownTimer from '../../components/CountdownTimer';
import { updateStrapiQuiz } from '../../api';
import {
  Background, Header, QuestionWrapper, OptionContainer, Opt,
  OptionsWrapper, ChangeWrapper,
} from './style';
import { STRAPI_NOTHING_QUIZ_ID } from './QuizInfo';
import { socket } from '../../socket';
import Loading from '../../views/Loading';

const NEXT = 'Next';
const SKIP = 'Skip';
const QSECONDS = 45;
const ASECONDS = 15;

const QuizGame = ({
  questions, isSocket, onEnded, email,
}) => {
  const [showResults, setShowResults] = useState(false);
  const [number, setNumber] = useState(0);
  const [data, setData] = useState();
  const [totalVotes, setTotalVotes] = useState();
  const [isBonus, setIsBonus] = useState();
  const [duration, setDuration] = useState(0);

  const updatedQuestions = useRef([]);
  const scorePerQuestion = useRef();

  const handleClick = (id) => {
    if (showResults) return;

    scorePerQuestion.current.stopTimer();
    const newData = data;
    newData.Options[id].numberOfVotes += 1;
    updatedQuestions.current.push(newData);
    setData(newData);
    setShowResults(true);
  };

  const handleNextQuestion = () => {
    // if question is skipped, add "old" results to data to be written to strapi
    if (!showResults) updatedQuestions.current.push(data);

    setShowResults(false);

    // to check if its the last question on the flow
    if (number + 2 === questions.Question.length) setIsBonus(true);

    if (questions.Question[number + 1]) {
      const opt = questions.Question[number + 1].Options;

      if (isSocket) {
        socket.emit('questionAndVotes', number + 1,
          [opt[0].numberOfVotes, opt[1].numberOfVotes, opt[2].numberOfVotes, opt[3].numberOfVotes]);
      }
      setNumber((prev) => prev + 1);
    } else {
      const eventName = isSocket ? 'NothingQuizQREnd' : 'NothingQuizTabletEnd';
      mixpanel.track(eventName, { email, totalQuestions: updatedQuestions.current.length });
      socket.emit('endGame');

      if (questions.Question.length === updatedQuestions.current.length) {
        updateStrapiQuiz({ id: STRAPI_NOTHING_QUIZ_ID, Question: updatedQuestions.current });
      }

      onEnded();
    }
  };

  useEffect(() => {
    setData(questions.Question[number]);
    let votes = 1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 4; i++) {
      votes += questions.Question[number].Options[i].numberOfVotes;
    }
    setTotalVotes(votes);

    setDuration(QSECONDS);
    // eslint-disable-next-line
      }, [number, data]);

  useEffect(() => {
    if (!showResults) return;
    setDuration(ASECONDS);
    // eslint-disable-next-line
      }, [showResults]);

  useEffect(() => {
    socket.once('answerQuestion', ({ opt }) => {
      handleClick(opt);
    });

    socket.once('nextQuestion', () => {
      handleNextQuestion();
    });

    return () => {
      socket.off('answerQuestion');
      socket.off('nextQuestion');
    };
  });

  if (!data) return <Loading />;

  return (
    <Background src="/assets/NothingQuizBck.jpg" onClick={() => showResults && handleNextQuestion()}>
      <CountdownTimer
        ref={scorePerQuestion}
        duration={duration}
        onComplete={handleNextQuestion}
        noShowTimer
        timerId={`${data.question}${duration}`}
      />
      <Header>
        {(isBonus)
          ? 'Bonus!'
          : `Question ${number + 1}/${questions.Question.length - 1}`}
      </Header>
      <QuestionWrapper>
        {data.question}
      </QuestionWrapper>
      <OptionsWrapper>
        <OptionContainer onClick={() => handleClick(0)}>
          <Opt
            showResults={showResults}
            percent={(data.Options[0].numberOfVotes / totalVotes) * 100}
          >
            {(showResults)
              ? `${Math.trunc((data.Options[0].numberOfVotes / totalVotes) * 100)}%`
              : 'A'}
          </Opt>
          {data.Options[0].text}
        </OptionContainer>
        <OptionContainer onClick={() => handleClick(1)}>
          <Opt
            showResults={showResults}
            percent={(data.Options[1].numberOfVotes / totalVotes) * 100}
          >
            {(showResults)
              ? `${Math.trunc((data.Options[1].numberOfVotes / totalVotes) * 100)}%`
              : 'B'}
          </Opt>
          {data.Options[1].text}
        </OptionContainer>
      </OptionsWrapper>
      <OptionsWrapper>
        <OptionContainer onClick={() => handleClick(2)}>
          <Opt
            showResults={showResults}
            percent={(data.Options[2].numberOfVotes / totalVotes) * 100}
          >
            {(showResults)
              ? `${Math.trunc((data.Options[2].numberOfVotes / totalVotes) * 100)}%`
              : 'C'}
          </Opt>
          {data.Options[2].text}
        </OptionContainer>
        <OptionContainer onClick={() => handleClick(3)}>
          <Opt
            showResults={showResults}
            percent={(data.Options[3].numberOfVotes / totalVotes) * 100}
          >
            {(showResults)
              ? `${Math.trunc((data.Options[3].numberOfVotes / totalVotes) * 100)}%`
              : 'D'}
          </Opt>
          {data.Options[3].text}
        </OptionContainer>
      </OptionsWrapper>
      <ChangeWrapper onClick={() => showResults || handleNextQuestion()} isBonus={isBonus}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {(isBonus) ? 'Thanks for playing!' : (showResults) ? NEXT : SKIP}
      </ChangeWrapper>
    </Background>
  );
};

export default QuizGame;
