import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import LogoutButton from './logoutbutton';

import './aboutDriver.css';

const WrapperPane = styled.div`
  background-color: ${(props) => props.theme.ternary};
`;

const InfoHeader = styled.div`
  color: ${(props) => props.theme.secondary};
  background-color: ${(props) => props.theme.primary};
`;

const InfoBody = styled.div`
  color: ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.ternary};
  border: 1px solid ${(props) => props.theme.primary};
`;

const ImgCircle = styled.img`
  border: 5px solid ${(props) => props.theme.primary};
`;

const CloseButton = styled.button`
  color: ${(props) => props.theme.primary};
`;
const DeviceInfo = styled.p`
  color: ${(props) => props.theme.primary};
  padding: 10px;
  margin: 0;
`;
const DescriptionText = styled.p`
  margin: 0;
`;

// const displayableFields = ["cities", "languages", "favouriteFood", "hobbies"]
// const fieldToHeaderString = {
//     "cities": "I drive in and around:",
//     "languages": "I speak in:",
//     "favouriteFood": "My favourite places to eat:",
//     "hobbies": "In my free time I like to:"
// }

class AboutDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextPage: undefined,
      portlWebviewVersion: undefined,
    };
    this.generateDescription = this.generateDescription.bind(this);
    this.completeProfileContent = this.completeProfileContent.bind(this);
    this.redirectToPage = this.redirectToPage.bind(this);
  }

  componentDidMount() {
    let portlWebviewVersion;
    try {
      portlWebviewVersion = window.DeviceApi.getAppVersion(); // only works for webview
      if (portlWebviewVersion.includes('ERROR')) {
        portlWebviewVersion = undefined;
      }
      // console.log('Running portl webview version: ', portlWebviewVersion);
    } catch (error) {
      // console.log('Error getting portl webview version: ', error);
    }
    this.setState({ portlWebviewVersion });
  }

  generateDescription() {
    const { driver } = this.props;
    const { cities } = driver;
    if (cities === '---') {
      return (
        <>
          <DescriptionText>Please rate me</DescriptionText>
          <DescriptionText>Tips are greatly appreciated!</DescriptionText>
          <DescriptionText>Thank you, have a great day!</DescriptionText>
        </>
      );
    }

    return <p>{`I drive in ${cities}`}</p>;
  }

  completeProfileContent() {
    const { driver } = this.props;
    const { portlWebviewVersion } = this.state;

    return (
      <div className="aboutDriverInfoPane">
        <ImgCircle className="aboutDriverImage" src={driver.profile} alt="profile pic" />
        <div className="aboutDriverInfoBox">
          <InfoHeader className="aboutDriverInfoHeader">
            {`Hi, I'm ${driver.driverName.first}!`}
          </InfoHeader>
          <InfoBody className="aboutDriverInfoBody">
            {driver.description
              ? driver.description
              : this.generateDescription()}
          </InfoBody>
        </div>
        <DeviceInfo>
          {`Webview Version: ${portlWebviewVersion || 'Not Found'}`}
        </DeviceInfo>
        <DeviceInfo>
          {`v: ${process.env.REACT_APP_GIT_SHA} [${process.env.REACT_APP_ENV || 'localhost'}]`}
        </DeviceInfo>
      </div>
    );
  }

  redirectToPage(page) {
    this.setState({ nextPage: page });
  }

  render() {
    const { nextPage } = this.state;
    const { driverLogout } = this.props;
    if (nextPage) {
      return <Redirect exact to={`/${nextPage}`} />;
    }
    return (
      <div className="driverInfoDiv">
        <WrapperPane className="aboutDriverWrapper">{this.completeProfileContent()}</WrapperPane>
        <LogoutButton driverLogout={driverLogout} />
        <CloseButton className="aboutDriverModalClose" onClick={() => this.redirectToPage('home')}>
          &times;
        </CloseButton>
      </div>
    );
  }
}

export default withTheme(AboutDriver);
