import React from 'react';
import ordinal from 'ordinal';
import {
  BoardContainer, Ranked, TableItem, TableTitle,
} from './style';
import { QuizTitle } from './QuizTitle';

// eslint-disable-next-line import/prefer-default-export
export const QuizLeaderBoard = ({ sortedTop10, position, backgroundImg }) => (
  <BoardContainer src={backgroundImg}>
    <QuizTitle />
    <Ranked>
      You rank
      {' '}
      {ordinal(position + 1)}
      !
    </Ranked>
    <TableTitle>
      <div>Rank</div>
      <div>Name</div>
      <div>Score</div>
    </TableTitle>
    {sortedTop10.map((element, i) => (
      <TableItem key={element.score + element.name} highlight={i === position}>
        <div>{ordinal(i + 1)}</div>
        <div>{element.name}</div>
        <div>{element.score}</div>
      </TableItem>
    ))}
  </BoardContainer>
);
