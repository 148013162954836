import React, {
  useRef, forwardRef, useImperativeHandle, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import Countdown, { calcTimeDelta } from 'react-countdown';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

// context
import useContent from '../../context/content';

const MULTIPLIER = 9;

const Container = styled.div`
    margin: 0 auto;
`;

const Span = styled.span`
    ${({ noShowTimer }) => noShowTimer && 'display: none'}
`;

const ConditionalWrap = ({
  circleTimer, timerStart, duration, color, animateCircle, children,
}) => (
  <>
    {(circleTimer)
      ? (
        <Container>
          <CountdownCircleTimer
            key={timerStart}
            duration={duration}
            colors={color}
            trailColor="#ffffff"
            isPlaying={animateCircle}
          >
            {children}
          </CountdownCircleTimer>
        </Container>
      )
      : <span>{children}</span>}
  </>
);

const CountdownTimer = ({
  duration, onComplete, timerId, color, circleTimer, noShowTimer,
}, ref) => {
  // timerId is a unique id that the countdown component uses to
  // restart the timer if its diff from the prev one

  const [timerStart, setTimerStart] = useState(0);
  const [animateCircle, setAnimateCircle] = useState(true);
  const { stopTimer } = useContent();
  const timerRef = useRef();

  const handleStart = () => {
    if (animateCircle) return;
    setAnimateCircle(true);
    setTimerStart(Date.now() + (duration * 1000));
    timerRef.current.start();
  };

  const handlePause = () => {
    setAnimateCircle(false);
    timerRef.current.pause();
  };

  useImperativeHandle(
    ref,
    () => ({
      getScorePoints() {
        handlePause();
        return calcTimeDelta(timerStart, { precision: 3 }).total * MULTIPLIER;
      },
      stopTimer() {
        handlePause();
      },
    }),
  );

  useEffect(() => {
    setTimerStart(Date.now() + ((duration + 1) * 1000));
    setAnimateCircle(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerId]);

  useEffect(() => {
    if (stopTimer) {
      handlePause();
    } else {
      handleStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopTimer, timerStart]);

  return (
    <ConditionalWrap
      timerStart={timerStart}
      duration={duration}
      color={color}
      animateCircle={animateCircle}
      circleTimer={circleTimer}
    >
      <Countdown
        ref={timerRef}
        date={timerStart}
        renderer={(props) => <Span noShowTimer={noShowTimer}>{props.seconds}</Span>}
        onComplete={onComplete}
        precision={3}
        key={timerStart}
        autoStart={!stopTimer}
      />
    </ConditionalWrap>
  );
};

export default React.memo(forwardRef(CountdownTimer));
