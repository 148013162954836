import styled from 'styled-components';

export const Background = styled.div`
  background: url("${({ src }) => src}") center center no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  color: #000000;
  padding: 20px 21px 12px;
  position: relative;

`;

export const QRContainer = styled.div`
  position: absolute;
  bottom: 5%;
  left: 25px;
`;

export const QRPadding = styled.div`
  padding: 5px;
  background: white;
  width: 170px;
`;

export const QRText = styled.p`
  height: 33px;
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  padding-top: 10px;
  color: white;
`;
