import React from 'react';
import {
  PinkBox, RedBox, BlueBox, BombIcon, RoseIcon,
} from '../../components/Svgs/LoveToWin';
import { BoxContainer } from './style';

// eslint-disable-next-line import/prefer-default-export
export const Tile = ({ value, show, onHiddenTileClick }) => {
  if (!show) {
    return (
      <BlueBox
        onClick={onHiddenTileClick}
      />
    );
  }

  switch (value) {
    case 'R': return (
      <BoxContainer>
        <PinkBox />
        <RoseIcon />
      </BoxContainer>
    );
    case 'D': return (
      <BoxContainer>
        <RedBox />
        <BombIcon />
      </BoxContainer>
    );
    default: return <div />; // return empty box so takes up grid space
  }
};
