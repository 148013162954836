import React from 'react';
import { PauseControlsContainer, IconContainer, VideoCloseButton } from './style';
import VideoProgressBar from './VideoProgressBar';

const PauseControls = ({
  handleVideoClose,
  played,
  setPlayed,
  showSettings,
  url,
  videoRef,
}) => (
  <>
    <PauseControlsContainer>
      <IconContainer src="/assets/portl-spotlight/volume.png" onClick={showSettings} padding="35px" />
      <VideoProgressBar
        played={played}
        setPlayed={setPlayed}
        videoRef={videoRef}
        url={url}
      />
    </PauseControlsContainer>
    <VideoCloseButton size="65px" src="/assets/portl-spotlight/Close.png" onClick={() => handleVideoClose(url)} />
  </>
);
export default PauseControls;
