import React, { useRef, useState, useEffect } from 'react';
import { getStrapiArtist } from '../api';
import Loading from '../components/Loading';

import { HomePage, ContentPage, VideoPage } from './SpotlightComponents/Pages';

const Spotlight = ({ clearTimer, resetTimer, showSettings }) => {
  const [page, setPage] = useState('home');
  const [artistData, setArtistData] = useState();
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);

  // Set state to data received from strapi
  useEffect(() => {
    const getArtist = async () => {
      const data = await getStrapiArtist(1);
      if (isMounted.current) {
        setArtistData(data);
        setLoading(false);
      }
    };
    getArtist();
    return () => { isMounted.current = false; };
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    if (page === 'videos' || page === 'intro_video') {
      return clearTimer();
    }
    return resetTimer();
    // eslint-disable-next-line
    }, [page]);

  // Function to handle going back from bio, video, social pages to home
  const handleGoBack = () => {
    setPage('home');
  };

  return (
    <>
      {(loading)
        ? <Loading />
        : (
          <>
            {page === 'home' && (
              <HomePage
                setPage={setPage}
                artistData={artistData}
              />
            )}
            {page === 'bio' && (
              <ContentPage
                type="bio"
                src={artistData.bio_page_img}
                handleGoBack={handleGoBack}
                artistData={artistData}
              />
            )}
            {page === 'videos' && (
              <VideoPage
                url={artistData.video_link}
                handleGoBack={handleGoBack}
                artistData={artistData}
                showSettings={showSettings}
              />
            )}
            {page === 'social' && (
              <ContentPage
                type="social"
                src={artistData.social_img}
                handleGoBack={handleGoBack}
                artistData={artistData}
              />
            )}
            {page === 'intro_video' && (
              <VideoPage
                url={artistData.intro_video_url}
                handleGoBack={handleGoBack}
                artistData={artistData}
                showSettings={showSettings}
              />
            )}
          </>
        )}
    </>
  );
};

export default Spotlight;
