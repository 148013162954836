import React from 'react';
import {
  TitleWrapper, Title, Subtitle,
} from './style';

// eslint-disable-next-line import/prefer-default-export
export const QuizTitle = () => (
  <TitleWrapper>
    <Title>
      Quizzle
    </Title>
    <Subtitle>
      by portl
    </Subtitle>
  </TitleWrapper>
);
