import React from 'react';
import QRCode from 'qrcode.react';
import {
  Text, Divider, TextContainer, SocialMediaPageContainer, SocialMediaDiv, QRContainer,
  SocialMedia, IconContainer,
} from './style';

export const BioWrapper = ({ bioDesc }) => (
  <>
    <Text size="30px" color="#efefef" lineHeight={1.3}>
      Biography
    </Text>
    <Divider />
    <TextContainer width="565px">
      <Text size="27px" lineHeight={1}><br /></Text>
      {bioDesc.split('\n').map((text, idx) => ((text === '')
        ? <Text size="27px" key={text + idx.toString()}><br /></Text>
        : <Text size="27px" color="#efefef" key={text + idx.toString()}>{text}</Text>
      ))}
    </TextContainer>
  </>
);

export const SocialWrapper = ({ socialLinks }) => (
  <SocialMediaPageContainer>
    {socialLinks.map((socialmedia) => (
      <SocialMediaDiv key={`${socialmedia.acnt_name}-${socialmedia.website}`}>
        <SocialMedia style={{ width: '400px' }}>
          <IconContainer size="100px" padding="35px" src={socialmedia.logo_light} />
          <Text size="27px" color="#efefef">{socialmedia.acnt_name}</Text>
        </SocialMedia>
        <QRContainer>
          <QRCode
            value={socialmedia.acnt_url}
            size={125}
            level="Q"
            renderAs="svg"
          />
        </QRContainer>
      </SocialMediaDiv>
    ))}
  </SocialMediaPageContainer>
);
