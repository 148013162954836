import styled from 'styled-components';

export const GAME_NAME = 'Quizzle';

export const Score = styled.div`
  position: absolute;
  top: 14px;
  left: 17px;
  text-transform: uppercase;
`;

export const Container = styled.div`
  width: 100%;
  height: 750px;
  background-image: url("/assets/quizBck2.webp");
  background-size: cover;
  padding-top: 16px;
  font-family: 'Press Start 2P', cursive;
  color: #ffffff;
`;

export const ContainerStart = styled(Container)`
  background-image: url("/assets/quizzleHome.webp");
  background-size: contain;
`;

export const QuestionMarkButton = styled.div`
  background-image: url("/assets/quizzleQuestionMark.png");
  background-size: cover; 
  position: absolute;
  width: 150px;
  height: 130px;
  top: 343px;
  left: 858px;
`;

export const TitleWrapper = styled.div`
  width: 557px;
  height: 164.3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #000000;
  background-image: url("/assets/quizTitleBck.png");
  background-size: cover; 
  padding: 8px 0 26px 28px;
  font-size: 77px;
  margin: 0 auto;
`;

export const TitleContainer = styled(TitleWrapper)`
  height: 105.7px;
  font-size: 30px;
  text-align: center;
  color: #fedbca;
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.89);
  padding: 12px 0;
  text-transform: uppercase;
`;

export const Title = styled.p`
  letter-spacing: -5.01px;
  color: #fedbca;
  text-align: left;
  text-shadow: 0 5px 0 rgba(0, 0, 0, 0.86);
  margin: 0;
  height: 99px;
`;

export const Subtitle = styled(Title)`
  font-size: 25px;
  height: fit-content;
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.86);
`;

export const QRWrapper = styled.div`
  width: 140px;
  height: 140px;
  border: solid 2px #ffc100;
  padding: 5px;
  background-color: #ffffff;
  margin: 480px 277px 0 auto;
`;

export const Content = styled.p`
  margin-bottom: 22px;
  text-align: center;
  font-size: 23px;
  line-height: 1.61;
`;

export const StartTablet = styled.div`
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.89);
  font-size: 23px;
  line-height: 1.61;
  text-align: center;
  width: 732px;
  margin: 0 auto 22px;
  text-transform: uppercase;
`;

export const StartButton = styled.div`
  width: 266px;
  height: 76px;
  background-image: url('${(props) => props.src}');
  background-size: cover;
  margin 30px auto 0;
`;

export const OptionsContainer = styled.div`
  height: 72px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  text-align: left;
`;

export const BoardTitle = styled(TitleWrapper)`
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.89);
  font-size: 30px;
  text-align: center;
  padding-top: 31px;
  color: #fedbca;
`;

export const PersonalScore = styled.div`
  font-size: 27px;
  text-align: center;
`;

export const BoardName = styled.div`
  text-align: center;
  margin-top: 30px;
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.89);
  font-size: 21px;
`;

export const BoardInfo = styled.div`
  width: 742px;
  text-shadow: 0 3px 0 rgba(0, 0, 0, 0.89);
  font-size: 19px;
  line-height: 2.11;
  text-align: center;
  margin: 30px auto 0;
`;

export const ScoreOptContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 19px;
  justify-content: space-between;
  margin: 25px auto 0;
  width: ${({ width }) => width}px;
`;

export const ContentOption = styled.div`
  position: relative;
  width: fit-content;
`;

export const SelectArrow = styled.div`
  width: 18.6px;
  height: 22.6px;
  background-image: url('/assets/quizSelected.png');
  background-size: cover;
  position: absolute;
  left: -27px;
`;

export const HighScoreBtn = styled.div`
  width: 284px;
  height: 89px;
  background-image: url('/assets/quizScore.png');
  background-size: cover;
  margin: 30px auto 0;
`;

export const BoardContainer = styled(Container)`
  text-transform: uppercase;
  background-image: url('/assets/${(props) => props.src || 'quizBck2.webp'}');
`;

export const Ranked = styled.div`
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.89);
  font-size: 30px;
  text-align: center;
`;

export const TableItem = styled.div`
  display: grid;
  grid-template-columns: 84px 153px 228px;
  font-size: 21px;
  text-align: right;
  margin: 5px auto 0;
  width: fit-content;
  ${({ highlight }) => highlight && 'color: #c40000;'}
`;

export const TableTitle = styled(TableItem)`
  margin-top: 20px; //38px;
  margin-bottom: 13px; //26px;
`;

export const StartPhone = styled.div`
  width: 692px;
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.89);
  font-size: 26px;
  text-align: center;
  line-height: 1.58;
  text-transform: uppercase;
  margin: 63px auto 0;
`;

export const Question = styled.div`
  max-width: 895px;
  line-height: 1.61;
  margin: 54px auto 0;
`;
