import React from 'react';
import FlipGame from '../views/FlipGame';

import { WinComponent } from './FourLeafFortuneComponents/WinComponent';
import { HowToPlay } from './FourLeafFortuneComponents/HowToPlay';
import { GameComponent } from './FourLeafFortuneComponents/GameComponent';

// Set startingSquares to game grid layout (5 x 3 in this case)
const generateRandomTiles = () => {
  // STATES
  // N - neutral
  // W - win
  // L - lose
  const setOfSquares = [
    'W', 'W', 'W', 'L', 'L', 'L', 'L', 'L', 'N', 'N', 'N', 'N', 'N', 'N', 'N',
  ];
  const getRandInt = () => Math.floor(Math.random() * setOfSquares.length);

  const startingSquares = new Array(3);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    startingSquares[i] = new Array(5);
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < 5; j++) {
      const index = getRandInt();
      startingSquares[i][j] = {
        show: false,
        value: setOfSquares[index],
      };
      // remove element from array so it doesn't get used again
      setOfSquares.splice(index, 1);
    }
  }

  return startingSquares;
};

const FourLeafFortune = ({ resetTimer }) => (
  <FlipGame
    appName="FourLeafFortune"
    resetTimer={resetTimer}
    GameRenderComp={GameComponent}
    HowToPlayComponent={HowToPlay}
    WinComponent={WinComponent}
    winCondition={3}
    generateRandomTiles={generateRandomTiles}
  />
);

export default FourLeafFortune;
