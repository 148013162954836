import React from 'react';
import {
  Background, GameContainer, Coaster, GameGrid, EmptyDiv, DripOnGlass, GlassImg,
} from './style';
import { LoseScreen } from './LoseScreen';

const Tile = ({ value, show, onHiddenTileClick }) => {
  if (!show) {
    return <Coaster src="/assets/four-leaf-fortune/coaster_back.webp" onClick={onHiddenTileClick} />;
  }

  switch (value) {
    case 'W': return <Coaster src="/assets/four-leaf-fortune/coaster_correct.webp" />;
    case 'L': return <Coaster src="/assets/four-leaf-fortune/coaster_wrong.webp" />;
    default: return <EmptyDiv />;
  }
};

const renderMugSwitch = (winTileCount) => {
  switch (winTileCount) {
    case 1:
      return <GlassImg src="/assets/four-leaf-fortune/glass1.webp" />;
    case 2:
      return <GlassImg src="/assets/four-leaf-fortune/glass2.webp" />;
    case 3:
      return <GlassImg src="/assets/four-leaf-fortune/glass_empty3.webp" />;
    default:
      return (
        <>
          <GlassImg src="/assets/four-leaf-fortune/glass_full.webp" />
          <DripOnGlass src="/assets/four-leaf-fortune/drip.webp" />
        </>
      );
  }
};

// eslint-disable-next-line import/prefer-default-export
export const GameComponent = ({
  handlePlayAgain,
  handleTileClick,
  tiles,
  loseTileFound,
  winTileCount,
  winCondition,
}) => (
  <Background disableClick={winTileCount === winCondition}>
    <GameContainer>
      <LoseScreen open={loseTileFound} onButtonClick={handlePlayAgain} />
      <GameGrid disableClick={loseTileFound}>
        {tiles.map((row, rowIndex) => row.map((tile, columnIndex) => {
          const key = tile.value + rowIndex + columnIndex;
          return (
            <Tile
              key={key}
              onHiddenTileClick={() => handleTileClick(tile, rowIndex, columnIndex)}
              value={tile.value}
              show={tile.show}
            />
          );
        }))}
      </GameGrid>
    </GameContainer>
    {renderMugSwitch(winTileCount)}
  </Background>
);
