import React, { useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const StaticImagePlayer = ({
  imageUrl, duration, onEnded, onError,
}) => {
  useEffect(() => {
    const timer = setTimeout(onEnded, duration * 1000);
    return () => {
      // Clean up the subscription
      clearTimeout(timer);
    };
  });

  const onImageError = (e) => {
    onError();
    console.log('Error loading image: ', e.target.src);
  };

  return (
    <>
      <img
        src={imageUrl}
        height="100%"
        width="100%"
        alt="Sponsored Content"
        style={{ borderRadius: '5px' }}
        onError={onImageError}
      />
    </>
  );
};
