import indexedDB from '../indexedDB';
import { indexDBDisabled, disableIndexedDB } from '../config';
import { createZendeskTicket } from '../api';
import { getObject } from '../utils/helper';

import { socket, streamSetting } from '../socket';

const overrideConsoleLog = async () => {
  if (indexDBDisabled) {
    return { error: 'IndexedDB is disabled.' };
  }

  const initError = await indexedDB.databaseInit();
  if (initError) {
    return { error: `Fail to override console log: ${initError}` };
  }

  const preservedConsoleLog = console.log; // saving the original console.log function

  // eslint-disable-next-line func-names
  console.log = function (...args) { // overriding console.log function
    // we can't just call to `preservedConsoleLog` function, ---> TypeError: Illegal invocation)
    // because we need the function to be inside the
    // scope of the `console` object so we going to use the apply` function
    preservedConsoleLog.apply(console, args);

    const replaceErrors = (_, errorVal) => {
      if (errorVal instanceof Error) {
        const error = {};

        Object.getOwnPropertyNames(errorVal).forEach((errorKey) => {
          error[errorKey] = errorVal[errorKey];
        });
        return error;
      }
      return errorVal;
    };

    const argsArray = [...args].map((arg) => {
      try {
        if (arg instanceof Error) return JSON.stringify(arg, replaceErrors);
        if (typeof arg !== 'string') return JSON.stringify(arg);
        return arg;
      } catch (error) {
        return 'unable to stringify this arg';
      }
    });

    if (streamSetting.isStreaming) {
      preservedConsoleLog.bind(socket.emit('stream', {
        id: localStorage.getItem('IMEI'),
        argsArray,
      }));
    }

    if (!indexDBDisabled) {
      indexedDB.addLogsToDB(argsArray, 'info')
        .then((hasError) => {
          if (hasError) {
            const driver = getObject('driver');
            const email = driver && driver.email;
            const IMEI = localStorage.getItem('IMEI');
            console.warn(hasError);
            createZendeskTicket(
              { email },
              `IndexedDB Failed To Log on ${IMEI}`,
              { hasError, argsArray, path: window.location.href },
            );
            disableIndexedDB();
          }
        });
    }
  };
  return null;
};

export default overrideConsoleLog;
