import React from 'react';
import { Score, TitleContainer } from './style';

// eslint-disable-next-line import/prefer-default-export
export const HeaderComponent = ({
  score, questions, qIndex, children,
}) => (
  <>
    <Score>
      Score:
      {' '}
      {score}
    </Score>
    <TitleContainer>
      Level
      {' '}
      {qIndex + 1}
      /
      {questions.length}
      <br />
      Time:
      {' '}
      {children}
    </TitleContainer>
  </>
);
