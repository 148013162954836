import React, { useState, useEffect } from 'react';
import { trackMixpanel } from '../analytics';
import { getActiveStrapiShorts, updateStrapiShorts } from '../api';
import VideoPlayer from '../components/VideoPlayer';
import {
  PageContainer, VideoPageWrapper, Header, Logo, Title, VideoListContainer,
} from './PortlShortsComponents/style';
import { VideoCard } from './PortlShortsComponents/VideoCard';

function shuffleArray(array = []) {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const PortlShorts = ({ clearTimer, resetTimer, onEnded }) => {
  const [videoPlayList, setVideoPlayList] = useState();
  const [currentVideo, setCurrentVideo] = useState();

  // Fetch videos from strapi and set videoPlayList with data
  useEffect(() => {
    let isMounted = true;
    const getPlayList = async () => {
      const { activeData, error } = await getActiveStrapiShorts();
      if (error) onEnded();
      shuffleArray(activeData); // shuffle to remove bias
      if (isMounted) {
        setVideoPlayList(activeData);
      }
    };
    getPlayList();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // Function to handle
  const handleVideoClose = () => {
    setCurrentVideo('');
    resetTimer();
  };

  const handleVideoClick = (url, videoTitle, id, numViews) => {
    // Update numViews state in videoPlayList for video that was clicked
    const videoIndex = videoPlayList.findIndex((video) => video.id === id);
    setVideoPlayList((prevState) => [
      ...prevState.slice(0, videoIndex),
      { ...prevState[videoIndex], numViews: numViews + 1 },
      ...prevState.slice(videoIndex + 1),
    ]);

    // set current video state
    setCurrentVideo({
      url,
      title: videoTitle,
    });
    clearTimer();
    trackMixpanel('ShortsVideoClick', { url, videoTitle }, true);

    // Iterate number of views when video is clicked
    try {
      updateStrapiShorts(id, { numViews: numViews + 1 });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {currentVideo ? (
        <VideoPageWrapper>
          <VideoPlayer
            url={currentVideo.url}
            handleVideoClose={handleVideoClose}
            appName="PortlShorts"
            title={currentVideo.title}
            resetTimer={resetTimer}
            clearTimer={clearTimer}
          />
        </VideoPageWrapper>
      ) : (
        <PageContainer>
          <Header>
            <Logo>portl</Logo>
            <Title>Shorts</Title>
          </Header>
          <VideoListContainer>
            {videoPlayList
              && videoPlayList.map(
                ({
                  id, title, imgUrl, videoUrl, numViews, videoLength,
                }) => (
                  <VideoCard
                    key={id + title}
                    title={title}
                    img={imgUrl}
                    length={videoLength}
                    views={numViews}
                    onClick={() => handleVideoClick(videoUrl, title, id, numViews)}
                  />
                ),
              )}
          </VideoListContainer>
        </PageContainer>
      )}
    </>
  );
};

export default PortlShorts;
