import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getWeather } from '../../store/api';

// const FontIcon = styled.i`
//     font-size: ${({ size }) => size || '40px'};
//     display: inherit;
// `;
const IconImg = styled.img`
    height: 28px;
`;
// old icons
// const iconMap = {
//     "clear sky": { day: "sun", night: "moon" },
//     "few clouds": { day: "cloud-sun", night: "cloud-moon" },
//     "scattered clouds": "cloud",
//     "overcast clouds": "cloud",
//     "broken clouds": "clouds",
//     "shower rain": "rain",
//     "rain": "drizzle",
//     "thunderstorm": "cloud-flash-alt",
//     "snow": "snow-alt",
//     "mist": "mist",
//     "haze": "fog"
// };
const imgMap = {
  'clear sky': { day: 'sunny', night: 'clear-night' },
  'few clouds': { day: 'mostly-sunny', night: 'mostly-clear-night' },
  'scattered clouds': { day: 'mostly-sunny', night: 'mostly-clear-night' },
  'overcast clouds': 'cloudy',
  'broken clouds': { day: 'partly-cloudy', night: 'partly-cloudy-night' },
  'shower rain': 'raining',
  rain: { day: 'drizzle', night: 'drizzle-night' },
  thunderstorm: 'thunderstorm',
  snow: 'snowy',
  mist: 'fog',
  haze: 'fog',
};
const WeatherText = styled.div`
    font-size: 23px;
    font-weight: 300;
    position: absolute;
    top: 10px;
    align-items: center;
    right: 15px;
    display: flex;
`;

// TODO: move to separate component
// const FontIconWrapper = ({ icon, size }) => <FontIcon className={`icon-${icon}`} size={size} />

const WeatherComponent = () => {
  const [state, setState] = useState({
    description: undefined,
    temp: undefined,
    dayNight: undefined,
    error: true,
  });

  useEffect(() => {
    const time = 5 * 60 * 1000; // 5 min
    let isMounted = true;

    const updateWeather = async () => {
      const response = await getWeather();

      if (response.error && isMounted) {
        console.log('Error getting weather!');
        setState({ error: true });
        return;
      }

      if (isMounted) {
        setState({ ...response, error: false });
      }
    };

    updateWeather();
    const interval = setInterval(updateWeather, time);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
    // eslint-disable-next-line
    }, []);

  const getTempC = (temp) => Math.round(((parseFloat(temp) - 32) * 5) / 9);
  // const getIcon = (description, dayNight) => {
  //     if (description in iconMap) {
  //         if (typeof iconMap[description] === "string") {
  //             return iconMap[description];
  //         }
  //         else {
  //             return iconMap[description][dayNight];
  //         }
  //     }
  //     else {
  //         return "clouds";
  //     }
  // };
  const getImg = (description, dayNight) => {
    if (description in imgMap) {
      if (typeof imgMap[description] === 'string') {
        return imgMap[description];
      }

      return imgMap[description][dayNight];
    }

    return 'cloudy';
  };

  return !state.error && (
  <WeatherText>
    <IconImg src={`/assets/weather-icons/${getImg(state.description, state.dayNight)}.png`} />
    {`${getTempC(state.temp)}°`}
  </WeatherText>
  );
};

export default WeatherComponent;
