import React, { useState, useEffect, useRef } from 'react';
import mixpanel from 'mixpanel-browser';
import queryString from 'query-string';

import QuizLeaderboard from './ChristmasQuizComponents/QuizLeaderboard';
import QuizGame from './ChristmasQuizComponents/QuizGame';
import Loading from '../views/Loading';
import QuizScore from './ChristmasQuizComponents/QuizScore';
import { QuizHome, GAME_NAME } from './ChristmasQuizComponents/QuizHome';
import QuizCharity from './ChristmasQuizComponents/QuizCharity';
import QuizInfo from './ChristmasQuizComponents/QuizInfo';

import { getQuizScore } from '../api';

import { socket } from '../socket';
import { generateCode } from '../utils/helper';
import useAuth from '../context/auth';

const ChristmasQuiz = ({ onEnded, clearTimer }) => {
  const parsed = queryString.parse(window.location.search);

  const privateCode = useRef(parsed.remoteControl || generateCode(15));
  const [step, setStep] = useState(0);
  const [info, setInfo] = useState({
    correct: 0, score: 0, name: '', content: '',
  });
  const [rank, setRank] = useState([]);
  const [isSocket, setSocket] = useState(false);
  const [questions, setQuestions] = useState();

  const { driver } = useAuth();
  const isMounted = useRef(true);
  const IMEI = localStorage.getItem('IMEI');

  const playAgain = () => {
    setInfo({
      correct: 0, score: 0, name: '', content: '',
    });
    setStep(2);
    setRank([]);

    const event = isSocket ? 'ChristmasPhonePlayAgain' : 'ChristmasTabletPlayAgain';
    mixpanel.track(event, { email: driver.email });
  };

  const handleStepChange = () => {
    setStep((prev) => prev + 1);
  };

  useEffect(() => {
    // Sockets config
    socket.on(GAME_NAME, ({ code }) => {
      if (privateCode.current !== code) {
        return socket.emit('gameStartStatus', 'code');
      }
      return socket.emit('gameStartStatus', 'active');
    });

    socket.on('playAgain', () => {
      playAgain();
      socket.emit('gameRestarted');
    });

    socket.once('connected', (code) => {
      if (privateCode.current === code) {
        socket.emit('tabletConnected');
        setSocket(true);
        handleStepChange();
      } else {
        socket.emit('wrongCode');
        onEnded();
      }
    });

    return () => {
      socket.off(GAME_NAME);
      socket.off('playAgain');
      socket.off('connected');
      socket.emit('disconnectRider');
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.once('nextStep', () => {
      handleStepChange();
      socket.emit('nextStepChanged');
    });

    return () => socket.off('nextStep');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    socket.once('startQuiz', () => {
      socket.emit('quizStarted', questions.length);
      handleStepChange();
    });

    return () => socket.off('startQuiz');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, info]);

  const handleSubmit = async (data) => {
    // eslint-disable-next-line no-param-reassign
    if (data.name === '') data.name = 'PTL';
    setInfo(data);

    const responseRank = await getQuizScore('Christmas', data);

    setRank(responseRank);

    handleStepChange();
  };

  const handleBeginQuiz = () => {
    mixpanel.track('ChristmasQuizTabletStart', { email: driver.email });
    handleStepChange();
  };

  switch (step) {
    case 1:
      return (
        <QuizCharity
          onStepChange={handleStepChange}
          setQuestions={setQuestions}
          clearTimer={clearTimer}
          onEnded={onEnded}
          _isMounted={isMounted.current}
        />
      );
    case 2:
      return <QuizInfo onStepChange={handleStepChange} onEnded={onEnded} />;
    case 3:
      return (questions)
        ? (
          <QuizGame
            questions={questions}
            onStepChange={handleStepChange}
            isSocket={isSocket}
            setInfo={setInfo}
            score={info.score}
          />
        )
        : <Loading />;
    case 4:
      return (
        <QuizScore
          onSubmit={handleSubmit}
          setInfo={setInfo}
          isSocket={isSocket}
          info={info}
        />
      );
    case 5:
      return (
        <QuizLeaderboard
          info={info}
          isSocket={isSocket}
          rank={rank}
          onEnded={onEnded}
          playAgain={playAgain}
        />
      );
    default:
      return <QuizHome beginQuiz={handleBeginQuiz} privateCode={privateCode.current} IMEI={IMEI} />;
  }
};

export default ChristmasQuiz;
