import styled from 'styled-components';
import ImageFadeIn from 'react-image-fade-in';

const DEFAULT_THEME = {
  dark: '#006abb',
  light: '#0088f1',
};
export const tileColor = DEFAULT_THEME;
export const Background = styled.div`
    width: 100%;
    height: 100vh;
    background: ${(props) => props.theme.welcomeBgColor};
    padding: 20px 28px;
    position: relative;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Box = styled.div`
    position: relative;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
`;

export const BigBox = styled(Box)`
    width: 915px;
    height: 516px;
`;

export const MediumBox = styled(Box)`
    width: 295px;
    height: 252px;
`;

export const MediumSmallBox = styled(Box)`
    width: 295px;
    height: 228px;
`;

export const SmallBox = styled(Box)`
  width: 295px;
  height: 106px;
  background-image: linear-gradient(
    to right,
    ${tileColor.dark} 3%,
    ${tileColor.light} 78%
  );
  color: #ffffff;
`;

export const QRContainer = styled(MediumSmallBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ffffff;
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 800;
    line-height: 1.21;
    // color: #043650; normal
    color: ${tileColor.dark};
`;

export const QRWrapper = styled.div`
    width: 150px;
    height: 150px;
    padding: 5px;
    background-color: #ffffff;
`;

export const Space = styled.div`
    height: 15px;
`;

export const PortlLogo = styled.span`
    position: absolute;
    right: 15px;
    bottom: 10px;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: -0.92px;
`;

export const TileImg = styled(ImageFadeIn)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const SettingsButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 4px;
    flex-direction: column;
    width: 50%;
    height: 100%;
    ${({ color }) => `background-color: ${color}`};
`;

export const SettingsBox = styled(SmallBox)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
`;

export const IconText = styled.p`
    margin: 0;
    font-size: 23px;
    color: ${({ color }) => color};
`;
