import React from 'react';
import { LoseScreen, looseOptions } from './LoseScreen';
import {
  GameComponentBackground, Container, Title, ChooseWording, Coaster,
  FlexContainer, FlipContainer, Description, Subtitle, Instruction, CloudsEffect,
} from './style';

const Tile = ({ value, show, onHiddenTileClick }) => {
  if (!show) {
    return <Coaster src="/assets/easter/basket.webp" onClick={onHiddenTileClick} />;
  }

  switch (value) {
    case 'W': return <Coaster src="/assets/easter/egg_basket.webp" />;
    case 'L1': return <Coaster src={looseOptions.L1.url} />;
    case 'L2': return <Coaster src={looseOptions.L2.url} />;
    case 'L3': return <Coaster src={looseOptions.L3.url} />;
    case 'L4': return <Coaster src={looseOptions.L4.url} />;
    case 'L5': return <Coaster src={looseOptions.L5.url} />;
    default: return <Coaster src="/assets/easter/basket.webp" isEmpty />;
  }
};
// eslint-disable-next-line import/prefer-default-export
export const GameComponent = ({
  handlePlayAgain,
  handleTileClick,
  tiles,
  loseTileFound,
  winTileCount,
  winCondition,
}) => (
  <GameComponentBackground disableClick={winTileCount === winCondition}>
    <CloudsEffect />
    <LoseScreen open={loseTileFound} onButtonClick={handlePlayAgain} />
    <Container disableClick={loseTileFound}>
      <Title>Some Bunny That You Used To Know</Title>
      <ChooseWording>Choose which baskets to hand out to the children</ChooseWording>
      <FlexContainer>
        <FlipContainer>
          {tiles.map((row, rowIndex) => row.map((tile, columnIndex) => {
            const key = tile.value + rowIndex + columnIndex;
            return (
              <Tile
                key={key}
                onHiddenTileClick={() => handleTileClick(tile, rowIndex, columnIndex)}
                value={tile.value}
                show={tile.show}
              />
            );
          }))}
        </FlipContainer>
        <Description>
          <Subtitle>HOW TO PLAY</Subtitle>
          <Instruction>1. Tap a basket to reveal its contents</Instruction>
          <Instruction>2. Find three egg baskets and win!</Instruction>
          <Instruction>
            3. Find anything dangerous and it&apos;s
            <span>GAME OVER</span>
          </Instruction>
        </Description>
      </FlexContainer>
    </Container>
  </GameComponentBackground>
);
