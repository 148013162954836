export const setCookie = (key, data, time) => {
  const date = new Date();
  date.setTime(date.getTime() + time);
  const expires = `expires=${date.toUTCString()}`;
  console.log('Cookie:', `${key}=${JSON.stringify(data)};${expires};domain=.portlmedia.com;path=/;`);
  if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production') {
    document.cookie = `${key}=${JSON.stringify(data)};${expires};domain=.portlmedia.com;path=/;`;
  } else {
    console.log('local storage', data);
    localStorage.setItem('token', data);
    // localStorage.cookieToken = data; // For use with the local storage
  }
};

export const getCookie = (key) => {
  const keyStr = `${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i += 1) {
    let chars = cookieArray[i];
    while (chars.charAt(0) === ' ') {
      chars = chars.substring(1);
    }
    if (chars.indexOf(keyStr) === 0) {
      return JSON.parse(chars.substring(keyStr.length, chars.length));
    }
  }
  return '';
};

export const deleteCookie = (key) => {
  const domain = window.location.href.includes('localhost') ? 'localhost' : 'portlmedia.com';
  // overwrites and expires existing cookie
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
  localStorage.removeItem('token');
//   localStorage.cookieToken = '';
};
