import React, { useState } from 'react';

import VideosGrid from '../components/VideosGrid';
import { data } from './CorusEntertainment/components';

const CSI = () => {
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <VideosGrid data={data[0]} link="/assets/CSIVegas.mp4" fullScreen={fullscreen} setFullscreen={setFullscreen} currentVideo="CSI" />
  );
};

export default CSI;
