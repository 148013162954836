import React from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

const LoadingWrapper = styled.div`
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
`;

const Loading = () => (
  <LoadingWrapper>
    <ClipLoader
      color="#169aff"
      size={75}
      loading
    />
  </LoadingWrapper>
);

export default Loading;
