import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Button = styled.div`
    text-align: center;
    border-radius: 5px;
    border: none;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 16%);
    width: 200px;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    background-color: #ddd;
`;
const ReloadContainer = styled.div`
    width: 500px;
`;

const ReloadMessage = ({ resetTimer, reloadPage }) => {
  const [counter, setCounter] = useState(15);
  useEffect(() => {
    if (counter === 0) return reloadPage();

    const id = setTimeout(() => {
      setCounter((prev) => prev - 1);
    }, 1000);
    return () => clearTimeout(id);
    // eslint-disable-next-line
    }, [counter]);

  return (
    <ReloadContainer>
      There is a pending update. This tablet will apply the update and refresh in
      {' '}
      {counter}
      {' '}
      seconds.
      <Button onClick={resetTimer}>Update later</Button>
    </ReloadContainer>
  );
};

export default ReloadMessage;
