import tzlookup from 'tz-lookup';
// console.log(tzlookup(42.7235, -73.6931)); // prints "America/New_York"

import { getLastLocation, isLocationDefault } from '../store/location';
import { getSunTime } from '../store/api';
import { changeTabletBrightness } from '../socket/index';

export const getTimeZone = async () => {
  const location = await getLastLocation();
  const { lat, lng } = location;
  const timeZone = tzlookup(lat, lng);
  return timeZone;
};

export const setAutoBrightness = async () => {
  const { sunrise, sunset } = await getSunTime();

  const currentDay = new Date();
  const currentTime = currentDay.getTime(); // Day in milliseconds

  const nextDay = currentDay;
  nextDay.setDate(currentDay.getDate() + 1);
  nextDay.setHours(0, 5, 0); // Next day at 12:05am
  const nextDayTime = nextDay.getTime(); // in milliseconds

  if (currentTime < sunrise || currentTime > sunset) { // this will be only needed on tablet start
    changeTabletBrightness(0);
  }

  if (currentTime < sunrise) {
    setTimeout(() => { changeTabletBrightness(50); }, sunrise - currentTime);
  }

  if (currentTime < sunset) {
    setTimeout(() => { changeTabletBrightness(15); }, sunset - currentTime);
  }

  setTimeout(setAutoBrightness, nextDayTime - currentTime);
};

// TODO: add test for isWithinCircle with known lat/lng and distance
export const isWithinCircle = async (latCircle, lngCircle, radius) => {
  if (!latCircle || !lngCircle || !radius) return null;

  const location = await getLastLocation();
  const { lat, lng } = location;
  if (isLocationDefault()) return null;

  // find the distance between current location and center of circle
  const R = 6371e3; // radius of earth in metres
  const φ1 = (lat * Math.PI) / 180; // convert φ, λ to radians
  const φ2 = (latCircle * Math.PI) / 180;
  const Δφ = ((latCircle - lat) * Math.PI) / 180;
  const Δλ = ((lngCircle - lng) * Math.PI) / 180;

  const x = Δλ * Math.cos((φ1 + φ2) / 2);
  const y = Δφ;
  const distance = Math.sqrt(x * x + y * y) * R;

  // return true/false
  return distance <= radius;
};

export const findClosest = (target = {}, positions = []) => {
  let index = 0;
  let min = 1000000;
  const R = 6371e3; // radius of earth in metres
  const { lat, lng } = target;
  positions.forEach(({ lat: placeLat, lng: placeLng }, i) => {
    const φ1 = (lat * Math.PI) / 180; // convert φ, λ to radians
    const φ2 = (placeLat * Math.PI) / 180;
    const Δφ = ((placeLat - lat) * Math.PI) / 180;
    const Δλ = ((placeLng - lng) * Math.PI) / 180;

    const x = Δλ * Math.cos((φ1 + φ2) / 2);
    const y = Δφ;
    const distance = Math.sqrt(x * x + y * y) * R;

    if (distance < min) {
      min = distance;
      index = i;
    }
  });

  if (min === 1000000) return { error: 'No closest' };

  return { index, distance: min };
};

const randomInt = (min, max) => Math.floor(Math.random() * (max - min) + min);

export const generateCode = (size) => {
  let newCode = '';

  for (let i = 0; i < size; i += 1) {
    newCode += String.fromCharCode(randomInt(65, 91));
  }
  return newCode;
};

export const generateCodeInt = (size) => {
  let newCode = '';

  for (let i = 0; i < size; i += 1) {
    newCode += randomInt(0, 10);
  }
  return newCode;
};

export const setObject = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getObject = (key) => {
  const value = localStorage.getItem(key);
  return value && JSON.parse(value);
};

const MULTIPLIER = 9;
export function ScoreByTimer(maxTime) { // maxTime in seconds
  this.initialTime = new Date();

  this.start = () => {
    this.initialTime = new Date();
  };

  this.end = () => {
    const currentTime = new Date();
    return (maxTime * 1000 - (currentTime - this.initialTime)) * MULTIPLIER;
  };
}
