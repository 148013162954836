import React from 'react';
import {
  CongratsContainer, FailText, LoserOverlay, FailHeader, PlayAgainButton, LeprechaunImg,
} from './style';

// eslint-disable-next-line import/prefer-default-export
export const LoseScreen = ({ open, onButtonClick }) => (
  <LoserOverlay open={open}>
    <CongratsContainer>
      <FailHeader>bollocks!!!</FailHeader>
      <FailText>
        Looks like the Leprechaun got you before you finished your beer.
        <br />
        <br />
        Don&apos;t worry, there&apos;s plenty more beer inside.
        <br />
        <br />
        Are you still thirsty?
        <br />
        <br />
      </FailText>
      <PlayAgainButton onClick={onButtonClick}>Play Again</PlayAgainButton>
      <LeprechaunImg src="/assets/four-leaf-fortune/mean_muggin.webp" />
    </CongratsContainer>
  </LoserOverlay>
);
