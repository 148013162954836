import React, { useState, useRef, useEffect } from 'react';
import { trackMixpanel } from '../../analytics';
import { TouchSvg } from '../../components/Svgs/LoveToWin';
import {
  Background, GameGrid, TouchText, TouchTextContainer, UncoverTextContainer,
  WarningText, ContentContainer, BackgroundImg,
} from './style';
import { DealbreakerScreen } from './DealbreakerScreen';
import { Tile } from './Tile';

const generateRandomTiles = () => {
  // STATES
  // N - neutral
  // R - rose
  // D - dealbreaker
  const setOfSquares = [
    'R', 'R', 'R', 'D', 'D', 'D', 'D', 'D', 'N', 'N', 'N', 'N', 'N', 'N', 'N', 'N',
  ];
  const getRandInt = () => Math.floor(Math.random() * setOfSquares.length);

  const startingSquares = new Array(4);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 4; i++) {
    startingSquares[i] = new Array(4);
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < 4; j++) {
      const index = getRandInt();
      startingSquares[i][j] = {
        show: false,
        value: setOfSquares[index],
      };
      // remove element from array so it doesn't get used again
      setOfSquares.splice(index, 1);
    }
  }

  return startingSquares;
};
const WIN_CONDITION = 3;

const Game = ({ setView, resetTimer }) => {
  const [dealbreakerFound, setDealBreakerFound] = useState(false);
  const [tiles, setTiles] = useState(generateRandomTiles());
  const [roseCount, setRoseCount] = useState(0);
  const tilesClicked = useRef({
    count: 0,
    tiles: [],
  });

  const handlePlayAgain = () => {
    trackMixpanel('LTW Play Again', { ...tilesClicked.current, roseCount }, true);
    setTiles(generateRandomTiles());
    setRoseCount(0);
    tilesClicked.current = {
      count: 0,
      tiles: [],
    };
    resetTimer();
    setDealBreakerFound(false);
  };

  const showAllTilesAfter2Sec = () => {
    const showTiles = () => {
      setTiles((prevSquares) => (
        prevSquares.map((row) => row.map((tile) => ({ ...tile, show: true })))
      ));
    };
    setTimeout(showTiles, 2000);
  };

  const handleTileClick = (tile, rowIndex, columnIndex) => {
    resetTimer();
    tilesClicked.current.count += 1;
    tilesClicked.current.tiles.push([rowIndex, columnIndex]);

    const { value } = tile;

    // check for dealbreaker
    if (value === 'D') {
      trackMixpanel('LTW Gameover', { ...tilesClicked.current, roseCount }, true);
      setDealBreakerFound(true);
      showAllTilesAfter2Sec();
    }

    // check for 3 roses
    if (value === 'R') {
      setRoseCount((prevCount) => prevCount + 1);
    }

    setTiles((prevSquares) => {
      const newSquares = [...prevSquares];
      newSquares[rowIndex][columnIndex] = {
        ...tile,
        show: true,
      };
      return newSquares;
    });
  };

  useEffect(() => {
    let id;
    if (roseCount === WIN_CONDITION) {
      trackMixpanel('LTW Win', { ...tilesClicked.current, roseCount }, true);
      showAllTilesAfter2Sec();
      id = setTimeout(() => setView('win'), 4000); // reroute after 4s
    }
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [roseCount, setView]);

  return (
    <Background disableClick={roseCount === WIN_CONDITION}>
      <BackgroundImg src="/assets/love-to-win/love-to-win-bg.webp" />
      <ContentContainer>
        <DealbreakerScreen open={dealbreakerFound} onButtonClick={handlePlayAgain} />
        <GameGrid>
          {tiles.map((row, rowIndex) => row.map((tile, columnIndex) => {
            const key = tile.value + rowIndex + columnIndex;
            return (
              <Tile
                key={key}
                onHiddenTileClick={() => handleTileClick(tile, rowIndex, columnIndex)}
                value={tile.value}
                show={tile.show}
              />
            );
          }))}
        </GameGrid>
        <TouchTextContainer>
          <TouchSvg />
          <TouchText>Tap to reveal</TouchText>
        </TouchTextContainer>
        <UncoverTextContainer>
          <TouchText>Uncover 3 Roses to Win!</TouchText>
        </UncoverTextContainer>
        <WarningText>If you find a Dealbreaker, it’s game over!</WarningText>
      </ContentContainer>
    </Background>
  );
};

export default Game;
