import React from 'react';
import styled from 'styled-components';

const ScoreLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
`;

const ScoreLoadingContainer = ({ children }) => (
  <ScoreLoading>
    {children}
  </ScoreLoading>
);

export default ScoreLoadingContainer;
