import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { feedbackQuestions } from '../../config';
import QuizNameInput from '../../components/QuizNameInput';
import SubmitLoadingContainer from '../../components/SubmitLoadingContainer';
import {
  Background, Header, SubHeader, THEMECOLOR, ResultsText, ContentSelection,
  SelectionContainer, SubmitScoreButton,
} from './style';

// eslint-disable-next-line import/prefer-default-export
export const ResultsPage = ({
  score,
  correctlyAnswered,
  name,
  handleNameChange,
  handleSelect,
  selectedContent,
  onSubmit,
  submitScoreLoading,
}) => (
  <Background src="/assets/movie-quiz-bg.webp">
    <Header>
      Results
    </Header>
    <SubHeader withSpace>
      Correctly Answered:
      {' '}
      {correctlyAnswered}
    </SubHeader>
    <SubHeader>
      Score:
      {' '}
      {score}
    </SubHeader>
    <ResultsText>Please enter your initials:</ResultsText>
    <QuizNameInput name={name} handleNameChange={handleNameChange} large color="#e5d2be" />
    <ResultsText>How can we improve?</ResultsText>
    <SelectionContainer>
      <ContentSelection
        large
        onClick={() => handleSelect(feedbackQuestions[0])}
        selected={selectedContent === feedbackQuestions[0]}
      >
        {feedbackQuestions[0]}
      </ContentSelection>
      <ContentSelection
        large
        onClick={() => handleSelect(feedbackQuestions[1])}
        selected={selectedContent === feedbackQuestions[1]}
      >
        {feedbackQuestions[1]}
      </ContentSelection>
    </SelectionContainer>
    <SelectionContainer>
      <ContentSelection
        large
        onClick={() => handleSelect(feedbackQuestions[2])}
        selected={selectedContent === feedbackQuestions[2]}
      >
        {feedbackQuestions[2]}
      </ContentSelection>
      <ContentSelection
        large
        onClick={() => handleSelect(feedbackQuestions[3])}
        selected={selectedContent === feedbackQuestions[3]}
      >
        {feedbackQuestions[3]}
      </ContentSelection>
    </SelectionContainer>
    {(submitScoreLoading)
      ? (
        <SubmitLoadingContainer>
          <ClipLoader color={THEMECOLOR} loading={submitScoreLoading} size={35} />
        </SubmitLoadingContainer>
      )
      : <SubmitScoreButton onClick={onSubmit}>See Your Ranking!</SubmitScoreButton>}
  </Background>
);
