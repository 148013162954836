/* eslint-disable no-underscore-dangle */
import ReactPlayer from 'react-player';
import React from 'react';
import { StaticImagePlayer } from './StaticImagePlayer';
import { RenderComponent } from './RenderComponent';

// eslint-disable-next-line import/prefer-default-export
const AdPlayer = ({
  component, mediaFile, duration = 15, onEnded, onError, videoElephant,
}) => {
  const playerRef = React.useRef(undefined);
  if (component) {
    return (
      <RenderComponent component={component} duration={duration} onEnded={onEnded} />
    );
  }

  if (mediaFile && mediaFile._type && mediaFile._type.includes('image')) {
    return (
      <StaticImagePlayer
        imageUrl={mediaFile.blobURL}
        duration={duration}
        onEnded={onEnded}
        onError={onError}
      />
    );
  }
  return (
    <>
      <ReactPlayer
        url={mediaFile?.blobURL}
        playing
        controls={!!videoElephant}
        height="100%"
        width="100%"
        className="adVideoPlayer"
        onEnded={() => {
          document.getElementsByTagName('video')[0].load();
          onEnded();
        }}
        volume={0}
        muted
        ref={playerRef}
        onReady={() => {}}
        onError={(err) => {
          console.log('error playing video: ', err);
          onError(err);
        }}
        style={{
          borderRadius: '5px',
          overflow: 'hidden',
        }}
        loop={false}
      />
    </>
  );
};

export default AdPlayer;
