import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import QRCode from 'qrcode.react';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import AdLoop from '../AdLoop';
import Clock from '../../components/ClockTimezoned';
import WeatherComponent from '../../components/WeatherComponent';
import { BrightnessIcon, VolumeIcon } from '../../components/SettingsModal';
import useContent from '../../context/content';
import { trackMixpanel } from '../../analytics';
import { serverUrl } from '../../config';
import {
  tileColor, Background, Row, BigBox, MediumBox, MediumSmallBox, SmallBox,
  QRContainer, QRWrapper, Space, PortlLogo, TileImg, SettingsButton, SettingsBox,
  IconText,
} from './style';

const ANIMATION_TIME = 10000; // 10 seconds
const SMALL_TILE_ANIMATION = 700;
// Theme Config * Change color for settings, time/weather, and QR box here!

// const HALLOWEEN_COLOR = {
//   dark: '#FE8000',
//   light: '#ffa52b',
// };

const QRTile = ({ IMEI, privateCode }) => {
  const index = Math.floor(Math.random() * 2); // Randomly display one or the other

  return (
    <QRContainer>
      Scan here to
      {(index)
        ? (
          <>
            <QRWrapper>
              <QRCode
                value={`${serverUrl}/tablet/remote-control/${IMEI}/${privateCode}/middle`}
                size={140}
                level="M"
                renderAs="svg"
                fgColor="#043650"
              />
            </QRWrapper>
            control from phone
          </>
        )
        : (
          <>
            <br />
            {' '}
            control from phone
            <QRWrapper>
              <QRCode
                value={`${serverUrl}/tablet/remote-control/${IMEI}/${privateCode}/bottom`}
                size={140}
                level="M"
                renderAs="svg"
                fgColor="#043650"
              />
            </QRWrapper>
          </>
        )}
    </QRContainer>
  );
};
const MemoizedQRTile = React.memo(QRTile);

const IMAGE_FADE = 0.3;
const NewWelcome = ({
  theme, incrementProgrammatic, privateCode, setShowSettings, saveImpression,
}) => {
  const history = useHistory();
  const { tiles } = useContent();
  const [selectedIndexA, setSelectedIndexA] = useState(0);
  const [selectedIndexB, setSelectedIndexB] = useState(0);
  const [selectedIndexE, setSelectedIndexE] = useState(0);

  const IMEI = localStorage.getItem('IMEI');
  const welcomeTiles = {
    A: [], B: [], C: [], D: [], E: [],
  };
  tiles.forEach(({
    place, name, imageUrl, id, smallTile, videoElephant, OnClickUrl, isActive,
  }) => {
    if (!place) return;
    if (isActive) {
      welcomeTiles[place].push({
        id, name, imageUrl: imageUrl || smallTile, videoElephant, onClickUrl: OnClickUrl,
      });
    }
  });

  const changeIndexA = () => {
    if (welcomeTiles.A.length === 1) {
      setSelectedIndexA(0);
    } else if (selectedIndexA >= welcomeTiles.A.length - 1) {
      setSelectedIndexA(0);
    } else {
      setSelectedIndexA(selectedIndexA + 1);
    }
  };

  const changeIndexB = () => {
    if (welcomeTiles.B.length === 1) {
      setSelectedIndexB(0);
    } else if (selectedIndexB >= welcomeTiles.B.length - 1) {
      setSelectedIndexB(0);
    } else {
      setSelectedIndexB(selectedIndexB + 1);
    }
  };

  const changeIndexE = () => {
    if (welcomeTiles.E.length === 1) {
      setSelectedIndexE(0);
    } else if (selectedIndexE >= welcomeTiles.E.length - 1) {
      setSelectedIndexE(0);
    } else {
      setSelectedIndexE(selectedIndexE + 1);
    }
  };

  const handleAppClick = ({ currentTarget }) => {
    const { dataset: { name, videoelephant, redirecturl } } = currentTarget;
    const data = tiles.find((element) => element.name === name);

    if (!name) return;
    if (redirecturl) {
      history.push(`${redirecturl}`);
      trackMixpanel('AppStart', { redirecturl });
    } else if (videoelephant) {
      history.push({ pathname: '/app/VideoElephant', data });
      trackMixpanel('AppStart', 'videoElephant');
    } else {
      history.push(`/app/${name}`);
      trackMixpanel('AppStart', { name });
    }
  };

  useEffect(() => {
    const timerA = setTimeout(() => {
      changeIndexA();
      changeIndexB();
      changeIndexE();
    }, ANIMATION_TIME);
    return () => clearTimeout(timerA);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndexE, selectedIndexA, selectedIndexB]);

  let count = 0;

  const openMyDriver = () => {
    count += 1;
    if (count === 5) {
      count = 0;
      history.push('/driver');
    }
  };

  const handleSettingsModal = () => {
    setShowSettings(true);
  };

  return (
    <Background theme={theme}>
      <Row>
        <BigBox>
          <AdLoop theme={theme} incrementProgrammatic={incrementProgrammatic} saveImpression={saveImpression} />
        </BigBox>
        <div>
          <CSSTransition
            key={welcomeTiles?.A[selectedIndexA]?.id}
            timeout={SMALL_TILE_ANIMATION}
            classNames="ad-loop"
            appear
          >
            <MediumBox
              onClick={handleAppClick}
              data-id="A"
              data-name={welcomeTiles?.A[selectedIndexA]?.name}
              data-videoelephant={welcomeTiles?.A[selectedIndexA]?.videoElephant}
              data-redirecturl={welcomeTiles?.A[selectedIndexA]?.onClickUrl}
            >
              <TileImg
                src={welcomeTiles?.A[selectedIndexA]?.imageUrl}
                opacityTransition={IMAGE_FADE}
              />
            </MediumBox>
          </CSSTransition>
          <Space />

          <CSSTransition
            key={welcomeTiles?.B[selectedIndexB]?.id}
            timeout={SMALL_TILE_ANIMATION}
            classNames="ad-loop"
            appear
          >
            <MediumBox
              onClick={handleAppClick}
              data-id="B"
              data-name={welcomeTiles?.B[selectedIndexB]?.name}
              data-videoelephant={welcomeTiles?.B[selectedIndexB]?.videoElephant}
              data-redirecturl={welcomeTiles?.B[selectedIndexB]?.onClickUrl}
            >
              <TileImg
                src={welcomeTiles?.B[selectedIndexB]?.imageUrl}
                opacityTransition={IMAGE_FADE}
              />
            </MediumBox>
          </CSSTransition>
        </div>
      </Row>
      <Space />

      <Row>
        <div>
          <SettingsBox onClick={handleSettingsModal}>
            <SettingsButton color={tileColor.dark}>
              <VolumeIcon />
              <IconText>Volume</IconText>
            </SettingsButton>
            <SettingsButton color="white">
              <BrightnessIcon fill={tileColor.dark} />
              <IconText color={tileColor.dark}>Brightness</IconText>
            </SettingsButton>
          </SettingsBox>
          <Space />
          <SmallBox>
            <WeatherComponent />
            <Clock />
            <PortlLogo onClick={openMyDriver}>portl</PortlLogo>
          </SmallBox>
        </div>

        {welcomeTiles?.C[0]?.name === 'RemoteControl' ? (
          <MemoizedQRTile IMEI={IMEI} privateCode={privateCode} />
        ) : (
          <MediumSmallBox
            onClick={handleAppClick}
            data-id="C"
            data-name={welcomeTiles?.C[0]?.name}
            data-redirecturl={welcomeTiles?.C[0]?.onClickUrl}
          >
            <TileImg src={welcomeTiles?.C[0]?.imageUrl} opacityTransition={IMAGE_FADE} />
          </MediumSmallBox>
        )}
        <MediumSmallBox
          onClick={handleAppClick}
          data-id="D"
          data-name={welcomeTiles?.D[0]?.name}
          data-redirecturl={welcomeTiles?.D[0]?.onClickUrl}
        >
          <TileImg src={welcomeTiles?.D[0]?.imageUrl} opacityTransition={IMAGE_FADE} />
        </MediumSmallBox>

        <CSSTransition
          key={welcomeTiles?.E[selectedIndexE]?.id}
          timeout={SMALL_TILE_ANIMATION}
          classNames="ad-loop"
          appear
        >
          <MediumSmallBox
            onClick={handleAppClick}
            data-id="E"
            data-name={welcomeTiles?.E[selectedIndexE]?.name}
            data-videoelephant={welcomeTiles?.E[selectedIndexE]?.videoElephant}
            data-redirecturl={welcomeTiles?.E[selectedIndexE]?.onClickUrl}
          >
            <TileImg
              src={welcomeTiles?.E[selectedIndexE]?.imageUrl}
              opacityTransition={IMAGE_FADE}
            />
          </MediumSmallBox>
        </CSSTransition>
      </Row>
    </Background>
  );
};

export default withTheme(NewWelcome);
