import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  color: ${({ color }) => color};
  font-weight: bold;
`;

export const ScoreStyled = styled.div`
  font-family: 'Lato', sans-serif;
  margin: 0 auto;
  width: fit-content;
  font-size: 29px;
  line-height: 1.7;
`;

export const Container = styled.div`
  font-family: 'Lato', sans-serif;
  width: 100%;
  height: 394px;
  margin: 0 auto 10px;
  font-size: 86px;
  display: flex;
  align-items: center;    
`;

export const CounterContainer = styled.div`
  flex-basis: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const blurEffect = keyframes`
  0% {
    filter: blur(22px);
  }
  80% {
    filter: none;
  }
`;

export const PictureContainer = styled.img`
  flex-basis: 725px;
  animation: ${blurEffect} 10s linear;
  height: 100%;
  border-radius: 10px;
`;
