import styled from 'styled-components';
import QuizBackground from '../../components/QuizBackground';

export const THEMECOLOR = '#cb2730';
export const GAME_NAME = 'MovieQuiz';

export const QRWrapper = styled.div`
  width: 137px;
  height: 137px;
  background: #ffffff;
  padding: 5px;
  position: absolute;
  bottom: 92px;
  right: 265px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const Background = styled(QuizBackground)`
  text-align: center;
    font-family: PlayfairDisplay;
    padding: 15px;
`;

export const StartButton = styled.button`
  width: 137px;
  height: 137px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${THEMECOLOR};
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: 'Limelight', Open Sans;
  font-size: 59px;
  color: #ffffff;
  padding: 12px;
  position: absolute;
  bottom: 92px;
  left: 284px;
  &:focus {
    outline: 0;
  }
  &:active {
    bottom: 90px
  }
`;

export const Header = styled.h1`
  font-family: Limelight;
  font-size: 65px;
  color: ${THEMECOLOR};
`;

export const SubHeader = styled.p`
  font-family: Limelight;
  color: ${THEMECOLOR};
  font-size: 36px;
  ${(props) => props.withSpace && 'padding-right: 70px;'}
  display:inline-block;
`;

export const Question = styled.p`
  font-family: PlayfairDisplay;
  font-size: 36px;
  line-height: 1.61;
  color: #e5d2be;
  max-width: 895px;
  margin: 30px auto 0;
`;

export const OptionBg = styled.div`
  width: 591px;
  height: 118px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${({ state }) => {
    switch (state) {
      case 'correct': return '#08ce64';
      case 'incorrect': return '#fc4848';
      default: return '#e5d2be';
    }
  }};
  overflow: hidden;
  text-align: left;
  font-family: PlayfairDisplay;
  font-size: 26px;
  color: black;
  
`;

export const OptionsText = styled.span`
  line-height: 118px;
  position: relative;
  top: -13px;
`;

export const OptionLetter = styled.div`
  display: inline-block;
  text-align: center;
  width: 118px;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #cb2730;
  margin-right: 16px;
  font-size: 68px;
  font-weight: bold;
  line-height: 118px;
  color: #e5d2be;
`;

export const ScoreHeader = styled.h3`
  text-transform: uppercase;
  font-family: Playfair-Display;
  font-size: 30px;
  color: #ffffff;
`;

export const TableItem = styled.div`
  position: relative;
  line-height: 42px;
  font-family: PlayfairDisplay;
  font-size: 30px;
  width: 366px;
  height: 42px;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
  color: white;
  ${({ highlight }) => highlight && (`
    background-color: #cb2730;
    border-radius: 5px;
  `)}
`;

export const ResultsText = styled.p`
  font-size: 36px;
  color: #e5d2be;
  padding-top: 30px;
`;

export const ContentSelection = styled.button`
  width: ${(props) => (props.large ? '405.5px' : '262px')};
  height: 78px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(props) => (props.selected ? '#ff8b91' : '#e5d2be')};
  text-align: center;
  line-height: 78px;
  color: #222222;
  font-size: 26px;
  &:focus{
    outline: none;
  }
`;

export const SelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 835px;
  padding-top: 25px;
  margin: 0 auto;
`;

export const SubmitScoreButton = styled.button`
  width: 405.5px;
  height: 68px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #cb2730;
  color: #e5d2be;
  font-family: Limelight;
  font-size: 30px;
  line-height: 68px;
  margin-top: 25px;
  &:focus{
    outline: none;
  }
`;

export const TableItemSpacer = styled.span`
  display: inline-block;
  width: 96px;
`;

export const PlayAgainImg = styled.img`
  height: 260px;
  width: 190px;
  position: absolute;
  bottom: 25px;
  right: 20px;
`;

export const StarSvg = styled.svg`
  position: absolute;
  top: 10px;
  ${(props) => (props.left ? 'left: 10px;' : 'right: 10px;')}
`;

export const GoButton = styled.div`
  background-color: #cb2731;
  width: 407px;
  height: 132px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  bottom: 72px;
  left: 459px;
  font-family: 'Limelight', Open Sans;
  font-size: 60px;
  color: #e3d5b9;
`;
