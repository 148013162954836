import styled from 'styled-components';

const Layout = styled.div`
display: flex;
flex-direction: column;
background-image: url(${(props) => props.theme.baseBg});
background-size: cover;
width: 100%;
height: 100vh;
overflow: hidden;
`;

export default Layout;
