import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import AnimatedTimer from './components/AnimatedTimer';

export const LowBatteryContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledToastContainer = styled(ToastContainer)`
  .toast-container {
  }
  .Toastify__toast {
    font-size: 21px;
    font-weight: 500;
    color: #222222;
    width: 519px;
    padding: 25px;
    line-height: 1.57;
    font-family: Montserrat;
    border-radius: 10px;
    box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.16);
    margin: 0;
  }
  .Toastify__toast--warning {
    background-color: #ffa000;
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--success {
    background-color: #2fd672;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export const StyledToastContainerTopLeft = styled(ToastContainer)`
  .toast-container {
  }
  .Toastify__toast {
    background-image: linear-gradient(to bottom, #4d475b, #676177);
    min-width: 600px;
    padding: 5px 15px;
    line-height: 1.31;
    font-family: Montserrat;
    border-radius: 10px;
    box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.16);
    margin: 0;
  }
  .Toastify__toast--warning {
    background-image: linear-gradient(to bottom, #4d475b, #676177);
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--success {
    background-image: linear-gradient(to bottom, #4d475b, #676177);
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export const RemoteControlPopup = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  backdrop-filter: blur(25px);
  transition: all 0.2s ease;
  background: rgb(0, 0, 0, 0.7);
  z-index: 3;
`;

export const CodeWrapper = styled.div`
  width: 858px;
  height: 405px;
  margin: 210px auto 0;
  padding: 47px 52px 47px 54px;
  border-radius: 10px;
  background-color: #0069ba;
  font-family: Montserrat;
  font-size: 36px;
  line-height: 1.61;
  text-align: center;
  color: #ffffff;
`;

export const CodeNumber = styled.p`
  text-align: center;
  margin-top: 10px;
  font-size: 90px;
  font-weight: bold;
  letter-spacing: 60px;
  text-indent: 60px; // needed to remove the letter-spacing from the last letter
  color: #ffffff;
`;

export const AnimatedTimerWrapper = styled(AnimatedTimer)`
  position: absolute;
  font-size: 86px;
  font-weight: 600;
  color: #ffffff;
  top: 30px;
  right: 31px;
`;

export const SuccessControl = styled.div`
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.57;
  text-align: left;
`;

export const PhoneIcon = styled.div`
  width: 54px;
  height: 54px;
  margin-right: 28px;
  background-image: url('/assets/phoneIcon.png');
  background-size: cover;
`;

export const RiderOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
`;
