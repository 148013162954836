import React from 'react';
import { OptionsContainer } from './style';
import { OptionBoxSvg } from './OptionBoxSvg';

const mapNumToLetter = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
};
const mapAnswerStateToFill = {
  correct: '#7ee001',
  incorrect: '#cd0e11',
};

// eslint-disable-next-line import/prefer-default-export
export const OptionsComponent = ({
  index, text, onClick, state,
}) => (
  <OptionsContainer onClick={onClick}>
    <OptionBoxSvg letter={mapNumToLetter[index]} fill={state && mapAnswerStateToFill[state]} />
    {text}
  </OptionsContainer>
);
