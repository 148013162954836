import React, { useState, useRef, useEffect } from 'react';
import { generateCode } from '../../utils/helper';
import { trackMixpanel } from '../../analytics';
import {
  IMEI,
  socket,
} from '../../socket';

import useCodeConfirmation from '../../utils/customHooks';

const Game = ({
  appName,
  tiles,
  setTiles,
  view: GameRenderComp,
  winCondition,
  setView,
  resetTimer,
  generateRandomTiles,
  isSocket,
}) => {
  const [loseTileFound, setLoseTileFound] = useState(false);
  const [winTileCount, setWinTileCount] = useState(0);
  const tilesClicked = useRef({
    count: 0,
    tiles: [],
  });

  const showAllTilesAfter2Sec = () => {
    const showTiles = () => {
      setTiles((prevSquares) => (
        prevSquares.map((row) => row.map((tile) => ({ ...tile, show: true })))
      ));
    };
    setTimeout(showTiles, 2000);
  };

  const handleTileClick = (tile, rowIndex, columnIndex) => {
    resetTimer();
    tilesClicked.current.count += 1;
    tilesClicked.current.tiles.push([rowIndex, columnIndex]);

    const { value } = tile;

    // Check for lose condition
    // Check for all lose condition which can be but not limited to: L1 L2 L3...
    if (value.includes('L')) {
      trackMixpanel(`${appName} GameOver`, { ...tilesClicked.current, winTileCount }, true);
      setLoseTileFound(value);
      showAllTilesAfter2Sec();
      if (isSocket) socket.emit('flipLostTileFound');
    }

    // Check for 3 win tiles
    if (value === 'W') {
      setWinTileCount((prevCount) => prevCount + 1);
    }

    setTiles((prevSquares) => {
      const newSquares = [...prevSquares];
      newSquares[rowIndex][columnIndex] = {
        ...tile,
        show: true,
      };
      return newSquares;
    });
  };

  const handlePlayAgain = () => {
    trackMixpanel(`${appName} Play Again`, { ...tilesClicked.current, winTileCount }, true);
    const newTiles = generateRandomTiles();
    setTiles(newTiles);
    setWinTileCount(0);
    tilesClicked.current = {
      count: 0,
      tiles: [],
    };
    resetTimer();
    setLoseTileFound(false);
    setView('game');
    // if there is an active socket connection, emit newTiles to socket
    isSocket && socket.emit('flipGameRestarted', newTiles);
  };

  // For sockets
  useEffect(() => {
    socket.on('tileClicked', ({ tile, rowIndex, columnIndex }) => {
      handleTileClick(tile, rowIndex, columnIndex);
    });

    socket.on('playAgainFlip', () => {
      handlePlayAgain();
    });

    return () => {
      socket.off('tileClicked');
      socket.off('playAgainFlip');
    };
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    let id;
    if (winTileCount === winCondition) {
      trackMixpanel(`${appName} Win`, { ...tilesClicked.current, winTileCount }, true);
      showAllTilesAfter2Sec();
      id = setTimeout(() => setView('win'), 4000); // reroute after 4s
      if (isSocket) socket.emit('flipGameWon');
    }
    return () => {
      if (id) clearTimeout(id);
    };
    // eslint-disable-next-line
    }, [winTileCount, setView]);

  return (
    <GameRenderComp
      handlePlayAgain={handlePlayAgain}
      handleTileClick={handleTileClick}
      tiles={tiles}
      loseTileFound={loseTileFound}
      winTileCount={winTileCount}
      winCondition={winCondition}
    />
  );
};

const FlipGame = ({
  appName,
  resetTimer,
  GameRenderComp,
  HowToPlayComponent,
  WinComponent,
  winCondition,
  generateRandomTiles,
}) => {
  const [view, setView] = useState('');
  const [tiles, setTiles] = useState(generateRandomTiles());
  const code = useRef(generateCode(5));
  const [isSocket, setSocket] = useState(false);

  const handlePlayClick = () => {
    resetTimer();
    setView('game');
  };

  useCodeConfirmation(appName, code.current, () => {
    socket.emit('eventCodeConfirmation');
    socket.off(appName);
  });

  // Socket useEffect
  useEffect(() => {
    socket.on('startFlipGame', () => {
      socket.emit('gameFlipStarted', { tiles, code });
      setSocket(true);
      handlePlayClick();
    });

    return () => {
      socket.off('startFlipGame');
      isSocket && socket.emit('endGame');
    };
    // eslint-disable-next-line
    }, []);

  switch (view) {
    case 'game': return (
      <Game
        appName={appName}
        tiles={tiles}
        setTiles={setTiles}
        setView={setView}
        resetTimer={resetTimer}
        view={GameRenderComp}
        winCondition={winCondition}
        generateRandomTiles={generateRandomTiles}
        isSocket={isSocket}
      />
    );
    case 'win': return (
      <WinComponent
        appName={appName}
        code={code}
        IMEI={IMEI}
        isSocket={isSocket}
      />
    );
    default: return <HowToPlayComponent handlePlayClick={handlePlayClick} />;
  }
};

export default FlipGame;
