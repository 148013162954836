import React, { useState, useEffect, useRef } from 'react';
import { socket } from '../../socket';
import PictureQuizTemplate from '../../compositions/PictureQuizTemplate';
import { THEMECOLOR } from './style';
import QuizBackground from '../../components/QuizBackground';

const QuizGame = ({
  questions, onStepChange, isSocket, score, setInfo,
}) => {
  const [data, setData] = useState({
    url: '',
    options: [],
    number: 0,
  });

  const totalQuestions = questions.length;
  const isMounted = useRef(true);

  // eslint-disable-next-line consistent-return
  const handleChangeData = (num) => {
    const number = num;
    if (!questions[number]) return onStepChange();
    const url = questions[number].question;
    const options = questions[number].Options;

    setData({ url, options, number });

    if (isSocket) {
      socket.emit('question', number + 1);
    }
  };

  useEffect(() => {
    handleChangeData(0);

    return () => { isMounted.current = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextQuestion = () => {
    if (isMounted.current) {
      handleChangeData(data.number + 1);
    }
  };

  return (
    <QuizBackground src="/assets/christmasBackground.webp" font="'Mountains of Christmas', cursive">
      <PictureQuizTemplate
        score={score}
        setInfo={setInfo}
        isSocket={isSocket}
        data={data}
        optColor={THEMECOLOR}
        optBck="#f1f8ff"
        color="#1f2639"
        optFontSize={45}
        totalQuestions={totalQuestions}
        nextQuestion={handleNextQuestion}
      />
    </QuizBackground>
  );
};

export default QuizGame;
