import React, { useRef } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import Clock from '../../components/ClockTimezoned';
import { createZendeskTicket } from '../../api';
import { getObject } from '../../utils/helper';

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0 10px 30px;
`;

const Logo = styled.div`
    width: 125px;
    font-family: Montserrat;
    font-size: 53px;
    font-weight: 700;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: -2.12px;
    color: #1fb6ff;
`;
const DebugText = styled.pre`
    color: white;
    padding: 15px 20px;
`;
const Background = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #1c1c1c;
    background: url('/assets/sunset-error.webp') center center no-repeat;
    background-size: cover;
`;
const TextBottom = styled.div`
    position: absolute;
    bottom: 30px;
`;
const ErrorText = styled.p`
    color: white;
    padding-left: 30px;
    font-size: 32px;
    font-weight: 500;
    margin: 0;
`;
const ErrorSubTitle = styled(ErrorText)`
    font-weight: 300;
`;

const Button = styled.button`
    color: white;
    display: block;
    height: 40px;
    width: 160px;
    margin-right: 30px;
    margin-top: 12px;
    line-height: 40px;
    background-color: #1fb6ff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 21px;
`;

const Container = styled.div`
    margin: 0 auto;
    width: fit-content;
`;

const RETRY_INTERVAL = 30 * 1000; // 30 sec

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const [showError, setShowError] = React.useState(false);
  const count = useRef(0);

  React.useEffect(() => {
    const timeout = setTimeout(resetErrorBoundary, RETRY_INTERVAL);

    const driver = getObject('driver');
    const email = driver && driver.email;
    const imei = localStorage.getItem('IMEI');
    Sentry.captureException(error);
    createZendeskTicket({ email }, `Global Tablet Error: ${imei}`, error.message + error.stack);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [resetErrorBoundary, error.message, error.stack, error]);

  const handleShowError = () => {
    count.current += 1;
    if (count.current >= 10) {
      setShowError((prevState) => (!prevState));
      count.current = 0;
    }
  };

  return (
    <Background data-test-id="global-error">
      <Flex>
        <Logo onClick={handleShowError} data-test-id="logo">Portl</Logo>
        <Button onClick={resetErrorBoundary}>Refresh</Button>
      </Flex>
      <Container>
        <Clock />
      </Container>
      {showError && <DebugText data-test-id="debug">{error.stack}</DebugText>}
      <TextBottom>
        <ErrorText>
          We&apos;re currently experiencing a technical issue but we&apos;re on it.
        </ErrorText>
        <ErrorSubTitle>In the meantime, enjoy a sunset!</ErrorSubTitle>
      </TextBottom>
    </Background>
  );
};

export default ErrorFallback;
