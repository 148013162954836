import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SettingsSlider from '../SettingsSlider';
import {
  changeTabletBrightness, changeTabletVolume, getTabletBrightness, getTabletVolume,
} from '../../socket/index';

const BackgroundOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: ${({ visible }) => (visible ? '1' : '0')};
    backdrop-filter: blur(25px);
    transition: all 0.2s ease;
    background: rgba(0,0,0,0.5);
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    z-index: 100;
    display: flex;
    justify-content: space-between;
    padding: 118px 340px;
`;
const ToggleButton = styled.div`
    width: 170px;
    height: 156px;
    border-radius: 20px;
    background-color: ${({ selected }) => (selected ? '#0069ba' : '#212121')};
    margin-bottom: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const ToggleText = styled.p`
    margin: 0;
    font-size: 23px;
    color: white;
    padding-top: 15px;
`;
const CloseContainer = styled.div`
    position: absolute;
    top: 35px;
    width: 65px;
    height: 65px;
    right: 35px;
`;
const HalfMoonIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height={65} viewBox="0 0 24 24" width={65}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill="#fff" d="M10 2c-1.82 0-3.53.5-5 1.35C7.99 5.08 10 8.3 10 12s-2.01 6.92-5 8.65C6.47 21.5 8.18 22 10 22c5.52 0 10-4.48 10-10S15.52 2 10 2z" />
  </svg>
);
const MuteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height={65} viewBox="0 0 24 24" width={65}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path fill="#fff" d="M4.34 2.93L2.93 4.34 7.29 8.7 7 9H3v6h4l5 5v-6.59l4.18 4.18c-.65.49-1.38.88-2.18 1.11v2.06c1.34-.3 2.57-.92 3.61-1.75l2.05 2.05 1.41-1.41L4.34 2.93zM10 15.17L7.83 13H5v-2h2.83l.88-.88L10 11.41v3.76zM19 12c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87 0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zm-7-8l-1.88 1.88L12 7.76zm4.5 8c0-1.77-1.02-3.29-2.5-4.03v1.79l2.48 2.48c.01-.08.02-.16.02-.24z" />
  </svg>
);
export const BrightnessIcon = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="59.013" height="59.013" viewBox="0 0 59.013 59.013">
    <path fill="none" d="M0 0h59.013v59.013H0z" />
    <path fill={fill || '#043650'} d="M48.171 20.361V8.829H36.639L28.5.69l-8.139 8.139H8.829v11.532L.69 28.5l8.139 8.139v11.532h11.532l8.139 8.138 8.139-8.139h11.532V36.639l8.138-8.139zM43.253 34.6v8.655H34.6l-6.1 6.1-6.1-6.1h-8.653V34.6l-6.1-6.1 6.1-6.1v-8.653H22.4l6.1-6.1 6.1 6.1h8.655V22.4l6.1 6.1zM28.5 13.747v29.506a14.753 14.753 0 1 0 0-29.506z" transform="translate(1.007 1.007)" />
  </svg>
);
export const VolumeIcon = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="63.841" height="63.841" viewBox="0 0 63.841 63.841">
    <path fill="none" d="M0 0h63.841v63.841H0z" />
    <path fill={fill || '#fff'} d="M3 18.576v15.958h10.639l13.3 13.3V5.278l-13.3 13.3zm18.618-.452v16.862l-5.771-5.771H8.319V23.9h7.527zm17.288 8.431a11.969 11.969 0 0 0-6.649-10.718v21.41a11.9 11.9 0 0 0 6.648-10.692zM32.256 3.23v5.479a18.624 18.624 0 0 1 0 35.692v5.479a23.92 23.92 0 0 0 0-46.65z" transform="translate(4.984 5.366)" />
  </svg>
);
export const CloseIcon = ({ fill, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size || 70} viewBox="0 0 24 24" width={size || 70}>
    <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
    <path fill={fill || '#fff'} d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z" />
  </svg>
);

const SettingsModal = ({ showOverlay, setShowOverlay }) => {
  const [volume, setVolume] = useState(50);
  const [brightness, setBrightness] = useState(50);
  const brightnessRef = useRef();
  const volumeRef = useRef();

  const handleBrightnessChange = (num, e) => {
    if (e) e.stopPropagation();
    changeTabletBrightness(num);
    setBrightness(num);
    brightnessRef.current.changeValue(num);
  };
  const handleVolumeChange = (num, e) => {
    if (e) e.stopPropagation();
    changeTabletVolume(num);
    setVolume(num);
    volumeRef.current.changeValue(num);
  };

  useEffect(() => {
    setVolume(getTabletVolume());
    setBrightness(getTabletBrightness());

    // eslint-disable-next-line
    }, [showOverlay]);

  return (
    <BackgroundOverlay visible={showOverlay} onClick={() => setShowOverlay(false)}>
      <SettingsSlider
        onAfterChange={handleVolumeChange}
        initialValue={volume}
        ref={volumeRef}
        svg={() => <VolumeIcon fill="#404040" />}
      />
      <SettingsSlider
        onAfterChange={handleBrightnessChange}
        svg={() => <BrightnessIcon fill="#404040" />}
        initialValue={brightness}
        ref={brightnessRef}
      />
      <div>
        <ToggleButton
          selected={brightness === 0}
          onClick={(e) => handleBrightnessChange(brightness === 0 ? 50 : 0, e)}
        >
          <HalfMoonIcon />
          <ToggleText>Night Mode</ToggleText>
        </ToggleButton>
        <ToggleButton
          selected={volume === 0}
          onClick={(e) => handleVolumeChange(volume === 0 ? 50 : 0, e)}
        >
          <MuteIcon />
          <ToggleText>Mute Tablet</ToggleText>
        </ToggleButton>
      </div>
      <CloseContainer>
        <CloseIcon />
      </CloseContainer>
    </BackgroundOverlay>
  );
};

export default React.memo(SettingsModal);
