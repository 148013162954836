import React from 'react';
import { GoBackButtonContainer, IconContainer, Text } from './style';

const GoBackButton = ({ onClick }) => (
  <GoBackButtonContainer onClick={onClick}>
    <IconContainer src="/assets/portl-spotlight/left-arrow.png" size="24px" padding="14px" />
    <Text size="25px">
      Go Back
    </Text>
  </GoBackButtonContainer>
);
export default GoBackButton;
