import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import App from './App';
import { setAutoBrightness } from './utils/helper';
import { isProduction, isStaging } from './config';
import overrideConsoleLog from './logger';
import { changeTabletBrightness } from './socket/index';

if (isStaging || isProduction) {
  overrideConsoleLog()
    .then((hasError) => hasError && console.log('Fail to override console log. Zendesk ticket sent.', hasError));
}

console.log('process.env.REACT_APP_ENV: ', process.env.REACT_APP_ENV);

changeTabletBrightness(50);
setAutoBrightness();

// Initialize React Google Analytics
if (isProduction) {
  ReactGA.initialize('UA-129510306-1', {
    debug: true,
    siteSpeedSampleRate: 100,
  });

  mixpanel.init('c16aa89e5ccb82ea62c9f983a915e7be');
} else {
  // Test token
  mixpanel.init('ab483d9d56a863b06ca6010998a861f7');
  // staging, test, dev, localhost
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>, document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
