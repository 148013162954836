import React from 'react';
import { trackMixpanel } from '../../analytics';
import {
  PageWrapper, ImgWrapper, DescriptionContainer, Background, VideoOverlay, Text, LinksContainer,
  SocialMediaContainer, SocialMediaDiv, IconContainer, ContentWrapper, VideoPageWrapper,
} from './style';
import PlayControls from './PlayControls';
import OverlayTextWrapper from './OverlayTextWrapper';
import LinkContainer from './LinkContainer';
import VideoPlayer from './VideoPlayer';
import { BioWrapper, SocialWrapper } from './Wrappers';
import GoBackButton from './GoBackButton';

export const HomePage = ({
  setPage,
  artistData,
}) => {
  // Handle page click to bio, video, social page from home page
  const handlePageClick = (pageName) => {
    // Remove close button when not on home page
    trackMixpanel('SpotlightPageClick', { pageName }, true);
    setPage(pageName);
  };

  return (
    <PageWrapper>
      <ImgWrapper>
        <Background src={artistData && artistData.intro_video_img}>
          <VideoOverlay onClick={() => handlePageClick('intro_video')}>
            <OverlayTextWrapper artistData={artistData} />
            <PlayControls />
          </VideoOverlay>
        </Background>
      </ImgWrapper>
      <DescriptionContainer>
        <Text size="27px" lineHeight={1.7}>
          {artistData && artistData.description}
        </Text>
      </DescriptionContainer>
      <LinksContainer>
        <LinkContainer onClick={() => handlePageClick('bio')} src={artistData && artistData.bio_page_tile_img} placeholder="/assets/portl-spotlight/placeholder.jpg" name="Biography" />
        <LinkContainer onClick={() => handlePageClick('videos')} src={artistData && artistData.video_page_tile_img} placeholder="/assets/portl-spotlight/placeholder.jpg" name="Music Videos" />
        <LinkContainer onClick={() => handlePageClick('social')} src={artistData && artistData.social_tile_img} placeholder="/assets/portl-spotlight/placeholder.jpg" name={artistData ? `Follow ${artistData?.name?.split(' ')[0]}` : 'Follow'} />
      </LinksContainer>
      <SocialMediaContainer>
        {artistData && artistData.social_media_links.slice(0, 2).map((socialmedia) => (
          <SocialMediaDiv key={`${socialmedia.website}-${socialmedia.acnt_name}`}>
            <IconContainer size="38px" padding="18px" src={socialmedia.logo_dark} />
            <Text size="24px" color="#262525">{socialmedia.acnt_name}</Text>
          </SocialMediaDiv>
        ))}
      </SocialMediaContainer>
    </PageWrapper>
  );
};

export const ContentPage = ({
  handleGoBack, type, artistData, src,
}) => (
  <Background src={src}>
    <OverlayTextWrapper artistData={artistData} />
    <ContentWrapper paddingTop="50px">
      {type === 'bio' && <BioWrapper bioDesc={artistData.bio_description} />}
      {type === 'social' && <SocialWrapper socialLinks={artistData.social_media_links} />}
      <GoBackButton onClick={handleGoBack} />
    </ContentWrapper>
  </Background>
);

export const VideoPage = ({
  handleGoBack,
  artistData,
  url,
  showSettings,
}) => (
  <VideoPageWrapper>
    <VideoPlayer
      url={url}
      artistData={artistData}
      handleGoBack={handleGoBack}
      showSettings={showSettings}
    />
  </VideoPageWrapper>
);
