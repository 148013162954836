import styled from 'styled-components';

export const ImgWrapper = styled.div`
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
  flex-grow: 1;
  position: relative;
  z-index: 1;
`;

export const DescriptionContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-column-start: 1;
  grid-row-start: 2;
  padding: 10px 0 0 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 950px auto;
  grid-template-rows: 535px auto;
  column-gap: 3px;
  position: relative;
`;

export const Text = styled.p`
  font-family: Lora;
  font-size: ${({ size }) => (size || '30px')};
  color: ${({ color }) => (color || '#262525')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  padding: 0px ${({ padding }) => (padding || '0px')};
  line-height: ${({ lineHeight }) => (lineHeight || 1.5)};
`;

export const LinksContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-column-start: 2;
  grid-row-start: 1;
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 3px;
`;

export const LinkWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url("${({ src }) => src}");
  background-size: cover;
  position: relative;
`;

export const ColorDivider = styled.div`
  background-color: ${({ color }) => color};
  height: 19px;
  width: 140%;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0 0 0;
`;

export const PlayIcon = styled.img`
  position: absolute;
  top: 60px;
  left: 150px;
  height: 65px;
  width: 65px;
`;

export const FullScreen = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Logo = styled.img`
  width: 100px;
  height: 100px;
`;

export const FullscreenStyled = styled.img`
  position: absolute;
  bottom: 60px;
  right: 60px;
  height: 30px;
  width: 30px;
`;
