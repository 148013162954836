/* eslint-disable camelcase */
// eslint-disable-next-line import/prefer-default-export
export const defaultTiles = [
  {
    id: 1,
    name: 'Quizzle',
    imageUrl: 'https://portl-welcomeitems.s3.amazonaws.com/quizzle-tile.png',
    place: 'A',
    isActive: true,
    contentType: 'GameZone',
    title: 'Quizzle',
    description:
        'Test your video game knowledge against other Uber and Lyft riders and reign supreme!',
    created_at: '2020-12-01T16:41:51.007Z',
    updated_at: '2021-02-13T01:21:52.767Z',
  },
  {
    id: 2,
    name: 'NothingQuiz',
    imageUrl: 'https://portl-welcomeitems.s3.amazonaws.com/nothing-quiz-tile.png',
    place: null,
    isActive: true,
    contentType: 'GameZone',
    title: 'The Quiz About Nothing',
    description:
        'Alright so this is going to get a bit awkward. There are no wrong answers here!',
    created_at: '2020-12-01T16:42:07.544Z',
    updated_at: '2021-02-13T01:21:18.697Z',
  },
  {
    id: 4,
    name: 'UberGiveaway',
    imageUrl: 'https://portl-welcomeitems.s3.amazonaws.com/uber-giveaway-tile.png',
    place: 'D',
    isActive: true,
    contentType: null,
    title: null,
    description: null,
    created_at: '2020-12-01T16:42:42.235Z',
    updated_at: '2021-02-13T01:22:13.628Z',
  },
  {
    id: 7,
    name: 'Spotlight',
    imageUrl: 'https://portl-welcomeitems.s3.amazonaws.com/spotlight-tile.jpg',
    place: null,
    isActive: true,
    contentType: null,
    title: null,
    description: null,
    created_at: '2021-01-05T16:48:58.307Z',
    updated_at: '2021-02-13T01:21:31.965Z',
  },
  {
    id: 8,
    name: 'GameZone',
    imageUrl: 'https://portl-assets.s3.amazonaws.com/gameZoneTile.webp',
    place: 'B',
    isActive: true,
    contentType: null,
    title: null,
    description: null,
    created_at: '2021-01-29T20:42:49.086Z',
    updated_at: '2021-02-12T16:02:47.484Z',
  },
  {
    id: 9,
    name: 'MovieQuiz',
    imageUrl: 'https://portl-assets.s3.amazonaws.com/movie-quiz-tile.webp',
    place: null,
    isActive: true,
    contentType: 'GameZone',
    title: 'The Quiz About Movies',
    description:
        'Test your knowledge of movie trivia skills. To all those Netflix bingers, this is your time to shine!',
    created_at: '2021-02-01T23:43:36.132Z',
    updated_at: '2021-02-13T01:21:11.188Z',
  },
  {
    id: 10,
    name: 'PortlTips',
    imageUrl: 'https://portl-welcomeitems.s3.amazonaws.com/portl-tips-tile.png',
    place: 'C',
    isActive: false,
    contentType: null,
    title: null,
    description: null,
    created_at: '2021-02-02T00:03:03.664Z',
    updated_at: '2021-02-13T01:21:45.677Z',
  },
  {
    id: 11,
    name: 'LoveToWin',
    imageUrl: 'https://portl-assets.s3.amazonaws.com/Love+To+Win+Tile+2.webp',
    place: 'E',
    isActive: true,
    contentType: 'GameZone',
    title: 'Love To Win',
    description:
        'Love is on the air! Find 3 roses and send them to that special someone.. on us!',
    created_at: '2021-02-08T18:12:39.583Z',
    updated_at: '2021-03-02T23:16:36.859Z',
  },
  {
    id: 12,
    name: 'PortlShorts',
    imageUrl: 'https://portl-welcomeitems.s3.amazonaws.com/Portl+Shorts+Tile+2.webp',
    place: 'A',
    isActive: true,
    contentType: null,
    title: null,
    description: null,
    created_at: '2021-03-02T23:16:48.885Z',
    updated_at: '2021-03-02T23:16:48.885Z',
  },
];
