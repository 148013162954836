import React from 'react';

import QRCodeWithData from '../../components/QRCodeWithData';
import { serverUrl } from '../../config';
import { Background, QRWrapper, GoButton } from './style';

export const GAME_NAME = 'NothingQuiz';

export const StartQuiz = ({ onStepChange, privateCode, IMEI }) => (
  <Background src="/assets/NothingQuizHome.jpg">
    <GoButton onClick={onStepChange}>
      <p>Go!</p>
    </GoButton>
    <QRWrapper>
      <QRCodeWithData name="QuizNothing" size={171} redirectUrl={`${serverUrl}/rider/${GAME_NAME}/${IMEI}/${privateCode}`} />
    </QRWrapper>
  </Background>
);
