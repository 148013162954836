import styled from 'styled-components';

const QuizBackground = styled.div`
    width: 100%;
    height: 100%;
    background-image: url('${({ src }) => src}');
    background-size: cover;
    position: relative;
    ${({ font }) => font && `font-family: ${font}`};
`;

export default QuizBackground;
