/* eslint-disable no-underscore-dangle */
import parseVAST from 'iab-vast-parser';
import {
  getMedia, getAdomniAd,
} from '../../api';

// eslint-disable-next-line import/prefer-default-export
export const requestAdomni = async (incrementProgrammatic) => {
  incrementProgrammatic('adomniAdReq');
  const vast = await getAdomniAd();
  if (vast.error) {
    return vast;
  }

  let parsed;
  try {
    parsed = parseVAST(vast);
  } catch (error) {
    console.log('Error parsing Adomni VAST file', error);
    return { error };
  }
  const ad = parsed && parsed._ads && parsed._ads.get(0);

  if (!ad) {
    return { error: parsed, msg: '_ads not found in VAST object', empty: true };
  }

  const creative = ad._creatives.get(0);
  const impression = ad._impressions[0];
  const mediaFile = creative._linear._mediaFiles[0];
  const duration = creative._linear._duration;
  const pop = impression._uri;

  const response = await getMedia(mediaFile._uri, 'adomni');
  if (response.error) {
    return response;
  }

  mediaFile.blobURL = response;

  return {
    mediaFile,
    duration,
    pop,
    adPartner: 'adomni',
  };
};
