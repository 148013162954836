import React from 'react';
import QRCodeWithData from '../components/QRCodeWithData';
import {
  Background, QRContainer, QRPadding, QRText,
} from './UberGiveawayComponents/style';

const UberGiveaway = () => (
  <Background src="/assets/uber-giveaway-bg.webp">
    <QRContainer>
      <QRPadding>
        <QRCodeWithData name="UberGiveaway" fileName="uberGiveaway.html" size={160} />
      </QRPadding>
      <QRText>Scan to learn more</QRText>
    </QRContainer>
  </Background>
);

export default UberGiveaway;
