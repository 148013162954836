import styled from 'styled-components';

export const ImgWrapper = styled.div`
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
  flex-grow: 1;
  position: relative;
  z-index: 1;
`;

export const VideoOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  grid-template-rows: 75px auto 180px;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 30%, rgba(255, 255, 255, 0) 49%), url("${({ src }) => src}");
  background-size: cover;
  position: relative;
  display: grid;
  grid-template-rows: 75px auto;
`;

export const DescriptionContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-column-start: 1;
  grid-row-start: 2;
  padding: 0 68px 0 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 950px auto;
  grid-template-rows: 535px auto;
  column-gap: 3px;
  position: relative;
`;

export const Text = styled.p`
  font-family: Lora;
  font-size: ${({ size }) => (size || '30px')};
  color: ${({ color }) => (color || '#262525')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  padding: 0px ${({ padding }) => (padding || '0px')};
  line-height: ${({ lineHeight }) => (lineHeight || 1.5)};
`;

export const LinksContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-column-start: 2;
  grid-row-start: 1;
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 3px;
`;

export const LinkWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url("${({ src }) => src}");
  background-size: cover;
  position: relative;
`;

export const ColorDivider = styled.div`
  background-color: ${({ color }) => color};
  height: 19px;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 3px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const PlayIcon = styled.img`
  position: absolute;
  top: 60px;
  left: 150px;
  height: 65px;
  width: 65px;
`;

export const Viewed = styled.img`
  background-color: green;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
