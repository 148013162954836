import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';
import { getLastLocation } from './store/location';
import { getObject } from './utils/helper';

const getAnalyticsInfo = async () => {
  const position = await getLastLocation();
  const driver = getObject('driver');
  let driverId;

  if (driver) {
    const { _id } = driver;
    driverId = _id;
  }

  const email = driver && driver.email;
  const imei = localStorage.getItem('IMEI');
  let portlWebviewVersion;
  const commit = process.env.REACT_APP_GIT_SHA;

  try {
    portlWebviewVersion = window.DeviceApi.getAppVersion(); // only works for webview
  } catch (error) {
    // Logs every mixpanel event if not defined which is unnecessary
    // console.log("Error getting portl webview version: ", error);
  }

  let faceDetected;
  if (window.DeviceApi && window.DeviceApi.faceDetected) {
    faceDetected = window.DeviceApi.faceDetected();
  }

  const info = {
    timestamp: Date.now(),
    position,
    driver: email,
    driverId,
    imei,
    portlWebviewVersion,
    commit,
    faceDetected,
    // Adding this for built in mixpanel location reporting (heartbeat events)
    $latitude: position.lat,
    $longitude: position.lng,
  };

  return info;
};

const recordEngagement = async () => {
  console.log('Recording engagement');
  if (process.env.REACT_APP_ENV === 'production') {
    try {
    // Register engagement:
      const engagement = await getAnalyticsInfo();
      mixpanel.track('Engagement', engagement);
      mixpanel.time_event('Timeout');
    } catch (err) {
      console.log(err);
    }
  }
};

const recordWestJetEvent = async (eventName, consumerEmail) => {
  try {
    const info = await getAnalyticsInfo();
    mixpanel.track(eventName, { ...info, inputEmail: consumerEmail });
  } catch (err) {
    console.log(err);
  }
};

const trackPageview = (path) => {
  if (process.env.REACT_APP_ENV === 'production') {
    console.log('Reporting pageview: ', path);
    ReactGA.set({ page: path }); // Update the user's current page
    ReactGA.pageview(path); // Record a pageview for the given page
    mixpanel.track('Pageview');
  }
};

const trackMixpanel = async (eventName, eventData, slim) => {
  let final;
  const userInfo = await getAnalyticsInfo();
  if (slim) {
    final = ({
      $latitude, $longitude, driver, imei,
    }) => ({
      $latitude, $longitude, driver, imei,
    })(userInfo);
  } else {
    final = userInfo;
  }
  mixpanel.track(eventName, { ...final, ...eventData });
};

export {
  recordEngagement,
  getAnalyticsInfo,
  recordWestJetEvent,
  trackPageview,
  trackMixpanel,
};
