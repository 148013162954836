import React, { useState, useEffect } from 'react';
import { trackMixpanel } from '../analytics';
import { getStrapiThunderDungeons, updateStrapiThunderDungeons } from '../api';
import VideoPlayer from '../components/VideoPlayer';
import {
  PageContainer, VideoPageWrapper, Header, Logo, VideoListContainer, ThunderDungeonLogo,
} from './ThunderDungeonComponents/style';
import { VideoCard } from './ThunderDungeonComponents/VideoCard';

const ThunderDungeon = ({ clearTimer, resetTimer, onEnded }) => {
  const [videoPlayList, setVideoPlayList] = useState();
  const [currentVideo, setCurrentVideo] = useState();

  // Fetch videos from strapi and set videoPlayList with data
  useEffect(() => {
    let isMounted = true;
    const getPlayList = async () => {
      const { activeData, error } = await getStrapiThunderDungeons();
      if (error) onEnded();
      if (isMounted) {
        setVideoPlayList(activeData);
      }
    };
    getPlayList();
    return () => { isMounted = false; };
    // eslint-disable-next-line
  }, []);

  // Function to handle
  const handleVideoClose = () => {
    setCurrentVideo('');
    resetTimer();
  };

  const handleVideoClick = (url, videoTitle, id, numViews) => {
    // Update numViews state in videoPlayList for video that was clicked
    const videoIndex = videoPlayList.findIndex((video) => video.id === id);
    setVideoPlayList((prevState) => [
      ...prevState.slice(0, videoIndex),
      { ...prevState[videoIndex], numViews: numViews + 1 },
      ...prevState.slice(videoIndex + 1),
    ]);

    // set current video state
    setCurrentVideo({
      url,
      title: videoTitle,
    });
    clearTimer();
    trackMixpanel('ThunderDungeonsVideoClick', { url, videoTitle }, true);

    // Iterate number of views when video is clicked
    try {
      updateStrapiThunderDungeons(id, { numViews: numViews + 1 });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {(currentVideo)
        ? (
          <VideoPageWrapper>
            <VideoPlayer
              url={currentVideo.url}
              handleVideoClose={handleVideoClose}
              appName="ThunderDungeons"
              title={currentVideo.title}
              resetTimer={resetTimer}
              clearTimer={clearTimer}
            />
          </VideoPageWrapper>
        )
        : (
          <PageContainer>
            <Header>
              <Logo>portl</Logo>
              <ThunderDungeonLogo src="/thunder-dungeon-logo.webp" />
            </Header>
            <VideoListContainer>
              {videoPlayList && videoPlayList.map(({
                id, title, videoUrl, imgUrl, numViews, videoLength,
              }) => (
                <VideoCard
                  key={id + title}
                  title={title}
                  img={imgUrl}
                  length={videoLength}
                  views={numViews}
                  onClick={() => handleVideoClick(videoUrl, title, id, numViews)}
                />
              ))}
            </VideoListContainer>
          </PageContainer>
        )}
    </>
  );
};

export default ThunderDungeon;
