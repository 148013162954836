import React from 'react';
import styled from 'styled-components';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const Container = styled.div`
    margin: 0 auto;
`;

const AnimatedTimer = ({
  time, color, onTimerComplete, isPlaying, keyId, size, trailColor, className,
}) => (
  // if onTimerComplete function returns [shouldRepeat: boolean, delay: number],
  // the first element shouldRepeat indicates if the loop should start over and
  // second element delay specifies the delay before looping again in milliseconds.
  // the key property is used to restart the timer,
  // if the key props is changed, the timer will reset.
  // the isPlaying property will play/pause the timer

  <Container className={className}>
    <CountdownCircleTimer
      key={keyId}
      size={size}
      duration={time}
      colors={color}
      trailColor={trailColor}
      onComplete={onTimerComplete}
      isPlaying={isPlaying}
    >
      {({ remainingTime }) => remainingTime}
    </CountdownCircleTimer>
  </Container>
);

export default AnimatedTimer;
