import React from 'react';
import LazyLoadImage from '../../components/LazyLoadImage';
import {
  VideoCardTitle, VideoCardWrapper, VideoImage, ViewsText, ViewsTimeContainer,
  LengthText, Dot, ImageLoading,
} from './style';

// eslint-disable-next-line import/prefer-default-export
export const VideoCard = ({
  title,
  img,
  length,
  views,
  onClick,
}) => {
  const loaded = LazyLoadImage(img);

  return (
    <VideoCardWrapper>
      {(loaded)
        ? <VideoImage src={loaded} onClick={onClick} alt="video image" />
        : <ImageLoading />}
      <VideoCardTitle>{title}</VideoCardTitle>
      <ViewsTimeContainer>
        <ViewsText>
          {views}
          {' '}
          views
        </ViewsText>
        <Dot />
        <LengthText>{length}</LengthText>
      </ViewsTimeContainer>
    </VideoCardWrapper>
  );
};
