import React from 'react';
import {
  Container, Content, StartTablet, StartButton, StartPhone,
} from './style';
import { QuizTitle } from './QuizTitle';

// eslint-disable-next-line import/prefer-default-export
export const QuizBegin = ({ isSocket, handleClick }) => (
  <Container>
    <QuizTitle />
    <Content>
      There was once a rider who believed
      {' '}
      <br />
      {' '}
      their knowledge of video games
      <br />
      {' '}
      surpassed that of all other riders...
    </Content>
    <Content>
      ...that rider was then called upon
      {' '}
      <br />
      to prove themselves and attain
      <br />
      glory in the rider kingdom.
    </Content>
    {isSocket
      ? (
        <StartPhone>
          Click start on your phone to begin!
        </StartPhone>
      )
      : (
        <StartTablet>
          Remember, faster answers will get you more points!
          <StartButton src="/assets/quizStartBtn.png" onClick={handleClick} />
        </StartTablet>
      )}
  </Container>
);
