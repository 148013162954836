import React, { useEffect } from 'react';
import { THEMECOLOR } from './style';
import QuizScoreTemplate from '../../compositions/QuizScoreTemplate';
import { socket } from '../../socket';
import QuizBackground from '../../components/QuizBackground';

const QuizScore = ({
  isSocket, info, setInfo, onSubmit,
}) => {
  useEffect(() => {
    if (!isSocket) return null;

    socket.once('persistScore', (receivedInfo) => {
      onSubmit(receivedInfo);
    });

    socket.emit('scoreInfo', info);

    return () => socket.off('persistScore');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = setTimeout(() => onSubmit(), 30000);

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QuizBackground src="/assets/christmasBackground.webp" font="'Mountains of Christmas', cursive">
      <QuizScoreTemplate
        themeColor={THEMECOLOR}
        info={info}
        setInfo={setInfo}
        onSubmit={onSubmit}
      />
    </QuizBackground>
  );
};

export default QuizScore;
