import ImageFadeIn from 'react-image-fade-in';
import styled from 'styled-components';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: url("${(props) => (props.src)}") center center no-repeat;
  background-size: cover;
  color: white;
  position: relative;
  overflow: none;
  display: flex;
  flex-direction: column-reverse;
`;

export const BgOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: #000000;
`;

export const FadeImg = styled(ImageFadeIn)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  filter: brightness(70%);
`;

export const Logo = styled.p`
  font-size: 24px;
  position: absolute;
  top: 10px;
  left: 20px;
  color: white;
`;

export const Blue = styled.span`
  color: #1fb6ff;
  font-weight: bold;
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 100px 20px 20px;
  display: flex;
  justify-content: space-between;
`;

export const ArticleContent = styled.div`
  //width: 70%;
`;

export const Title = styled.h1`
  font-size: 36px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 1.22;
`;

export const SubTitle = styled.p`
  padding: 10px 0;
  font-size: 24px;
`;

export const TimeText = styled.span`
  padding-left: 15px;
`;

export const Text = styled.p`
  font-size: 24px;
  line-height: 1.54;
  padding-top: 20px;
`;

export const QRContainer = styled.div`
  padding: 5px;
  width: 160px;
  height: 160px;
  margin: 0 50px 25px;
  background-color: white;
`;

export const Sidebar = styled.div`
  text-align: center;
  padding: 0 10px;
`;

export const QRText = styled.p`
  font-size: 24px;
  text-align: center;
  height: 29px;
`;

export const QRTextBold = styled(QRText)`
  font-weight: bold;
`;
