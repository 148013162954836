import React from 'react';
import { trackMixpanel } from '../../analytics';
import { ProgressContainer, ProgressFiller, VideoSlider } from './style';

const VideoProgressBar = ({
  played,
  setPlayed,
  videoRef,
  url,
}) => {
  const handleSeekChange = (evt) => {
    const videoSeekTo = parseFloat(evt.target.value);
    setPlayed(videoSeekTo);
    videoRef.current.seekTo(videoSeekTo);
    trackMixpanel('SpotlightSeek', { url, videoSeekTo }, true);
  };

  return (
    <ProgressContainer>
      <ProgressFiller played={played} />
      <VideoSlider
        type="range"
        min={0}
        max={0.999999}
        step="any"
        value={played}
        onChange={handleSeekChange}
      />
    </ProgressContainer>
  );
};

export default VideoProgressBar;
