import React from 'react';
import QRCode from 'qrcode.react';
import { ContainerStart, QRWrapper, QuestionMarkButton } from './style';

// eslint-disable-next-line import/prefer-default-export
export const StartScreen = ({ onClick, qrCode }) => (
  <ContainerStart>
    <QuestionMarkButton onClick={onClick} />
    <QRWrapper>
      <QRCode
        value={qrCode}
        size={126}
        level="Q"
        renderAs="svg"
      />
    </QRWrapper>
  </ContainerStart>
);
