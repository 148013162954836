import { useEffect } from 'react';
import { socket } from '../socket';

const useCodeConfirmation = (eventName, code, onSuccess) => {
  useEffect(() => {
    socket.on(eventName, (receivedCode) => {
      if (code === receivedCode) {
        if (onSuccess) onSuccess();
      }
    });

    return () => socket.off(eventName);
    // eslint-disable-next-line
    }, []);
};

export default useCodeConfirmation;
