import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { serverUrl } from '../config';

import { getLastLocation } from './location';

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 1 * 60 * 60 * 1000, // 1 hour
});

axios.defaults.withCredentials = true;

const api = axios.create({
  adapter: cache.adapter,
});

export const hour = () => new Date().getHours();

export const getCurrentPosition = () => new Promise((resolve, reject) => (navigator.geolocation
  ? navigator.geolocation.getCurrentPosition(
    ({ coords }) => resolve(coords),
    (err) => reject(new Error(`Position not found. Error: ${err.description}`)),
  )
  : reject(new Error('Device doesnt have position'))));

export const getWeather = async () => {
  const { lat, lng } = await getLastLocation();
  const time = new Date().getTime() / 1000;

  try {
    const { data } = await api.get(`${serverUrl}/tablet/weather`, { params: { lat, lng } });
    if (data) {
      const { formattedList, city } = data;

      const dayNight = (time >= city.sunrise && time <= city.sunset) ? 'day' : 'night';

      return {
        description: data.list[0] && data.list[0].weather[0] ? data.list[0].weather[0].description : '',
        dayNight,
        temp: data.list[0] && data.list[0].main ? data.list[0].main.temp : '',
        weather: formattedList,
        city,
      };
    }
    return { error: true };
  } catch (error) {
    console.log(error, 'errr');
    return { error };
  }
};

const MONTH_AVERAGE = [
  { sunriseAve: { h: 7, m: 52 }, sunsetAve: { h: 16, m: 50 } }, // Jan
  { sunriseAve: { h: 7, m: 35 }, sunsetAve: { h: 17, m: 27 } }, // Feb
  { sunriseAve: { h: 7, m: 42 }, sunsetAve: { h: 18, m: 6 } }, // Mar
  { sunriseAve: { h: 6, m: 58 }, sunsetAve: { h: 19, m: 44 } }, // Apr
  { sunriseAve: { h: 6, m: 10 }, sunsetAve: { h: 20, m: 19 } }, // May
  { sunriseAve: { h: 5, m: 39 }, sunsetAve: { h: 20, m: 52 } }, // Jun
  { sunriseAve: { h: 6, m: 5 }, sunsetAve: { h: 20, m: 43 } }, // Jul
  { sunriseAve: { h: 6, m: 39 }, sunsetAve: { h: 19, m: 56 } }, // Aug
  { sunriseAve: { h: 7, m: 13 }, sunsetAve: { h: 19, m: 2 } }, // Sep
  { sunriseAve: { h: 7, m: 51 }, sunsetAve: { h: 18, m: 11 } }, // Oct
  { sunriseAve: { h: 7, m: 54 }, sunsetAve: { h: 16, m: 43 } }, // Nov
  { sunriseAve: { h: 7, m: 51 }, sunsetAve: { h: 16, m: 41 } }, // Dez
];

const getSunTimeOffline = () => {
  const date = new Date();

  const month = date.getMonth();
  const { sunriseAve, sunsetAve } = MONTH_AVERAGE[month];

  date.setHours(sunriseAve.h, sunriseAve.m, 0);
  const sunrise = date.getTime(); // Day in milliseconds

  date.setHours(sunsetAve.h, sunsetAve.m, 0);
  const sunset = date.getTime(); // Day in milliseconds

  return { sunrise, sunset };
};

export const getSunTime = async () => {
  const location = await getLastLocation();
  // I decided to use 1 decimal number because I want to have 11.1 km of precision
  const lat = location.lat.toFixed(1);
  const lng = location.lng.toFixed(1);

  try {
    const response = await api.get(
      `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${lng}&formatted=0`,
      { withCredentials: false },
    ); // https://sunrise-sunset.org/api
    const { status, results } = response.data;

    if (status === 'OK') {
      const sunrise = new Date(results.sunrise).getTime(); // Get time in millisecond
      const sunset = new Date(results.sunset).getTime();
      return { sunrise, sunset };
    }
    console.log('Error on the sunrise/sunset api fetching response:', status);
  } catch (error) {
    console.log('Error on the sunrise/sunset api fetching:', error);
  }
  return getSunTimeOffline();
};
