import React, { useState } from 'react';
import styled from 'styled-components';

const Background = styled.div`
    background: url("${({ src }) => src}") center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
`;

const PortlTips = ({ onEnded, resetTimer }) => {
  const [index, setIndex] = useState(1);

  const handlePicClick = () => {
    if (index === 4) return onEnded();
    // otherwise increase index
    resetTimer();
    return setIndex((prev) => prev + 1);
  };

  return <Background src={`/assets/portl-tips/tips${index}.png`} onClick={handlePicClick} />;
};

export default PortlTips;
