import * as Yup from 'yup';

export const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

export const phone = Yup.string().trim()
  .matches(phoneRegExp, 'Please enter a valid phone number.')
  .required('Please enter a valid phone number.');

export const email = Yup.string().trim()
  .email('Please enter a valid email.')
  .required('Please enter a valid email.');
