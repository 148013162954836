import baseBg from './base-background.jpg';
import logoPortl from '../portl-logo-white.png';
import uber from '../uberLogoWhite.svg';
import lyft from '../lyftLogoWhite.svg';
import plus from '../plus.svg';

const theme = {
  primary: '#F8F8F8',
  secondary: '#ffffff',
  ternary: '#000000',
  baseBg,
  logoPortl,
  logoRide: { uber, lyft },
  plus,
  welcomeInterimBg: '/PortlSafetyTips.webp',
  brightnessIcon: 'brightness_white.png',
  volumeIcon: 'volume_white.png',
  batteryIcon: 'battery_white_',
  welcomeBgColor: '#343434',
  appBackground: '#000',
};

export default theme;
