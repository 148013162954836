import React, { useRef } from 'react';
import queryString from 'query-string';

import { serverUrl } from '../config';
import { generateCode } from '../utils/helper';
import QuizBackground from '../components/QuizBackground';
import Quiz from '../views/Quiz';
import {
  Question, OptionBg, OptionLetter, OptionsText, GAME_NAME, GoButton,
} from './MovieQuizComponents/style';
import { ResultsPage } from './MovieQuizComponents/ResultsPage';
import { LeaderboardPage } from './MovieQuizComponents/LeaderboardPage';
import { QuizHeader } from './MovieQuizComponents/QuizHeader';
import { StartScreen } from './MovieQuizComponents/StartScreen';

const mapNumToLetter = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
};

const OptionsComponent = ({
  index, text, onClick, state,
}) => (
  <OptionBg onClick={onClick} state={state}>
    <OptionLetter>{mapNumToLetter[index]}</OptionLetter>
    <OptionsText>{text}</OptionsText>
  </OptionBg>
);

const QuestionComponent = ({ text }) => (
  <Question>
    {text}
  </Question>
);

const RulesScreen = ({ handleClick }) => (
  <QuizBackground src="/assets/movie-quiz-rules.webp">
    <GoButton onClick={handleClick}>
      <p>LET`S GO!</p>
    </GoButton>
  </QuizBackground>
);

const NUM_OF_QUESTIONS = 10;

const MovieQuiz = ({ onEnded, clearTimer, resetTimer }) => {
  const parsed = queryString.parse(window.location.search);

  const privateCode = useRef(parsed.remoteControl || generateCode(15));
  const IMEI = localStorage.getItem('IMEI');

  return (
    <Quiz
      quizName={GAME_NAME}
      privateCode={privateCode.current}
      onEnded={onEnded}
      clearTimer={clearTimer}
      resetTimer={resetTimer}
      quizCategory="Entertainment: Film"
      numOfQuestions={NUM_OF_QUESTIONS}
      OptComponent={OptionsComponent}
      HeaderComponent={QuizHeader}
      background="movie-quiz-bg.webp"
      QuestionComponent={QuestionComponent}
      StartScreen={StartScreen}
      ExplainerScreen={RulesScreen}
      qrCode={`${serverUrl}/rider/${GAME_NAME}/${IMEI}/${privateCode.current}`}
      LeaderboardPage={LeaderboardPage}
      ScorePage={ResultsPage}
    />
  );
};

export default MovieQuiz;
