/* eslint-disable no-underscore-dangle */
import parseVAST from 'iab-vast-parser';
import {
  getHivestackAd,
  getMedia,
} from '../../api';

// eslint-disable-next-line import/prefer-default-export
export const requestHivestack = async (incrementProgrammatic) => {
  incrementProgrammatic('hivestackAdReq'); // increment counter in App.js for heartbeats
  const vast = await getHivestackAd();
  if (vast.error) {
    return vast;
  }
  let parsed;
  try {
    parsed = parseVAST(vast);
  } catch (error) {
    console.log('Error parsing Hivestack VAST file', error);
    return { error };
  }

  const ads = parsed._adPod;
  if (!ads) {
    return { error: parsed, msg: '_adPod not found in VAST object', empty: true };
  }

  const creative = ads._ads.get(0)._creatives.get(0);
  const impression = ads._ads.get(0)._impressions[0];
  const mediaFile = creative._linear._mediaFiles[0];
  const duration = creative._linear._duration;
  const pop = impression._uri;

  const response = await getMedia(mediaFile._uri, 'hivestack');
  if (response.error) {
    return response;
  }

  mediaFile.blobURL = response;

  return {
    mediaFile,
    duration,
    pop,
    adPartner: 'hivestack',
  };
};
