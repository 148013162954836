import React, { useEffect, useState } from 'react';
import {
  GoogleMap, LoadScript, Marker,
} from '@react-google-maps/api';
import {
  DetailsModal,
} from './TourGuide/DetailsModal';
import {
  getTourGuideCategories,
  getTourGuideItems,
} from '../api';
import LegendModal from './TourGuide/LegendModal';
import { QuestionMark, Question } from './TourGuide/style';
import Loading from '../components/Loading';

const containerStyle = {
  flex: 1,
  width: '100%',
  height: '100%',
};

const TourGuide = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [tourGuideCategories, setTourGuideCategories] = useState(undefined);
  const [tourGuideItems, setTourGuideItems] = useState(undefined);
  const [coordinates, setCoordinates] = useState(undefined);
  const [legendModalVisible, setLegendModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [touchedMarker, setTouchedMarker] = useState(undefined);

  async function getCategories() {
    const categories = await getTourGuideCategories();
    setTourGuideCategories(categories);
  }

  async function getItems() {
    const items = await getTourGuideItems();
    setTourGuideItems(items);
  }
  useEffect(() => {
    getCategories();
    getItems();
  }, []);

  const mappedTourGuideItems = tourGuideItems?.map((item) => {
    const itemCategory = tourGuideCategories?.find((category) => category.title === item.category);
    return {
      ...item,
      category: itemCategory?.title,
      color: itemCategory?.color,
    };
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoordinates(
        { lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude) },
      );
    });
    setLoading(false);
  }, []);

  const onClickMarker = (element) => {
    setModalVisible(!modalVisible);
    const marker = mappedTourGuideItems.filter((item) => item.id === element.id);
    setTouchedMarker(marker);
  };

  return (
    loading ? <Loading /> : (
      <LoadScript
        googleMapsApiKey="AIzaSyD8gKUAaItflRl3Z42uEZBfuE-dOW-4oR0"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={coordinates}
          zoom={13}
          options={{ styles: [{ elementType: 'labels', featureType: 'poi', stylers: [{ visibility: 'off' }] }], mapTypeControl: false, streetViewControl: false }}
        >
          {
            mappedTourGuideItems && mappedTourGuideItems.map((item) => (
              <Marker
                key={item.id}
                position={{ lat: item.lat, lng: item.lng }}
                icon={{
                  path: 'M30.816,1A27.437,27.437,0,0,0,3.3,28.356c0,13.837,23.037,38.331,26.94,42.4a.771.771,0,0,0,1.12,0c3.9-4.04,26.94-28.629,26.94-42.4A27.436,27.436,0,0,0,30.816,1Zm0,37.122A9.795,9.795,0,1,1,37.823,35.3a9.793,9.793,0,0,1-7.007,2.826Z',
                  fillColor: item.color,
                  fillOpacity: 1,
                  strokeWeight: 1.5,
                  scale: 0.6,
                }}
                onClick={() => onClickMarker(item)}
              />
            ))
          }
          <Marker position={coordinates} icon="/assets/car.png" />
        </GoogleMap>
        {
          legendModalVisible
          && (
            <LegendModal
              tourGuideCategories={tourGuideCategories}
              setLegendModalVisible={setLegendModalVisible}
            />
          )
        }
        {modalVisible
          && (
            <DetailsModal
              details={touchedMarker && touchedMarker[0]}
              setModalVisible={setModalVisible}
            />
          )}
        <QuestionMark onClick={() => setLegendModalVisible(!legendModalVisible)}>
          <Question>?</Question>
        </QuestionMark>
      </LoadScript>
    )
  );
};
export default TourGuide;
