import styled from 'styled-components';

export const Background = styled.div`
  background-image: url('${({ src }) => src}');
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  font-family: 'Playfair Display', serif;
`;

export const QRWrapper = styled.div`
  width: 183px;
  height: 183px;
  padding: 6px;
  background: #ffffff;
  position: absolute;
  bottom: 13px;
  left: 486px;
`;

export const Header = styled.h2`
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.74);
  font-family: FeniceStd;
  font-size: 50px;
  font-style: oblique;
  letter-spacing: -2.7px;
  color: #dc0921;
  text-align: center;
  padding-top: 50px;
`;

export const QuestionWrapper = styled.p`
  margin: 31px auto 0;
  width: 1100px;
  height: 183px;
  font-size: 38px;
  line-height: 1.61;
  text-align: center;
  color: #f2f2f2;
`;

export const OptionsWrapper = styled.div`
  width: 1200px;
  margin: 29px auto 0;
  display: flex;
  justify-content: space-between;
`;

export const OptionContainer = styled.div`
  width: 591px;
  height: 118px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fafafa;
  font-size: 26px;
  line-height: 1.62;
  text-align: left;
  color: #202020;
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

export const Opt = styled.div`
  flex: 0 0 118px;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-weight: bold;
  color: #0982f1;
  text-transform: uppercase;
  margin-right: 11px;
  text-align: center; 
  line-height: 1.75;
  font-size: 68px;

  ${(props) => props.showResults && `
    font-size: 46px;
    line-height: 2.6;
    background: linear-gradient(0deg, #bee0ff ${props.percent}%, #ffffff ${props.percent}%);
  `}
`;

export const ChangeWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: ${({ isBonus }) => (isBonus ? '322px' : '199px')};
  height: 51px;
  margin-left: 50%;
  left: ${({ isBonus }) => (isBonus ? '-161px' : '-100px')};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.85;
  text-align: center;
  color: #0982f1;
`;

export const OkayButton = styled.div`
  background-color: white;
  width: 474px;
  height: 155px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #0e7ff8;
  font-size: 50px;
  font-weight: bold;
  position: absolute;
  bottom: 196px;
  right: 76px;
`;

export const GoButton = styled.div`
  background-color: white;
  width: 192px;
  height: 195px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #0e7ff8;
  font-size: 60px;
  font-weight: bold;
  position: absolute;
  bottom: 225px;
  left: 480px;
`;

export const Arrow = styled.img`
  width: 70px;
  height: 70px;
`;
