import { openDB } from 'idb';
import { createZendeskTicket } from '../api';
import { disableIndexedDB } from '../config';

const LOGS_TABLE = 'logTable';
const LOGS = 'logs';
const LOGS_LIMIT = 300000; // rough estimate of 150mb

export const zendeskTicketWrapper = (msg) => {
  const value = localStorage.getItem('driver');
  const driver = value && JSON.parse(value);
  const email = driver && driver.email;
  const IMEI = localStorage.getItem('IMEI');
  createZendeskTicket({ email }, `Tablet ${IMEI} error`, msg);
};

export const storageSetup = async () => {
  let errorMessage;

  if (navigator.storage && navigator.storage.persist && navigator.storage.estimate) {
    try {
      const granted = await navigator.storage.persist();
      if (granted) console.log('Storage is now going to be persistent...', granted);
    } catch (error) {
      errorMessage = `Client did not allow storage to be persistent..${error}`;
    }

    try {
      const response = await navigator.storage.estimate();
      console.log('Storage Estimate, ', response, 'Usage: ', ((response.usage * 100) / response.quota).toFixed(2));
    } catch (error) {
      errorMessage = `estimate() is not supported on tablet, ${error}`;
    }
  }

  if (errorMessage) {
    zendeskTicketWrapper(errorMessage);
  }
};

if (!('indexedDB' in window)) {
  disableIndexedDB();
  const shouldSend = process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'production';
  if (shouldSend) zendeskTicketWrapper('User tablet does not support Indexed DB.');
}

if (navigator.storage && navigator.storage.persist) { // .persist() not supported on FireFox
  storageSetup().then(() => console.info('Storage Setup Completed'));
}

class IndexedDB {
  constructor() {
    this.db = undefined;
    this.count = 0;
    this.oldestEntry = 0;
  }

  async databaseInit() {
    try {
      this.db = await openDB(LOGS_TABLE, 1, {
        upgrade: (database) => {
          // Create a store of objects
          const store = database.createObjectStore(LOGS, {
            keyPath: 'id',
            autoIncrement: true,
            // Possible Errors: store already exists, called on a database that does not exist,
            // autoIncrementing on empty string
          });

          store.createIndex('time', 'time');
          store.createIndex('level', 'level'); // console.eror, console.log, 'info', 'debug' 'error' 'warnn
          store.createIndex('content', 'content');
          store.createIndex('id', 'id');
        },
      }); // According to MDN, no errors are thrown in this. This function is only called when
      // the version doesnt exist. It does not call again afterwards
    } catch (error) {
      console.log('Database failed to initiation: ', error);
      zendeskTicketWrapper(`IndexDB Failed to initialize:${error}`);
      return { error };
    }

    try {
      const idIndex = this.db.transaction(LOGS, 'readwrite').store.index('id');
      const firstCursor = await idIndex.openCursor(null);
      this.oldestEntry = firstCursor.value.id;
    } catch (error) {
      this.oldestEntry = 1;
    }

    try {
      this.count = await this.db.count(LOGS);
    } catch (e) {
      this.count = 0;
    }
    return null;
  }

  async addLogsToDB(args, level) {
    try {
      if (this.count > LOGS_LIMIT) {
        this.db.delete(LOGS, this.oldestEntry);
        this.oldestEntry += 1;
      }

      await this.db.put(LOGS, {
        time: new Date().getTime(),
        content: args,
        level,
      });
      this.count += 1;
    } catch (error) {
      // Return error object so function stops being called.
      return { error };
    }
    return null;
  }

  async getLastXLogs(lines) {
    try {
      const idIndex = this.db.transaction(LOGS, 'readwrite').store.index('id');
      const lastCursor = await idIndex.openCursor(null, 'prevunique');
      const firstId = lastCursor.value.id - lines > 0 ? lastCursor.value.id - lines : 0;
      const payload = await this.db
        .getAll(LOGS, IDBKeyRange.bound(firstId, lastCursor.value.id, true, false));
      return payload;
    } catch (error) {
      console.log('Error getting logs: ', error);
      zendeskTicketWrapper(error);
      return { error };
    }
  }

  async getAllLogs() {
    try {
      const payload = await this.db.getAll(LOGS);
      return payload;
    } catch (error) {
      console.log('Error getting all logs', error);
      return { error };
    }
  }

  async clearAllLogs() {
    let countBefore; let
      countAfter;
    try {
      countBefore = await this.countLogs();
      await this.db.clear(LOGS);
      countAfter = await this.countLogs();
      return { countBefore, countAfter };
    } catch (error) {
      console.log('Could not complete execution of removal. ', error);
      return { error };
    }
  }

  async countLogs() {
    try {
      return await this.db.count(LOGS);
    } catch (error) {
      console.log('Could not count logs ', error);
      return { error };
    }
  }
}

const indexedDB = new IndexedDB();
// logger.js's database  would be disabled on local, so enabling here.
if (process.env.NODE_ENV === 'development') {
  indexedDB.databaseInit();
}

export default indexedDB;
