import styled from 'styled-components';

export const Background = styled.div`
  text-align: center;
  position: relative;
  background: url('/assets/four-leaf-fortune/FourLeafFortuneBg.webp') center center no-repeat;
  width: 100%;
  height: 100%;
  ${({ disableClick }) => disableClick && 'pointer-events: none;'}
`;

export const RulesViewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 190px;
`;

export const TestYourLuckText = styled.h1`
  padding: 0;
  margin: 0;
  font-family: FuturaPT-Bold;
  font-size: 36px;
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.72);
  color: #f1f8ff;
  position: relative;
  padding-bottom: 30px;
`;

export const RulesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 70px;
`;

export const RuleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-right: 60px;
`;

export const StartButton = styled.button`
  width: 250px;
  height: 65px;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.63);
  border: solid 4px #0b0f33;
  background-color: #2b7c20;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 75px;
  right: 40px;
  border-radius: 10px;
`;

export const StartText = styled.p`
  font-family: CryUncial;
  font-size: 32px;
  text-transform: uppercase;
  color: #ffffff;
  padding-right: 15px;
`;

export const ThreeColverImg = styled.img`
  width: 80px;
  height: 42px;
`;

export const RuleNumber = styled.p`
  box-sizing: content-box;
  font-family: CryUncial;
  font-size: 80px;
  text-shadow: 0 10px 10px rgba(0, 0, 0, 0.65);
  color: #f1f8ff;
  position: absolute;
  top: 0;
  left: 0;
`;

export const RuleImg = styled.img`
  //width: ${({ width }) => width};
  //height: 140px;
  margin-top: 20px;
`;

export const CircularRuleImg = styled.img`
  margin-top: 20px;
  margin-bottom: 8px;
  border-radius: 50%;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
`;

export const RuleText = styled.p`
  width: ${({ width }) => width};
  font-family: FuturaPT-Bold;
  font-size: 27px;
  font-weight: bold;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.65);
  text-align: center;
  color: #f1f8ff;
`;

export const CongratsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 180px;
  padding-left: 95px;
  text-align: left;
`;

export const CongratsHeader = styled.h1`
  font-family: CryUncial;
  font-size: 50px;
  text-shadow: 0 10px 10px rgba(0, 0, 0, 0.64);
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 50px;
`;

export const CongratsText = styled.p`
  width: 620px;
  font-family: FuturaPT-Light;
  font-size: 36px;
  text-shadow: 0 8px 6px rgba(0, 0, 0, 0.72);
  line-height: 46px;
  color: #f1f8ff;
`;

export const FailText = styled(CongratsText)`
  line-height: 36px;
`;

export const CongratsMugImg = styled.img`
  height: 370px;
  width: 370px;
  position: absolute;
  top: 125px;
  right: 0;
`;

export const QRCodeContainer = styled.div`
  padding-top: 10px;
  border-radius: 5px;
  border: solid 1px #707070;
  width: 240px;
  height: 240px;
  background-color: #ffffff;
  position: absolute;
  top: 400px;
  right: 305px;
`;

export const GameContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const LoserOverlay = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url('/assets/four-leaf-fortune/FourLeafFortuneBg.webp') center center no-repeat;
  ${(props) => props.open && 'transition: visibility 0.3s ease 4s, opacity 4s;'}
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? '1' : '0')};
`;

export const FailHeader = styled(CongratsHeader)`
  margin-bottom: 30px;
`;

export const PlayAgainButton = styled(StartButton)`
  position: relative;
  top: 0;
  left: 0;
  width: 365px;
  height: 105px;
  font-family: CryUncial;
  font-size: 44px;
  color: #ffffff;
  text-align: left;
`;

export const LeprechaunImg = styled.img`
  width: 464px;
  height: 513px;
  position: absolute;
  top: 0px;
  right: 50px;
`;

export const Coaster = styled.img`
  width: 145px;
  height: 145px;
  border-radius: 50%;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.56);
`;

export const GameGrid = styled.div`
  position: relative;
  display: grid;
  grid-template: repeat(5, 1fr) / repeat(5, 1fr);
  grid-gap: 25px;
  width: 680px;
  padding-left: 80px;
  padding-top: 170px;
  ${({ disableClick }) => disableClick && 'pointer-events: none;'}
`;

export const EmptyDiv = styled.div`
  height: 145px;
  width: 145px;
`;

export const DripImg = styled.img`
  position: absolute;
  width: 37px;
  height: 65px;
  top: 400px;
  right: 215px;
`;

export const DripOnGlass = styled(DripImg)`
  top: 310px;
  right: 272px;
`;

export const GlassImg = styled.img`
  height: 365px;
  width: 273px;
  position: absolute;
  top: 230px;
  right: 55px;
`;
