/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const RenderComponent = ({ component: Component, duration, onEnded }) => {
  const timer = useRef();

  const clearTimer = () => {
    clearTimeout(timer.current);
  };

  useEffect(() => {
    timer.current = setTimeout(onEnded, duration * 1000);
    return () => clearTimer(); // Clean up the subscription
  });

  const resetTimer = (dur = duration) => {
    clearTimer();
    timer.current = setTimeout(onEnded, dur * 1000);
  };

  return (
    <>
      <Component onEnded={onEnded} clearTimer={clearTimer} resetTimer={resetTimer} />
    </>
  );
};
