import React from 'react';

import {
  LoserOverlay, GridContainer, Image, Text, OverText, PlayAgain,
} from './style';

export const looseOptions = {
  L1: {
    url: '/assets/easter/magazines.webp',
    text: 'magazines',
    color: '#7e5dcf',
  },
  L2: {
    url: '/assets/easter/pill_bottle.webp',
    text: 'sports drink',
    color: '#89d26a',
  },
  L3: {
    url: '/assets/easter/rope.webp',
    text: 'jump rope',
    color: '#f3d01e',
  },
  L4: {
    url: '/assets/easter/rum_bottle.webp',
    text: 'vitamins',
    color: '#1fb6ff',
  },
  L5: {
    url: '/assets/easter/saw.webp',
    text: 'tools',
    color: '#dd65ba',
  }
  ,
};

export const LoseScreen = ({ open, onButtonClick }) => (
  <LoserOverlay open={open}>
    {
        looseOptions[open] && (
        <GridContainer>
          <div>
            <Image src={looseOptions[open].url} />
            <Text fontSize="21px">We can&apos;t give this to children!</Text>
            <OverText color={looseOptions[open].color}>GAME OVER</OverText>
          </div>
          <div>
            <Text fontSize="43px" margin="180px 0 0">
              Looks like you found the
              {' '}
              <br />
              {' '}
              Easter Bunny&apos;s
              {' '}
              {looseOptions[open].text}
              .
              <br />
              <br />
              Maybe that&apos;s why he overslept?
            </Text>
            <PlayAgain onClick={onButtonClick} />
          </div>
        </GridContainer>
        )
      }
  </LoserOverlay>
);
