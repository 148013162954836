import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { socket } from '../../socket';
import { getQuizScore, getQuizQuestionsOpenTrivia } from '../../api';
import { trackMixpanel } from '../../analytics';

// Components
import Loading from '../../components/Loading';
import CountdownTimer from '../../components/CountdownTimer';

const Container = styled.div`

    width: 100%;
    height: 100%;
    background-image: url('/assets/quizBck2.webp');
    background-size: cover; 
    padding: 20px;
    font-family: 'Press Start 2P', cursive;
    color: #ffffff;
`;

const QuetionsContainer = styled(Container)`
    font-size: 23px;
    background-image: url('/assets/${(props) => props.src || 'quizBck1.webp'}');
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    padding-bottom: 80px;
`;

const Question = styled.div`
    max-width: 895px;
    line-height: 1.61;
    margin: 54px auto 0;
`;

const AnswersContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin: 40px 20px 0 0;
`;

const Grid = styled.div`
    display: inline-grid;
    justify-items: start;
    grid-gap: 33px;
    margin-left: 20px;
`;

const ScoreBoard = ({
  info, setInfo, onSubmit, view: View, submitScoreLoading,
}) => {
  const [selectedContent, setSelectContent] = useState();

  const handleChange = ({ target }) => {
    let userName = target.value;
    if (target.value.length > target.maxLength) {
      userName = target.value.slice(0, target.maxLength);
    }
    setInfo((prev) => ({ ...prev, name: userName }));
  };

  const handleSelect = (elemId) => {
    setSelectContent(elemId);
    setInfo((prev) => ({ ...prev, content: elemId }));
  };

  return (
    <View
      score={info.score}
      name={info.name}
      handleNameChange={handleChange}
      handleSelect={handleSelect}
      selectedContent={selectedContent}
      onSubmit={() => onSubmit()}
      correctlyAnswered={info.correct}
      submitScoreLoading={submitScoreLoading}
    />
  );
};

const DefaultOptionComponent = ({ index, text, onClick }) => (
  <div
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex="0"
  >
    {`${index + 1}. ${text}`}
  </div>
);

const DefaultQuestionComponent = ({ text }) => (
  <Question>
    {text}
  </Question>
);

const TIME_TO_SHOW_ANSWERS = 2000;
const Answers = ({
  currentQuestion, OptComponent, handleAnswer, questionNumber, timerRef,
}) => {
  // either empty "", 'correct', or 'incorrect'
  const [questionsState, setQuestionsState] = useState([]);

  const showAnswers = (qIndex) => {
    if (questionsState.length > 0) return; // disable click if already showing

    timerRef.current.stopTimer();
    const { correctAnswer, options } = currentQuestion; // index of correct answer

    const newState = new Array(options.length).fill('');
    newState[qIndex] = 'incorrect';
    newState[correctAnswer] = 'correct';
    setQuestionsState(newState);

    // show next question in 2 seconds
    setTimeout(() => handleAnswer(questionNumber, qIndex), TIME_TO_SHOW_ANSWERS);
  };

  useEffect(() => {
    socket.once('answerQuestion', ({ opt }) => {
      showAnswers(opt);
    });

    return () => socket.off('answerQuestion');
  });

  useEffect(() => {
    setQuestionsState([]);
  }, [currentQuestion]);

  return (
    <AnswersContainer>
      <Grid>
        <OptComponent
          onClick={() => showAnswers(0)}
          index={0}
          text={currentQuestion.options[0]}
          state={questionsState[0]}
        />
        <OptComponent
          onClick={() => showAnswers(2)}
          index={2}
          text={currentQuestion.options[2]}
          state={questionsState[2]}
        />
      </Grid>
      <Grid>
        <OptComponent
          onClick={() => showAnswers(1)}
          index={1}
          text={currentQuestion.options[1]}
          state={questionsState[1]}
        />
        <OptComponent
          onClick={() => showAnswers(3)}
          index={3}
          text={currentQuestion.options[3]}
          state={questionsState[3]}
        />
      </Grid>
    </AnswersContainer>
  );
};

const Quiz = ({
  quizName,
  privateCode,
  qrCode,
  clearTimer,
  resetTimer,
  onEnded,
  quizCategory,
  numOfQuestions,
  background,
  OptComponent = DefaultOptionComponent,
  HeaderComponent,
  QuestionComponent = DefaultQuestionComponent,
  StartScreen,
  ExplainerScreen,
  LeaderboardPage,
  ScorePage,
  questionDuration = 15,
}) => {
  const [questions, setQuestions] = useState([]);
  const [number, setNumber] = useState(0);
  const [view, setView] = useState('selection'); // also intro and quiz
  const [isSocket, setSocket] = useState(false);

  // leaderboard/score state
  const [sortedTop10, setSortedTop10] = useState([]);
  const [submitScoreLoading, setSubmitScoreLoading] = useState(false);
  const [position, setPosition] = useState();
  const [info, setInfo] = useState({
    name: '',
    content: '',
    score: 0,
    correct: 0,
  });

  const scorePerQuestion = useRef();

  const resetQuiz = () => {
    resetTimer();
    setView('selection');
    setSortedTop10([]);
    setPosition();
    setInfo({
      name: '',
      content: '',
      score: 0,
      correct: 0,
    });
    setNumber(0);
    setQuestions([]);
  };

  const handleShowQuestions = () => {
    clearTimer();
    setView('quiz');
  };

  const nextQuestion = useCallback(() => {
    if (questions[number + 1]) {
      if (isSocket) socket.emit('question', number + 1);
      return setNumber((prev) => prev + 1);
    }
    const data = {
      privateCode, // to track same user throughout their 15m session
      name: quizName,
      remote: !!isSocket,
    };
    trackMixpanel('QuizEnd', data, true);
    return setView('score');
  }, [isSocket, number, privateCode, questions, quizName]);

  const handleAnswer = (questionNumber, opt) => {
    if (questions[questionNumber].correctAnswer === opt) {
      const questionScore = scorePerQuestion.current.getScorePoints();
      setInfo((prev) => ({
        ...prev,
        score: prev.score + questionScore,
        correct: prev.correct + 1,
      }));
    }
    nextQuestion();
  };

  const handleShowRules = () => {
    setView('intro');
  };

  const toggleLoading = () => {
    setSubmitScoreLoading((prevState) => !prevState);
  };

  const handleScoreSubmit = async (data = info) => {
    toggleLoading();

    let updatedData = data;

    if (!data.name) {
      updatedData = { ...data, name: 'PTL' };
    }
    setInfo(updatedData);

    const responseRank = await getQuizScore(quizName, data);

    let top10 = [data];
    let riderPosition = 0;

    if (!responseRank.error && responseRank.length > 0) {
      responseRank.push(data);
      const sortedRank = responseRank.sort((a, b) => (b.score - a.score));
      top10 = sortedRank.slice(0, 10);

      riderPosition = sortedRank
        .findIndex(({ score, name }) => (data.score === score && data.name === name));
    }
    setSortedTop10(top10);
    setPosition(riderPosition);

    if (isSocket) socket.emit('leaderboard', { top10, position: riderPosition });
    resetTimer();
    toggleLoading();
    setView('leaderboard');
  };

  useEffect(() => {
    let isMounted = true;

    const fetchQuetions = async () => {
      const response = await getQuizQuestionsOpenTrivia(quizCategory, numOfQuestions);

      if (response.error || response.response_code !== 0) {
        console.log('Error Fetching open trivia quiz questions', response.error);
        return onEnded();
      }

      const questionsHolder = [];
      const { results } = response;

      for (let i = 0; i < results.length; i += 1) {
        const element = results[i];
        // randomize where to insert correct answer into incorrect answers array
        const correct = Math.floor(Math.random() * 4);

        element.incorrect_answers.splice(correct, 0, element.correct_answer);

        questionsHolder.push({
          question: element.question,
          options: element.incorrect_answers,
          correctAnswer: correct,
        });
      }

      if (isMounted) setQuestions(questionsHolder);
      return null;
    };

    if (!(questions.length > 0)) fetchQuetions();

    return () => { isMounted = false; };
  }, [questions, numOfQuestions, onEnded, quizCategory]);

  useEffect(() => {
    socket.on(quizName, async ({ code }) => {
      console.log('Code sent: ', code, privateCode);
      if (privateCode !== code) {
        return socket.emit('gameStartStatus', 'code');
      }
      return socket.emit('gameStartStatus', 'active');
    });

    socket.on('connected', (code) => {
      if (privateCode === code) {
        socket.emit('tabletConnected');
        setSocket(true);
        handleShowRules();

        socket.on('startQuiz', () => {
          socket.emit('quizStarted', numOfQuestions);
          handleShowQuestions();
        });
      } else {
        socket.emit('wrongCode');
      }
    });

    socket.on('playAgain', () => {
      resetQuiz();
      socket.emit('gameRestarted');
    });

    return () => {
      socket.off(quizName);
      socket.off('connected');
      socket.off('startQuiz');
      socket.off('playAgain');
      if (isSocket) socket.emit('endGame');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (view === 'score') {
      socket.once('persistScore', async (receivedInfo) => {
        handleScoreSubmit(receivedInfo);
      });

      socket.emit('scoreInfo', info);
      resetTimer();

      return () => socket.off('persistScore');
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const handleStart = async () => {
    handleShowRules();

    const data = {
      privateCode,
      name: quizName,
      remote: !!isSocket,
    };
    trackMixpanel('QuizStart', data, true);
  };

  switch (view) {
    case 'intro':
      return <ExplainerScreen handleClick={handleShowQuestions} isSocket={isSocket} />;
    case 'quiz':
      return (questions.length > 0)
        ? (
          <QuetionsContainer src={background}>
            <div>
              <HeaderComponent
                score={info.score}
                questions={questions}
                qIndex={number}
              >
                <CountdownTimer
                  ref={scorePerQuestion}
                  duration={questionDuration}
                  onComplete={nextQuestion}
                  timerId={questions[number] && questions[number].question}
                />
              </HeaderComponent>
              <QuestionComponent text={questions[number].question} />
            </div>
            <Answers
              currentQuestion={questions[number]}
              number={number}
              OptComponent={OptComponent}
              handleAnswer={handleAnswer}
              questionNumber={number}
              timerRef={scorePerQuestion}
            />
          </QuetionsContainer>
        )
        : <Loading />;
    case 'score':
      return (
        <ScoreBoard
          info={info}
          setInfo={setInfo}
          onSubmit={handleScoreSubmit}
          submitScoreLoading={submitScoreLoading}
          view={ScorePage}
        />
      );
    case 'leaderboard':
      return (
        <LeaderboardPage
          sortedTop10={sortedTop10}
          position={position}
          score={info.score}
          handlePlayAgain={resetQuiz}
        />
      );
    default:
      return <StartScreen onClick={handleStart} qrCode={qrCode} />;
  }
};

Quiz.propTypes = {
  quizName: PropTypes.string.isRequired,
  privateCode: PropTypes.string.isRequired,
  qrCode: PropTypes.string.isRequired,
  clearTimer: PropTypes.func,
  onEnded: PropTypes.func.isRequired,
  resetTimer: PropTypes.func.isRequired,
  quizCategory: PropTypes.string,
  numOfQuestions: PropTypes.number,
  OptComponent: PropTypes.func,
  HeaderComponent: PropTypes.func.isRequired,
  ScorePage: PropTypes.func.isRequired,
  background: PropTypes.string,
  QuestionComponent: PropTypes.func.isRequired,
  StartScreen: PropTypes.func.isRequired,
  ExplainerScreen: PropTypes.func.isRequired,
  LeaderboardPage: PropTypes.func.isRequired,
  questionDuration: PropTypes.number,
};

export default Quiz;
