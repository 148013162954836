import React from 'react';
import LazyLoadImage from '../../components/LazyLoadImage';
import {
  LinkWrapper, LinkTextWrapper, Text, Divider,
} from './style';

const LinkContainer = ({
  onClick, src, name, placeholder,
}) => {
  const loaded = LazyLoadImage(src);

  return (
    <LinkWrapper onClick={onClick} src={loaded || placeholder}>
      <LinkTextWrapper>
        <Text size="30px" color="#efefef" lineHeight={1.3}>
          {name}
        </Text>
        <Divider />
      </LinkTextWrapper>
    </LinkWrapper>
  );
};
export default LinkContainer;
