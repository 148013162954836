import React from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { sendAuthSMSToDriver, localTabletLogin, createZendeskTicket } from '../../api';
import { serverUrl } from '../../config';

import ConnectionStatus from '../../components/ConnectionStatus';
import { phone, email } from '../../utils/validationSchema';

const Background = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: white;
`;
const Panel = styled.div`
    position: relative;
    max-width: 1130px;
    width: 100%;
    height: 100%;
    max-height: 720px;
    margin: 0 auto;
    border-radius: 10px;
    background: ${({ theme }) => theme.welcomeBgColor};
    font-family: Montserrat;
`;
const PanelTop = styled.div`
    width: 100%;
    height: 13px;
    border-radius: 10px 10px 0 0;
    background: #1fb6ff;
`;
const Header = styled.h1`
    font-size: 72px;
    font-weight: 800;
    color: #1fb6ff;
    padding-top: 30px;
    margin: 0;
`;
const SubHeader = styled.h3`
    font-size: 34px;
    font-weight: 300;
    padding-top: 5px;
    color: #1fb6ff;
    margin: 0;
`;
const Logo = styled.h2`
    font-size: 54px;
    font-weight: 700;
    color: #1fb6ff;
    padding-top: 30px;
    margin: 0;
`;
const Text = styled.p`
    padding-top: 30px;
    font-size: 28px;
    color: ${(props) => props.theme.primary};
    margin: 0;
`;
const QRContainer = styled.div`
    background: white;
    width: 360px;
    height: 360px;
    border-radius: 5px;
    padding: 25px;
    margin-top: 215px;
    margin-right: 30px;
`;
const Content = styled.div`
    display: flex;
    padding-left: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
`;
const TextContainer = styled.div`
    width: 620px;
`;
const Button = styled.button`
    display: block;
    width: 360px;
    height: 75px;
    line-height: 75px;
    border-radius: 10px;
    box-shadow: none;
    color: white;
    background: ${(props) => (props.disabled ? '#d6d6d6' : '#1fb6ff')};
    font-size: 25px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 50px;
    outline: none;
    disabled: ${(props) => props.disabled};
    ${(props) => props.bottom && `
        position: absolute;
        bottom: 48px;
        right: 30px;
        margin: 0;
        &:focus{
            outline: none;
        }
    `}
`;
const Input = styled.input`
        display: block;
        width: 100%;
        height: 75px;
        border-radius: 10px;
        text-align: center;
        font-size: 28px;
        border: 1px solid ${(props) => ((props.error && props.touched) ? '#d80f4d' : '#d6d6d6')};
        background-color: ${(props) => ((props.error && props.touched) ? '#fae5ec' : '#ebebeb')};
        outline: none;
    `;
const InputError = styled.div`
    color: #d80f4d;
    font-size: 12px;
    text-align: left;
    padding-top: 3px;
    margin-bottom: 10px;
    line-height: 1.25;
`;
const Form = styled.form`
    padding-top: 215px;
    width: 360px;
    margin-right: 30px;
`;
const DebugText = styled(Text)`
    position: absolute;
    bottom: 30px;
    left: 40px;
    color: #1fb6ff;
`;
const validationSchema = Yup.object().shape({
  phone,
});
const validationSchemaLocal = Yup.object().shape({
  email,
});

const DriverLogin = ({ id }) => {
  const [QRMode, setQRMode] = React.useState(true);
  const [phoneView, setPhoneView] = React.useState(undefined);
  const [showDebug, setShowDebug] = React.useState(process.env.REACT_APP_ENV !== 'production');
  const version = window.DeviceApi
    && window.DeviceApi.getAppVersion && window.DeviceApi.getAppVersion();

  const handleLocalLogin = async ({ email: loginEmail }, { setSubmitting }) => {
    setSubmitting(true);
    console.log('login event - local');
    const { error } = await localTabletLogin(loginEmail);
    setSubmitting(false);
    if (!error) window.location.reload();
  };

  const handlePhoneSubmit = async ({ phone: phoneNum }, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const code = '1';
    const phoneNumber = code + phoneNum;
    const response = await sendAuthSMSToDriver(phoneNumber, id);
    setSubmitting(false);
    resetForm();

    if (response.error) {
      console.log('Error sending sms: ', response.error);
      createZendeskTicket({ email: 'tablet@portlmedia.com' }, 'Error sending sms', { phone: phoneNum, error: response.error });
      return setPhoneView('error');
    }
    return setPhoneView('success');
  };

  let logoCount = 0;
  const handleLogoClick = () => {
    logoCount += 1;
    if (logoCount >= 5) setShowDebug(!showDebug);
  };
  const showLocalLogin = serverUrl.includes('localhost') && (process.env.REACT_APP_ENV !== 'staging' || process.env.REACT_APP_ENV !== 'production');
  if (showLocalLogin) {
    return (
      <Background>
        <Panel>
          <PanelTop />
          <Content>
            <TextContainer>
              <Logo>portl</Logo>
              <Header>Localhost login</Header>
              <Formik
                initialValues={{ email: 'nolan.buzanis@portlmedia.com' }}
                validationSchema={validationSchemaLocal}
                onSubmit={handleLocalLogin}
              >
                {(formProps) => (
                  <form onSubmit={formProps.handleSubmit}>
                    <Input
                      onChange={formProps.handleChange('email')}
                      onBlur={formProps.handleBlur('email')}
                      name="email"
                      id="email"
                      value={formProps.values.email}
                      error={formProps.errors.email}
                      touched={formProps.touched.email}
                    />
                    {formProps.errors.email
                      && formProps.touched.email
                      && <InputError>{formProps.errors.email}</InputError>}
                    <Button type="submit" disabled={formProps.isSubmitting}>{formProps.isSubmitting ? 'Submitting..' : 'Submit'}</Button>
                  </form>
                )}
              </Formik>
            </TextContainer>
          </Content>
          <DebugText>
            v:
            {process.env.REACT_APP_GIT_SHA}
            {' '}
            [
            {process.env.REACT_APP_ENV || 'localhost'}
            ]
            {` wb: ${version}`}
            <ConnectionStatus />
          </DebugText>
        </Panel>
      </Background>
    );
  }

  const renderPhoneText = () => {
    switch (phoneView) {
      case 'success': {
        return (
          <Text>
            We sent you a text message with a link to log in to your Portl account.
            Please check your phone!
          </Text>
        );
      }
      case 'error': {
        return (
          <>
            <Text>
              We&apos;re currently having an issue texting you the link.
              Our team is looking into the problem.
            </Text>
            <Text>Please try scanning the QR code instead.</Text>
          </>
        );
      }
      default: {
        return (
          <>
            <Text>
              Input your phone number and we&apos;ll text you a link
              that you can use to sign into your Portl account.
            </Text>
            <Text>After you sign in you will be guided through the setup process.</Text>
          </>
        );
      }
    }
  };

  return (
    <Background>
      <Panel>
        <PanelTop />
        <Content>
          <TextContainer>
            <Logo onClick={handleLogoClick}>portl</Logo>
            <Header>{!QRMode && phoneView === 'error' ? 'Oops.' : 'Hi there!'}</Header>
            <SubHeader>{!QRMode && phoneView === 'error' ? 'Something went wrong.' : "We're happy to have you on board."}</SubHeader>
            {QRMode
              ? (
                <>
                  <Text>To get started use your phone camera to scan the QR code.</Text>
                  <Text>
                    After scanning the code you will be prompted to sign into your
                    Portl account where you will be guided through the setup process.
                  </Text>
                </>
              )
              : renderPhoneText()}
          </TextContainer>
          {QRMode
            ? (
              <QRContainer>
                <QRCode
                  value={`${serverUrl}/login/${id}`}
                  size={310}
                  level="Q"
                  renderAs="svg"
                  imageSettings={{
                    src: '/logo.webp',
                    height: 35,
                    width: 82.55,
                    excavate: true,
                  }}
                />
              </QRContainer>
            )
            : (
              <Formik
                initialValues={{ phone: '' }}
                validationSchema={validationSchema}
                onSubmit={handlePhoneSubmit}
              >
                {(formProps) => (
                  <Form onSubmit={formProps.handleSubmit}>
                    <Input
                      onChange={formProps.handleChange('phone')}
                      onBlur={formProps.handleBlur('phone')}
                      name="phone"
                      id="phone"
                      value={formProps.values.phone}
                      error={formProps.errors.phone}
                      touched={formProps.touched.phone}
                      placeholder="888-888-8888"
                    />
                    {formProps.errors.phone
                      && formProps.touched.phone
                      && <InputError>{formProps.errors.phone}</InputError>}
                    <Button type="submit" disabled={formProps.isSubmitting}>{formProps.isSubmitting ? 'Submitting..' : 'Submit'}</Button>
                  </Form>
                )}
              </Formik>
              // Add git commit hash, add somethign for staging// click logo 5 times, make visible
            )}
        </Content>
        <Button onClick={() => setQRMode(!QRMode)} bottom>{QRMode ? 'Not working? Click here.' : 'Scan QR code instead'}</Button>
        {showDebug
          && (
            <DebugText>
              v:
              {process.env.REACT_APP_GIT_SHA}
              {' '}
              [
              {process.env.REACT_APP_ENV || 'localhost'}
              ]
              {` wb: ${version}`}
              <ConnectionStatus />
            </DebugText>
          )}
      </Panel>
    </Background>
  );
};

export default DriverLogin;
