import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: inline-flex;
    align-items: center;
    margin: 9px 13.75px;
    width: 585px;
    height: 100px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
    text-align: left;
    ${({ fontSize }) => fontSize && `font-size: ${fontSize}px`};

    background-color: ${({ showAnswer, checked, isCorrect }) => (showAnswer ? (
    (isCorrect && '#00d261')
        || (checked && '#ff3636')
  ) : '#fafafa')}
`;

const OptContainer = styled.div`
    flex-basis: 100px;
    height: 100px;
    margin-right: 16px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    font-size: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ optBck }) => optBck && `background-color: ${optBck}`};
    ${({ optColor }) => optColor && `color: ${optColor}`};
`;

const toLetter = ['A', 'B', 'C', 'D'];

const QuizOption = ({
  text, isCorrect, fontSize, i, optColor, optBck, showAnswer, selected, onClickSelection,
}) => (
  <Container
    fontSize={fontSize}
    showAnswer={showAnswer}
    checked={selected === i}
    isCorrect={isCorrect}
    onClick={() => onClickSelection(i)}
  >
    <OptContainer optColor={optColor} optBck={optBck} key={text}>
      {toLetter[i]}
    </OptContainer>
    {text}
  </Container>
);

export default React.memo(QuizOption);
