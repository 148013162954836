const maximizeDistribution = (arr = []) => {
  // if input is not an warray, return an empty value
  if (!Array.isArray(arr)) {
    return [];
  }

  // 1. Order items from lowest frequency to highest

  const compareFrequencies = (a, b) => {
    if (a.Frequency < b.Frequency) {
      return -1;
    }
    if (a.Frequency > b.Frequency) {
      return 1;
    }
    return 0;
  };

  const sorted = [...arr].sort(compareFrequencies);

  // 2. distribute equally
  let array = [];

  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < sorted.length; j++) {
    // validate data
    // eslint-disable-next-line no-continue
    if (!sorted[j].Frequency || typeof sorted[j].Frequency !== 'number') continue;

    const size = array.length;
    const frequency = sorted[j].Frequency;
    const newSize = size + frequency;
    let oneCount = 1;
    let zeroCount = 1;
    let oldArrayElementIndex = 0;
    const newArray = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < newSize; i++) {
      if (oneCount * size < zeroCount * frequency) {
        oneCount += 1;
        newArray[i] = sorted[j];
      } else {
        zeroCount += 1;
        newArray[i] = array[oldArrayElementIndex];
        oldArrayElementIndex += 1;
      }
    }

    array = newArray;
  }

  return array;
};

// const distributed = []; //5

// const testFrequencies = [1, 2, 3, 4, 5, 6, 7, 8, 9];
// const arrayLengths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
// const outputs = new Array(testFrequencies.length);

// for (let i = 0; i < testFrequencies.length; i++) {
//     outputs[i] = new Array(arrayLengths.length);

//     for (let j = 0; j < arrayLengths.length; j++) {
//         const frequency = testFrequencies[i];
//         const oldArraySize = arrayLengths[j]; //3
//         const multiplier = Math.ceil((oldArraySize + frequency) / frequency);

//         const indexArray = Array.from({ length: frequency }, (_, i) => i * multiplier); // []
//         const failed =
//          indexArray[indexArray.length - 1] >= (oldArraySize + frequency - 1); //[0 , 4, 8]

//         //Failure condition?
//         const willFail = (frequency - 1) * multiplier >= (oldArraySize + frequency - 1);

//         outputs[i][j] = {
//             frequency,
//             oldArraySize,
//             multiplier,
//             indexArray,
//             failed,
//             willFail
//         };
//         if (willFail !== failed) console.log('Condition did not hold!');

//     }
// }

// return outputs;

// const item = sorted[2];
// const frequency = item.Frequency; //3
// const multiplier = Math.ceil((arrat + frequency) / frequency); //

// const indexArray = Array.from({ length: frequency }, (_, i) => i * multiplier); // []
// console.log(indexArray);
// distributed.splice()

export default maximizeDistribution;
