/* eslint-disable no-underscore-dangle */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import * as dayjs from 'dayjs';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  getMedia,
  createZendeskTicket,
  getStrapiPresets,
  getStrapiAds,
} from '../../api';
import { getAnalyticsInfo } from '../../analytics';
import maximizeDistribution from '../../algorithms';
import { generateCode, isWithinCircle } from '../../utils/helper';
import useAuth from '../../context/auth';
import useContent from '../../context/content';
import { getLastLocation, isLocationDefault } from '../../store/location';
import {
  ANIMATION_TIME, AdContainer, LoadingContainer, BrandedImage,
} from './style';

import { requestHivestack } from './RequestHivestack';
import { requestAdomni } from './RequestAdomni';
import { requestMagnite } from './RequestMagnite';
import { requestVistar } from './RequestVistar';
import AdPlayer from './AdPlayer';
import { ProgressBarMemo } from './ProgressBar';
import { requestBroadsign } from './RequestBroadsign';

const mapCreativeTypeToMimeType = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  webp: 'image/webp',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  mpg: 'video/mpg',
  quicktime: 'video/quicktime',
  webm: 'video/webm',
};

const getRandomCreative = (assetsOptionsUrl) => {
  const mediaUrlArray = assetsOptionsUrl.split(',');
  const randomCreative = Math.floor(Math.random() * mediaUrlArray.length);

  return mediaUrlArray[randomCreative];
};

export const requestDirectAd = async (ad) => {
  const isVideo = mapCreativeTypeToMimeType[ad.CreativeType]
    && mapCreativeTypeToMimeType[ad.CreativeType].includes('video');

  const parsedAd = {
    mediaFile: {
      _type: mapCreativeTypeToMimeType[ad.CreativeType],
      blobURL: undefined,
    },
    duration: isVideo ? undefined : ad.PlayLength, // If ad is a video, set as undefined
    pop: undefined,
    adPartner: 'portl',
  };

  const creativeUrl = ad.ItemType === 'directAdOptions'
    ? getRandomCreative(ad.AssetsOptionsUrl)
    : ad.AssetUrl;

  const blobURL = await getMedia(creativeUrl, 'portl');

  if (blobURL.error) {
    return blobURL;
  }
  parsedAd.mediaFile.blobURL = blobURL;

  return parsedAd;
};

export const importComponent = async ({ Component, PlayLength }) => {
  try {
    // eslint-disable-next-line prefer-template
    const Imported = await import('../WelcomeComponents/' + Component + '.jsx');

    return { component: Imported.default, duration: PlayLength };
  } catch (error) {
    return { error };
  }
};

const mapItemTypeToFunction = {
  directAd: (ad) => requestDirectAd(ad),
  hivestack: (_, incrementProgrammatic) => requestHivestack(incrementProgrammatic),
  broadsign: (_, incrementProgrammatic) => requestBroadsign(incrementProgrammatic),
  vistar: (_, incrementProgrammatic) => requestVistar(incrementProgrammatic),
  adomni: (_, incrementProgrammatic) => requestAdomni(incrementProgrammatic),
  magnite: (_, incrementProgrammatic) => requestMagnite(incrementProgrammatic),
  placeholder: (ad) => requestDirectAd(ad),
  directAdOptions: (ad) => requestDirectAd(ad),
  component: importComponent,
};

export const LoadingSpinner = () => (
  <LoadingContainer data-testid="loader">
    <ClipLoader size={50} color="#1FB6FF" loading margin={2} />
  </LoadingContainer>
);

const INITIAL_STATE = {
  mediaFile: {},
  pop: undefined,
  duration: undefined,
  adPartner: undefined,
  adName: undefined,
  loading: true,
  error: undefined,
};
export const faceDetectedTimeout = 15 * 60 * 1000; // 15 mins in milliseconds

const AdLoop = ({ theme, incrementProgrammatic, saveImpression }) => {
  const history = useHistory();

  const { driver } = useAuth();
  const { adLoop, setAdLoop } = useContent();
  const { email } = driver;

  const [state, setState] = useState(INITIAL_STATE);
  const faceDetectedLast = useRef();
  const [impressionTag, setImpressionTag] = useState(false);

  const adLoopArray = useRef();
  const isCancelled = useRef(false);
  const timeSinceLastAdFetch = useRef();
  const adWaiting = useRef();

  // Update face detected variable every 10s
  useEffect(() => {
    let interval;

    const updateFace = () => {
      if (window.DeviceApi.faceDetected()) {
        faceDetectedLast.current = new Date().getTime();
      }
    };
    if (window.DeviceApi && window.DeviceApi.faceDetected) {
      // if face detection is true then set face detected last variable
      console.log('Has face detection ability. ');
      updateFace(); // run once at very beginning
      interval = setInterval(updateFace, 10000);
    } else {
      console.log(
        'Does not have face detection ability. Unable to run ads that require face detection.',
      );
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    // check if ad waiting and if so store in adWaiting useRef
    const loadLocalAdToQueue = () => {
      try {
        const stringifiedAd = localStorage.getItem('adWaiting');
        if (!stringifiedAd) return;
        console.log('Found preloaded ad in localstorage, adding to queue.');
        const parsed = JSON.parse(stringifiedAd);
        adWaiting.current = parsed;
        localStorage.removeItem('adWaiting');
      } catch (error) {
        console.log(error);
      }
    };
    loadLocalAdToQueue();

    // eslint-disable-next-line func-names
    return function () {
      // also store ad in queqe to local storage so we can fetch it when component mounts again
      if (adWaiting.current && !adWaiting.current.component) {
        localStorage.setItem('adWaiting', JSON.stringify(adWaiting.current));
        console.log('Storing preloaded ad in local for later: ', adWaiting.current);
      }
    };
  }, []);

  const shouldRunFaceAd = () => {
    const faceDetected = faceDetectedLast.current;
    return faceDetected && Date.now() - faceDetected < faceDetectedTimeout;
  };

  const loadAd = useCallback(async () => {
    // If at end of loop, set index to 0 again
    const { index, data } = adLoopArray.current;
    if (index >= data.length) adLoopArray.current.index = 0;

    const nextAd = data[adLoopArray.current.index];
    if (nextAd.PlayOnlyIfFaceDetected && !shouldRunFaceAd()) {
      adLoopArray.current.index += 1;
      return loadAd();
    }

    const requestAdFunction = mapItemTypeToFunction[nextAd.ItemType];
    if (typeof requestAdFunction !== 'function') {
      const msg = 'requestAdFunction is not a function! Removing from ad loop: ';
      adLoopArray.current.data = data.filter((item) => item !== nextAd);
      return { error: msg };
    }
    const adData = await requestAdFunction({ ...nextAd }, incrementProgrammatic);

    if (adData.empty) {
      // No data from data source, increase index by 1 and call next ad
      adLoopArray.current.index += 1;
      return loadAd();
    }
    if (adData.error) {
      // remove item from ad loop
      console.log(`Error - Removing from ad loop: ${nextAd.Name}`);
      adLoopArray.current.data = data.filter((item) => item !== nextAd);
      return adData;
    }

    const {
      mediaFile, duration, pop, adPartner, component,
    } = adData;
    if (!component && (!mediaFile || !mediaFile._type || !mediaFile.blobURL)) {
      // schema?
      return { error: "Doesn't match schema" };
    }

    const newAdState = {
      id: generateCode(4),
      component,
      mediaFile,
      duration,
      pop,
      adPartner,
      onClickUrl: nextAd.OnClickUrl || '/home',
      adName: nextAd.Name,
      clickAnalytics: nextAd.ClickAnalytics,
      mixpanelClickEventName: nextAd.MixpanelClickEventName,
      trackImpression: nextAd.ItemType === 'directAd' || nextAd.trackImpression,
      adId: nextAd.id,
    };

    return { ad: newAdState };
  }, [incrementProgrammatic]);

  const preloadAd = useCallback(async () => {
    const { ad, error } = await loadAd();
    if (error) return; // should we try a certain number of times?
    adWaiting.current = ad;
  }, [loadAd]);

  const playAd = async () => {
    try {
      const { data } = adLoopArray.current;
      if (!data || data.length === 0) {
        console.log('Error: No ads in ad loop!', data);
        return setState({ error: 'No ads in ad loop!' });
      }
      // Refresh data every 60 minutes
      // const nowInMS = new Date().getTime();
      // const timeSinceMS = Math.abs(nowInMS - timeSinceLastAdFetch.current.getTime());
      // console.log('Time since last ad fetch: ' + timeSinceMS / (1000 * 60) + ' minutes');
      // if (timeSinceMS >= 1000 * 3600) return fetchLoopItems();

      let newAd;
      // Check for preloaded ad first
      if (adWaiting.current) {
        newAd = { ...adWaiting.current };
        adWaiting.current = null;
      } else {
        const { ad, error } = await loadAd();

        // eslint-disable-next-line no-use-before-define
        if (error) return onAdError(error);
        newAd = ad;
      }
      if (isCancelled.current) return null;
      if (newAd.adName === 'Google Ad') {
        setImpressionTag(true);
      }
      console.log('Running Ad: ', newAd);
      setState(newAd);

      adWaiting.current || preloadAd(); // attempt to preload next, don't "await" on purpose
    } catch (error) {
      setState({ error });
      console.log('Error in ad loop: ', error);
      return createZendeskTicket(email, 'Ad loop error:', error);
    }
    return null;
  };

  const fetchLoopItems = async () => {
    // if strapi items are in context, use them instead
    if (adLoop && adLoop.length > 0) {
      // Store in component ref
      adLoopArray.current = {
        data: adLoop,
        index: 0,
      };
      return playAd();
    }

    // Get welcomeitem data from strapi
    const strapiResponse = await getStrapiAds();
    timeSinceLastAdFetch.current = new Date();

    console.log('Ads fetched: ', strapiResponse);
    if (strapiResponse.error) {
      console.log('Error fetching welcomeitems from strapi', strapiResponse.error);
      const driverInfo = await getAnalyticsInfo();
      createZendeskTicket(
        { email: driverInfo.driver },
        `Error fetching WelcomeItems from Strapi for id: ${driverInfo.imei} `,
        strapiResponse.error,
      );
      return setState({ error: strapiResponse.error });
    }
    const presetLocations = await getStrapiPresets();
    if (presetLocations.error) {
      console.log('Error fetching presets from strapi', presetLocations.error);
      const driverInfo = await getAnalyticsInfo();
      createZendeskTicket(
        { email: driverInfo.driver },
        `Error fetching PresetLocations from Strapi for id: ${driverInfo.imei} `,
        presetLocations.error,
      );
      return setState({ error: strapiResponse.error });
    }

    // Only play active=true ads
    // If type directAd, filter non-approved
    const filteredArray = strapiResponse.filter((item) => {
      const today = dayjs().startOf('d'); // set to 12:00am
      if (item.StartDate) {
        const diffStart = dayjs(item.StartDate).diff(today, 'd');

        if (diffStart > 0) return false; // start date in the future
      }

      if (item.EndDate) {
        const diffEnd = dayjs(item.EndDate).diff(today, 'd');

        if (diffEnd < 0) return false; // end date has passed
      }

      // check approved property
      if (item.ItemType === 'directAd' && !item.Approved) {
        return false;
      }

      return item.Active;
    });

    // Check location of items (async so needs to be outside the filter)
    const promises = filteredArray.map(({ Location: location }) => {
      if (location && location.length > 0) {
        // for now just check first location
        return new Promise((res) => {
          try {
            const {
              lat, lng, radius, preset,
            } = location[0];
            if (preset) {
              // check preset data
              for (let i = 0; i < presetLocations.length; i += 1) {
                const { id, Location } = presetLocations[i];
                if (id === preset[0].id && Location[0]) {
                  // check only first
                  // eslint-disable-next-line no-shadow
                  const { lat, lng, radius } = Location[0];
                  return res(isWithinCircle(lat, lng, radius));
                }
              }
            } else {
              return res(isWithinCircle(lat, lng, radius));
            }
          } catch (error) {
            console.log(error);
            // something happened, send zendesk ticket
            createZendeskTicket(email, 'Error parsing ad loop location:', error);
            return res(false);
          }
          return null;
        });
      }
      return true;
    });
    const locationChecks = await Promise.all(promises);

    const locationFilteredArray = filteredArray.filter(
      (_, index) => locationChecks[index],
    );
    console.log('Ad Array after filtering: ', locationFilteredArray);

    // Run it through distribution algorithm
    const sortedAdArray = maximizeDistribution(locationFilteredArray);
    console.log('Ad Array after algorithm: ', sortedAdArray);

    // Store in component ref
    adLoopArray.current = {
      data: sortedAdArray,
      index: 0,
    };
    setAdLoop(sortedAdArray);

    isCancelled.current || playAd();
    return null;
  };

  useEffect(() => {
    let timeout;
    if (state && state.error) {
      timeout = setTimeout(() => {
        console.log('Error found. Resetting ad loop...');
        setState(INITIAL_STATE);
        fetchLoopItems();
      }, 30000); // retry every 30s
    }
    return () => timeout && clearTimeout(timeout);
    // eslint-disable-next-line
  }, [state.error]);

  useEffect(() => {
    fetchLoopItems();
    return () => {
      isCancelled.current = true;
    };
    // eslint-disable-next-line
  }, [email]);

  const updateMixpanel = async (eventName, duration) => {
    const adInfo = {
      // must come before first await
      mediaFile: state.mediaFile,
      pop: state.pop,
      duration: duration || state.duration,
      adPartner: state.adPartner,
      adName: state.adName,
    };
    const userInfo = await getAnalyticsInfo();
    const adReport = { ...adInfo, ...userInfo };
    if (window.DeviceApi && window.DeviceApi.faceDetected) {
      adReport.faceDetected = window.DeviceApi.faceDetected();
      console.log('Face detected: ', adReport.faceDetected);
    }

    mixpanel.track(eventName, adReport);
  };

  const handleAdClick = () => {
    // if (state.mediaFile && !state.enableClick) return;

    if (state.clickAnalytics) updateMixpanel('Engagement'); // use mixpanelClickEventName?
    if (state.onClickUrl === '/home') return;
    history.push(state.onClickUrl);
  };

  const onAdEnded = async () => {
    // console.log('onAdEnded');
    if (isCancelled.current) return;

    if (state.adPartner === 'hivestack') incrementProgrammatic('hivestackAdPlayed');
    if (state.adPartner === 'broadsign') incrementProgrammatic('broadsignAdPlayed');
    if (state.adPartner === 'vistar') incrementProgrammatic('vistarAdPlayed');
    if (state.adPartner === 'adomni') incrementProgrammatic('adomniAdPlayed');
    if (state.adPartner === 'magnite') incrementProgrammatic('magniteAdPlayed');

    // State changes
    adLoopArray.current.index += 1;
    playAd().then(() => {
      if (state.mediaFile) URL.revokeObjectURL(state.mediaFile.blobURL);
    });

    // Other calls
    if (state.trackImpression) saveImpression(state.adId);// no await intentionally
    setImpressionTag(false);
    // no await intentionally on impression calls
    if (state.pop) {
      if (state.adPartner === 'adomni') {
        // if adomni && pop, add location info to url
        getLastLocation().then(({ lat, lng }) => {
          let popUrl = state.pop;
          if (!isLocationDefault()) popUrl += `&lat=${lat}&lng=${lng}`;
          fetch(popUrl);
        });
        return;
      }
      // if no adomni, just report impression
      fetch(state.pop);
    }
  };

  const onAdError = async (err) => {
    console.log('onAdError', err);
    if (isCancelled.current) return;

    // errorsArray.current.push(err);
    if (state.mediaFile) URL.revokeObjectURL(state.mediaFile.blobURL);
    //
    // if (errorsArray.current && errorsArray.current.length > 2) {
    //     return setState({ error: errorsArray.current });
    //     //add a retry after 5 minutes
    // }

    adLoopArray.current.index += 1;
    playAd();

    // const adPlaying = adLoopArray.current.data[adLoopArray.current.index];
    // const driverInfo = await getAnalyticsInfo();
    // createZendeskTicket(driverInfo.driver,
    // 'Tablet Ad Error', { ...err, ...driverInfo, ...adPlaying });

    // How do we limit this? On a 4-5 item loop, we would get 1 ticket/minute
    // Take ad out of loop? if it has happened 2+ times, remove from loop
  };

  if (state.loading) {
    return (
      <AdContainer>
        <LoadingSpinner />
      </AdContainer>
    );
  }

  return (
    <AdContainer onClick={handleAdClick}>
      {state.error ? (
        <BrandedImage // Asana task: Sub with error QR screen
          src={theme.welcomeInterimBg}
          alt="Error"
        />
      ) : (
        <TransitionGroup component={null}>
          <CSSTransition
            key={state.id}
            timeout={ANIMATION_TIME}
            classNames="ad-loop"
            appear
          >
            <AdPlayer
              component={state.component}
              mediaFile={state.mediaFile}
              duration={state.duration}
              onEnded={onAdEnded}
              onError={onAdError}
            />
          </CSSTransition>
        </TransitionGroup>
      )}
      {
        impressionTag
        && (
          <img
            alt=""
            // eslint-disable-next-line no-template-curly-in-string
            src="https://ad.doubleclick.net/ddm/trackimpj/N57101.4656319EMBERMEDIA/B28659227.348301677;dc_trk_aid=539490071;dc_trk_cid=179731104;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=?"
          />
        )
      }
      <ProgressBarMemo duration={state.duration} id={state.id} />
    </AdContainer>
  );
};

export default React.memo(AdLoop);
