/* eslint-disable import/prefer-default-export */
import React from 'react';

export const PinIcon = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="45" viewBox="0 0 57 72">
    <g id="map_pin" data-name="map pin" transform="translate(-2.3)">
      <path id="Path_1637" data-name="Path 1637" d="M30.816,1A27.437,27.437,0,0,0,3.3,28.356c0,13.837,23.037,38.331,26.94,42.4a.771.771,0,0,0,1.12,0c3.9-4.04,26.94-28.629,26.94-42.4A27.436,27.436,0,0,0,30.816,1Zm0,37.122A9.795,9.795,0,1,1,37.823,35.3a9.793,9.793,0,0,1-7.007,2.826Z" transform="translate(0 0)" fill={fill} stroke="#043650" strokeWidth="2" />
    </g>
  </svg>
);
