import styled from 'styled-components';
import ReactPlayer from 'react-player';

export const PageWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 950px auto;
    grid-template-rows: 535px auto;
    column-gap: 30px;
    position: relative;
`;

export const ImgWrapper = styled.div`
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
    flex-grow: 1;
    position: relative;
    z-index: 1;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
`;

export const VideoOverlay = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: grid;
    grid-template-rows: 75px auto 180px;
`;

export const OverlayTextContainer = styled.div`
    height: 100%;
    width: 100%;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const PlayControlsContainer = styled.div`
    height: 100%;
    width: 100%;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 44px;
    padding-left: 45px;
`;

export const PauseControlsContainer = styled(PlayControlsContainer)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0px 45px;
`;

export const Divider = styled.div`
    height: ${({ vertical }) => (vertical ? '32px' : '5px')};
    width: ${({ vertical }) => (vertical ? '5px' : '32px')};
    background-color: #e7a729
`;

export const DescriptionContainer = styled.div`
    height: 100%;
    width: 100%;
    grid-column-start: 1;
    grid-row-start: 2;
    padding: 0 68px 0 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const Text = styled.p`
    font-family: Lora;
    font-size: ${({ size }) => (size || '30px')};
    color: ${({ color }) => (color || '#262525')};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    padding: 0px ${({ padding }) => (padding || '0px')};
    line-height: ${({ lineHeight }) => (lineHeight || 1.5)};
`;

export const LinksContainer = styled.div`
    height: 100%;
    width: 100%;
    grid-column-start: 2;
    grid-row-start: 1;
    display: grid;
    grid-template-rows: auto auto auto;
    row-gap: 22px;
`;

export const Background = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 30%, rgba(255, 255, 255, 0) 49%), url("${({ src }) => src}");
    background-size: cover;
    position: relative;
    display: grid;
    grid-template-rows: 75px auto;
`;

export const GoBackButtonContainer = styled.div`
    width: 565px;
    height: 50px;
    background: #efefef;
    position: absolute;
    top: 615px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const ProgressContainer = styled.div`
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background-color: #262525;
    overflow: hidden;
    position: relative;
`;

export const ProgressFiller = styled.div`
    height: 100%;
    width: ${({ played }) => played * 100}%;
    text-align: right;
    background-color: #efefef;
`;

export const VideoSlider = styled.input`
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    opacity: 0;
`;

export const IconContainer = styled.img`
    height: ${({ size }) => (size || '100px')};
    width: ${({ size }) => (size || '100px')};
    margin-right: ${({ padding }) => (padding || '0px')};
`;

export const VideoCloseButton = styled(IconContainer)`
    position: absolute;
    top: 0; 
    right: 0;
    margin: 15px;
`;

export const LinkWrapper = styled.div`
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url("${({ src }) => src}");
    background-size: cover;
    position: relative;
`;

export const LinkTextWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;
    align-items: flex-start;
    justify-content: flex-end;
`;

export const ContentWrapper = styled.div`
    padding-left: 35px;
    padding-top: ${({ paddingTop }) => (paddingTop || 0)};
`;

export const TextContainer = styled.div`
    width: ${({ width }) => width};
`;

export const SocialMediaContainer = styled.div`
    height: 100%;
    width: 100%;
    grid-row-start: 2;
    grid-column-start: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
`;

export const SocialMediaDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: ${({ paddingTop }) => (paddingTop || 0)};
    padding-bottom: ${({ paddingBottom }) => (paddingBottom || 0)};
`;

export const SocialMedia = styled.div`
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const SocialMediaPageContainer = styled.div`
    height: 475px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
`;

export const QRContainer = styled.div`
    height: 140px;
    width: 140px;
    border-radius: 10px;
    padding: 8px;
    background-color: #efefef;
`;

export const VideoPageWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

export const LoaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    z-index: -1;
`;
