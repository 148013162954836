import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ color }) => color && { color }};
`;

export const Title = styled.div`
  margin: 0 auto;
  font-size: 65px;
  width: fit-content;
  padding-top: 9px;
  ${({ color }) => color && { color }};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  ${({ color }) => color && { color }};
`;

export const PlayerScore = styled.div`
  width: fit-content;
  font-size: 38px;
  font-weight: bold;
`;

export const BoardInfo = styled.div`
  margin: 22px auto 0;
  max-width: 1075px;
  font-family: 'Lato', sans-serif;
  font-size: 38px;
  text-align: center;
`;

export const InputContainer = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto;
`;

export const InputName = styled.input`
  letter-spacing: 10px;
  text-indent: 5px;
  background: transparent;
  width: 105px;
  border: none;
  font-size: 33px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;

  &:focus {
      outline: none;
  }
`;

export const Line = styled.div`
  width: 27px;
  height: 3px;
  background-color: ${({ color }) => color || '#ffffff'};
  position: absolute;
  bottom: 4px;
  left: ${({ left }) => left}px;
`;

export const ScoreOptContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 20px auto 0;
  width: 861px;
`;

export const ContentOption = styled.div`
  font-size: 26px;
  font-family: 'Lato', sans-serif;
  flex-grow: 1;
  height: 78px;
  margin: 0 12.5px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${({ checked }) => (checked ? '#88ccff' : '#fafafa')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NextButton = styled.div`
  width: 405.5px;
  height: 68px;
  margin: 28px auto 0;
  line-height: 1.85;
  border-radius: 10px;
  box-shadow: 5px 5px 11px 0 rgba(0, 0, 0, 0.16);
  background-color: ${({ color, stdColor }) => color || stdColor};
  font-size: 37px;
  font-weight: bold;
  color: #fafafa;
  text-align: center;
`;
