import React from 'react';
import QRCode from '../../components/QRCodeWithData';
import { riderUrl } from '../../config';
import {
  WinContainer, CongratsText, QRTextWrapper, QRCodeContainer, ThreeBasket, Space,
} from './style';

// eslint-disable-next-line import/prefer-default-export
export const WinComponent = ({
  appName, code, IMEI, isSocket,
}) => (
  <WinContainer>
    <CongratsText>CONGRATULATIONS</CongratsText>
    <ThreeBasket />
    <QRTextWrapper>
      You saved the Easter Bunny&apos;s reputation!
      {(!isSocket)
        ? (
          <>
            <QRCodeContainer>
              <QRCode
                name={`${appName}Win`}
                size={260}
                redirectUrl={`${riderUrl}/${appName}Win/${IMEI}/${code.current}`}
              />
            </QRCodeContainer>
            <p>Scan the QR Code with your phone to claim your prize</p>
          </>
        )
        : (
          <>
            <Space />
            <p>Please follow the instructions on your phone to claim your price!</p>
          </>
        )}
    </QRTextWrapper>
  </WinContainer>
);
