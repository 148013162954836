import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  THEMECOLOR, QuizBackgroundWrapper, LeaderboardMessage,
  Message, PlayAgainButton,
} from './style';
import QuizLeaderboardTemplate from '../../compositions/QuizLeaderboardTemplate';
import { socket } from '../../socket';

const QuizLeaderboard = ({
  info, rank, isSocket, onEnded, playAgain,
}) => {
  useEffect(() => {
    const eventName = isSocket ? 'ChristmasQuizQREnd' : 'ChristmasQuizTabletEnd';
    mixpanel.track(eventName);

    const id = setTimeout(() => onEnded(), 30000);

    return () => {
      if (isSocket) {
        socket.emit('endGame');
      }

      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QuizBackgroundWrapper src="/assets/christmasBackground.webp" font="'Mountains of Christmas', cursive">
      <LeaderboardMessage>
        Thanks for playing and Happy Holidays!
        <Message>
          A donation will be made to Sick Kids on your behalf!
        </Message>
        <PlayAgainButton onClick={playAgain}>
          Play Again!
        </PlayAgainButton>
      </LeaderboardMessage>
      <QuizLeaderboardTemplate
        isSocket={isSocket}
        info={info}
        rank={rank}
        themeColor={THEMECOLOR}
      />
    </QuizBackgroundWrapper>
  );
};

export default QuizLeaderboard;
