import React from 'react';
import Modal from 'react-modal';

const BroadcastMessage = ({ isOpen, closeBroadcast, message }) => (
  <Modal
    header="Broadcast Message"
    isOpen={isOpen}
    closeBroadcast={closeBroadcast}
    className="broadcastModal"
    shouldFocusAfterRender
  >
    <button type="button" onClick={closeBroadcast} className="broadcastCloseButton">
      &times;
    </button>
    <h1 className="broadcastTitle">Incoming Message</h1>
    {message && (<p className="broadcastMessage">{message}</p>)}
  </Modal>
);

export default BroadcastMessage;
