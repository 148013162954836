import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import FeedbackNotification from '../../components/FeedbackNotification';
import { CloseIcon } from '../../components/SettingsModal';
import { trackMixpanel } from '../../analytics';

// Apps
import Quizzle from '../../apps/Quizzle';
// import HalloweenContest from '../WelcomeComponents/HalloweenContest';
// import HalloweenVoting from '../WelcomeComponents/HalloweenVoting';
// import BigPicture from '../WelcomeComponents/BigPicture';
import UberGiveaway from '../../apps/UberGiveaway';
import PortlTips from '../../apps/PortlTips';
import NewsArticle from '../../apps/NewsArticle';
import NothingQuiz from '../../apps/NothingQuiz';
import ChristmasQuiz from '../../apps/ChristmasQuiz';
import Spotlight from '../../apps/Spotlight';
import MovieQuiz from '../../apps/MovieQuiz';
import GameZone from '../../apps/GameZone';
import LoveToWin from '../../apps/LoveToWin';
import PortlShorts from '../../apps/PortlShorts';
import FourLeafFortune from '../../apps/FourLeafFortune';
import SpotlightSelection from '../../apps/SpotlightSelection';
import EasterEggGame from '../../apps/EasterEggGame';
import ThunderDungeon from '../../apps/ThunderDungeon';
import VideoElephant from '../../apps/VideoElephant';
import CSI from '../../apps/CSI';
import FireCountry from '../../apps/FireCountry';
import SoHelpMeTodd from '../../apps/SoHelpMeTodd';
import TourGuide from '../../apps/TourGuide';

const Background = styled.div`
    width: 100%;
    height: 100vh;
    background: ${(props) => props.theme.welcomeBgColor};
`;

const Logo = styled.h1`
    font-size: 50px;
    font-weight: bold;
    color: ${(props) => props.theme.secondary};
`;
const CloseButton = styled.button`
    margin-left: 25px;
    width: 208px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    font-size: 32px;
    color: #2f2f2f;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;
const SmallButton = styled(CloseButton)`
    width: 100px;
`;

const SettingsIcon = () => (
  <svg id="settings-24px" xmlns="http://www.w3.org/2000/svg" width="40.383" height="40.383" viewBox="0 0 40.383 40.383">
    <path id="Path_1411" data-name="Path 1411" d="M0,0H40.383V40.383H0Z" fill="none" />
    <path id="Path_1412" data-name="Path 1412" d="M30.388,20.135a11.9,11.9,0,0,0,.1-1.582,9.72,9.72,0,0,0-.118-1.582l3.416-2.659a.825.825,0,0,0,.2-1.026L30.758,7.7a.822.822,0,0,0-.993-.37L25.744,8.945a11.887,11.887,0,0,0-2.726-1.582L22.413,3.09A.814.814,0,0,0,21.6,2.4H15.144a.8.8,0,0,0-.791.69l-.606,4.274a12.149,12.149,0,0,0-2.726,1.582L7,7.33a.8.8,0,0,0-.993.37L2.793,13.287A.765.765,0,0,0,3,14.313l3.416,2.659a9.361,9.361,0,0,0-.034,3.163L2.962,22.793a.825.825,0,0,0-.2,1.026L5.99,29.406a.822.822,0,0,0,.993.37L11,28.161a11.886,11.886,0,0,0,2.726,1.582l.606,4.274a.827.827,0,0,0,.808.69H21.6a.785.785,0,0,0,.791-.69L23,29.743a11.541,11.541,0,0,0,2.726-1.582l4.021,1.615a.8.8,0,0,0,.993-.37l3.231-5.586a.779.779,0,0,0-.2-1.026ZM18.374,24.611a6.057,6.057,0,1,1,6.057-6.057A6.075,6.075,0,0,1,18.374,24.611Z" transform="translate(1.817 1.638)" fill="#1f2639" />
  </svg>
);

const MainScreen = styled.div`
    width: 100%;
    height: 720px;
    overflow: hidden;
    background-color: ${(props) => props.theme.appBackground};
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    height: 80px;
`;
const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
`;
const CloseText = styled.span`
    padding-right: 20px;
`;

const mapStateToApp = {
  Quizzle: {
    Component: Quizzle,
  },
  UberGiveaway: {
    Component: UberGiveaway,
    doNotCollectFeedback: true,
  },
  PortlTips: {
    Component: PortlTips,
  },
  NewsArticle: {
    Component: NewsArticle,
  },
  NothingQuiz: {
    Component: NothingQuiz,
  },
  ChristmasQuiz: {
    Component: ChristmasQuiz,
  },
  MovieQuiz: {
    Component: MovieQuiz,
  },
  Spotlight: {
    Component: Spotlight,
  },
  GameZone: {
    Component: GameZone,
    doNotCollectFeedback: true,
  },
  LoveToWin: {
    Component: LoveToWin,
  },
  PortlShorts: {
    Component: PortlShorts,
  },
  FourLeafFortune: {
    Component: FourLeafFortune,
  },
  SpotlightSelection: {
    Component: SpotlightSelection,
  },
  EasterEggGame: {
    Component: EasterEggGame,
  },
  ThunderDungeon: {
    Component: ThunderDungeon,
  },
  VideoElephant: {
    Component: VideoElephant,
  },
  CSI: {
    Component: CSI,
  },
  FireCountry: {
    Component: FireCountry,
  },
  SoHelpMeTodd: {
    Component: SoHelpMeTodd,
  },
  TourGuide: {
    Component: TourGuide,
  },
};

const feedbackToastId = 'feedback';

const AppContainer = ({ setShowSettings, riderIsConnected }) => {
  const history = useHistory();
  const { name } = useParams();
  const timeStart = useRef(new Date().getTime());
  const timer = useRef();
  const isTimerPaused = useRef(false);

  const handleOnEnd = () => {
    const timeNow = new Date().getTime();
    trackMixpanel('AppEnd', { session: timeNow - timeStart.current, name });
    history.push('/');

    const { doNotCollectFeedback } = mapStateToApp[name];
    const shouldShow = Math.random(1) < 0.35; // % chance so it doesn't always show
    if (riderIsConnected || doNotCollectFeedback || !shouldShow) return;
    toast(<FeedbackNotification gameName={name} />, {
      delay: 2000, autoClose: 15000, toastId: feedbackToastId, closeOnClick: false, containerId: 'A',
    });
  };

  const clearTimer = () => {
    clearTimeout(timer.current);
  };

  const pauseTimer = () => {
    isTimerPaused.current = true;
    clearTimer();
  };

  const resetTimer = () => {
    if (isTimerPaused.current) isTimerPaused.current = false;
    clearTimer();
    timer.current = setTimeout(handleOnEnd, 1000 * 60 * 2);
  };

  const handleIdle = () => {
    isTimerPaused.current || resetTimer();
  };

  useEffect(() => {
    resetTimer();

    return () => clearTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  if (!mapStateToApp[name]) {
    return <Redirect to="/" />;
  }

  const { Component } = mapStateToApp[name];

  return (
    <Background onClick={handleIdle}>
      <MainScreen>
        <Component
          onEnded={handleOnEnd}
          clearTimer={pauseTimer}
          resetTimer={resetTimer}
          showSettings={setShowSettings}
        />
      </MainScreen>
      <Footer>
        <Logo>portl</Logo>
        <ButtonsContainer>
          <SmallButton onClick={setShowSettings}>
            <SettingsIcon />
          </SmallButton>
          <CloseButton onClick={handleOnEnd}>
            <CloseText>Close</CloseText>
            <CloseIcon size={34} fill="#2f2f2f" />
          </CloseButton>
        </ButtonsContainer>
      </Footer>
    </Background>
  );
};

export default AppContainer;
