import React, { useState, useRef } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { trackMixpanel } from '../../analytics';
import { getTabletVolume, changeTabletVolume } from '../../socket/index';
import { LoaderWrapper, VideoOverlay, StyledReactPlayer } from './style';
import PauseControls from './PauseControls';
import OverlayTextWrapper from './OverlayTextWrapper';

const Loader = () => (
  <LoaderWrapper>
    <ClipLoader
      css="border-width: 5px"
      size={75}
      color="#efefef"
      loading
    />
  </LoaderWrapper>
);

const VideoPlayer = ({
  url,
  artistData,
  handleGoBack,
  showSettings,
}) => {
  // Amount video has played in seconds
  const [played, setPlayed] = useState(0);
  // State for video loaded
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef();

  const tabletVolume = getTabletVolume();
  if (tabletVolume < 15) changeTabletVolume(50);

  // Handle play of video
  const handlePlay = async (video) => {
    trackMixpanel('SpotlightVideoPlayClick', { video }, true);
  };

  // Handle closing of video player
  const handleVideoClose = () => {
    const videoTimeWatched = videoRef.current.getCurrentTime();
    trackMixpanel('SpotlightVideoClosed', { url, videoTimeWatched }, true);
    handleGoBack();
  };

  // When video has ended, reset the timer to go back to main page
  const onEnded = () => {
    document.getElementsByTagName('video')[0].load();
    trackMixpanel('SpotlightVideoComplete', { url }, true);
    handleGoBack();
  };

  // When video has loaded, set loaded state to true
  const handleLoaded = () => {
    setLoaded(true);
  };

  return (
    <>
      <StyledReactPlayer
        url={url}
        height="100%"
        width="100%"
        ref={videoRef}
        controls={false}
        playing
        onReady={handleLoaded}
        onPlay={() => handlePlay(url)}
        onProgress={({ played: videoPlayed }) => setPlayed(videoPlayed)}
        onEnded={() => onEnded()}
        onError={(e) => { console.log(e.target.error.message); handleGoBack(); }}
      />
      <VideoOverlay>
        <OverlayTextWrapper artistData={artistData} />
        {/* If light is not set to an image and video is still loading, show loader */}
        {loaded || <Loader />}
        <PauseControls
          handleVideoClose={handleVideoClose}
          played={played}
          showSettings={showSettings}
          url={url}
          setPlayed={setPlayed}
          videoRef={videoRef}
        />
      </VideoOverlay>
    </>
  );
};
export default VideoPlayer;
