import React from 'react';
import styled from 'styled-components';
import DotLoader from 'react-spinners/DotLoader';

const Background = styled.div`
    background-color: #1c1c1c;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const Text = styled.h1`
    font-size: 42px;
    font-weight: 800;
    color: #1fb6ff;
`;
const LoadingContainer = styled.div`
    text-align: center;
    padding-bottom: 40px;
`;

const CheckingForUpdates = ({ children }) => (
  <Background data-testid="checking-for-updates">
    <LoadingContainer>
      <DotLoader
        size={40}
        color="#1FB6FF"
        loading
      />
    </LoadingContainer>
    <Text>{children}</Text>
  </Background>
);

export default CheckingForUpdates;
