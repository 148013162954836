import React, { useEffect } from 'react';
import { getStrapiQuizByName } from '../../api';
import QuizBackground from '../../components/QuizBackground';

const QuizCharity = ({
  onStepChange, onEnded, clearTimer, setQuestions, _isMounted,
}) => {
  useEffect(() => {
    const id = setTimeout(() => {
      onEnded();
    }, 30000);

    // eslint-disable-next-line consistent-return
    const getQuestions = async () => {
      const response = await getStrapiQuizByName('Christmas');
      if (response.error || response.Question.length === 0) return onEnded();

      response.Question.forEach((e) => {
        e.Options = e.Options.sort(() => Math.random() - 0.5);
      });

      if (_isMounted) {
        setQuestions(response.Question.sort(() => Math.random() - 0.5));
      }
    };

    clearTimer();
    getQuestions();

    return () => clearTimeout(id);
    // eslint-disable-next-line
      }, []);

  return <QuizBackground src="/assets/christmasCharity.webp" onClick={onStepChange} />;
};

export default QuizCharity;
