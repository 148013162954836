import React from 'react';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import useContent from '../context/content';
import { trackMixpanel } from '../analytics';
import {
  Background, Header, ItemContainer, Image, Title,
} from './GameZoneComponents/style';

const responsive = {
  all: {
    breakpoint: {
      max: 2000,
      min: 0,
    },
    items: 2,
    partialVisibilityGutter: 135,
  },
};

const Item = ({
  imageUrl, title, description, onGameClick, name,
}) => (
  <ItemContainer onClick={() => onGameClick(name)}>
    <Image src={imageUrl} />
    <Title>
      {title}
    </Title>
    {description}
  </ItemContainer>
);

const GameZone = () => {
  const { tiles, showSettings } = useContent();
  const history = useHistory();

  const gamesTiles = tiles.filter((element) => element.contentType === 'GameZone' && element.isActive);

  const handleGameClick = (name) => {
    history.push(`/app/${name}`);
    trackMixpanel('AppStart', { name });
  };
  return (
    <Background>
      <Header>
        Game Zone
      </Header>
      <Carousel
        responsive={responsive}
        partialVisible
        infinite
        arrows={!showSettings}
      >
        {gamesTiles.map((tile) => (
          <Item
            key={tile.id}
            imageUrl={tile.imageUrl}
            title={tile.title}
            description={tile.description}
            name={tile.name}
            onGameClick={handleGameClick}
          />
        ))}
      </Carousel>
    </Background>
  );
};

export default GameZone;
