import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import ClipLoader from 'react-spinners/ClipLoader';
import { trackMixpanel } from '../../analytics';
import { getTabletVolume, changeTabletVolume } from '../../socket/index';
import { getMedia } from '../../api';

const StyledReactPlayer = styled(ReactPlayer)`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const VideoOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: ${({ playing }) => (playing
    ? 'none'
    : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(10, 10, 10, 0.2), rgba(0, 0, 0, 0))')};
`;
const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: -1;
`;
const ProgressContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: rgba(222, 222, 222, 0.75);
  overflow: hidden;
  z-index: 2;
  position: absolute;
  bottom: 0;
`;
const ProgressFiller = styled.div`
  height: 100%;
  width: ${({ played }) => played * 100}%;
  text-align: right;
  background-color: ${({ playing }) => (playing ? '#efefef' : '#dd334c')};
`;
const VideoSlider = styled.input`
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  opacity: 0;
`;
const ControlsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
const StyledIcon = styled.svg`
  height: 70px;
  width: 70px;
  padding-left: 16px;
  padding-bottom: 20px;
`;
const VideoCloseButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  margin: 15px;
`;
const VideoTitle = styled.p`
  position: absolute;
  padding: 15px;
  margin-top: 40px;
  transform: translateY(-50%);
  top: 0;
  left: 0;
  text-align: left;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
`;
const PlayIcon = () => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="50.824"
    height="58"
    viewBox="0 0 50.824 58"
  >
    <defs>
      <filter
        id="z1hf0k44ua"
        width="50.824"
        height="58"
        x="0"
        y="0"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur result="blur" stdDeviation="3" />
        <feFlood floodOpacity=".161" />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g>
      <g filter="url(#z1hf0k44ua)" transform="translate(9 6) translate(-9 -6)">
        <path
          fill="#fff"
          d="M8 9.059v33.52a3.229 3.229 0 0 0 4.983 2.721L39.32 28.536a3.236 3.236 0 0 0 0-5.468L12.983 6.341A3.229 3.229 0 0 0 8 9.059z"
          transform="translate(1 .18)"
        />
      </g>
    </g>
  </StyledIcon>
);

const PauseIcon = () => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="53.16"
    height="59.02"
    viewBox="0 0 53.16 59.02"
  >
    <defs>
      <filter
        id="sl5t4oeeia"
        width="53.16"
        height="59.02"
        x="0"
        y="0"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur result="blur" stdDeviation="3" />
        <feFlood floodOpacity=".161" />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g>
      <g filter="url(#sl5t4oeeia)" transform="translate(9 6) translate(-9 -6)">
        <path
          fill="#fff"
          d="M11.86 46.02a5.877 5.877 0 0 0 5.86-5.86v-29.3A5.877 5.877 0 0 0 11.86 5 5.877 5.877 0 0 0 6 10.86v29.3a5.877 5.877 0 0 0 5.86 5.86zm17.58-35.16v29.3a5.877 5.877 0 0 0 5.86 5.86 5.877 5.877 0 0 0 5.86-5.86v-29.3A5.877 5.877 0 0 0 35.3 5a5.877 5.877 0 0 0-5.86 5.86z"
          transform="translate(3 1)"
        />
      </g>
    </g>
  </StyledIcon>
);

const Loader = () => (
  <LoaderWrapper>
    <ClipLoader css="border-width: 5px" size={75} color="#efefef" loading />
  </LoaderWrapper>
);

const VideoProgressBar = ({
  playing,
  played,
  setPlayed,
  videoRef,
  appName,
  title,
  url,
}) => {
  const handleSeekChange = (evt) => {
    const videoSeekTo = parseFloat(evt.target.value);
    trackMixpanel(`${appName}Seek`, { title, url, videoSeekTo }, true);
    setPlayed(videoSeekTo);
    videoRef.current.seekTo(videoSeekTo);
  };

  return (
    <ProgressContainer>
      <ProgressFiller playing={playing} played={played} />
      <VideoSlider
        type="range"
        min={0}
        max={0.999999}
        step="any"
        value={played}
        onChange={handleSeekChange}
      />
    </ProgressContainer>
  );
};

const Controls = ({ playing, handleClose, title }) => (
  <>
    {playing ? (
      <ControlsWrapper>
        <PauseIcon />
      </ControlsWrapper>
    ) : (
      <>
        <VideoTitle>{title}</VideoTitle>
        <ControlsWrapper>
          <PlayIcon />
        </ControlsWrapper>
        <VideoCloseButton
          size="65px"
          src="/assets/portl-spotlight/Close.png"
          onClick={handleClose}
        />
      </>
    )}
  </>
);

const VideoPlayer = ({
  url,
  title,
  handleVideoClose,
  appName,
  resetTimer,
  clearTimer,
}) => {
  // set video playing
  const [playing, setPlaying] = useState(true);
  const [videoUrl, setVideoUrl] = useState();
  // Amount video has played in seconds
  const [played, setPlayed] = useState(0);
  // State for video loaded
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef();

  const tabletVolume = getTabletVolume();
  if (tabletVolume < 15) changeTabletVolume(50);

  useEffect(() => {
    const loadVideoFromCache = async () => {
      const blob = await getMedia(url);
      console.log('blob res: ', blob);
      setVideoUrl(blob.error ? url : blob);
    };
    loadVideoFromCache();
  }, [url]);

  // Handle pause video player
  const handlePausePlay = () => {
    if (playing) {
      resetTimer();
    } else {
      clearTimer();
    }
    setPlaying((prevState) => !prevState);
  };

  // Handle closing of video player
  const handleClose = () => {
    const videoTimeWatched = videoRef.current.getCurrentTime();
    trackMixpanel(`${appName}Closed`, { title, url, videoTimeWatched }, true);
    handleVideoClose();
  };

  // When video has ended, reset the timer to go back to main page
  const onEnded = () => {
    document.getElementsByTagName('video')[0].load();
    trackMixpanel(`${appName}Complete`, { title, url }, true);
    handleVideoClose();
  };

  // When video has loaded, set loaded state to true
  const handleLoaded = () => {
    setLoaded(true);
  };

  return (
    <>
      <StyledReactPlayer
        url={videoUrl}
        height="100%"
        width="100%"
        ref={videoRef}
        controls={false}
        playing={playing}
        onReady={handleLoaded}
        onProgress={({ played: videoPlayed }) => setPlayed(videoPlayed)}
        onEnded={() => onEnded()}
        onError={(e) => {
          console.log(e.target.error.message);
          handleClose();
        }}
      />
      <VideoOverlay playing={playing} onClick={handlePausePlay}>
        {loaded || <Loader />}
        <Controls
          playing={playing}
          played={played}
          setPlayed={setPlayed}
          handleClose={handleClose}
          title={title}
          videoRef={videoRef}
        />
      </VideoOverlay>
      <VideoProgressBar
        playing={playing}
        played={played}
        setPlayed={setPlayed}
        videoRef={videoRef}
        title={title}
        url={url}
        appName={appName}
      />
    </>
  );
};

export default VideoPlayer;
