import React from 'react';
import styled from 'styled-components';
import { socket } from '../../socket';

const ConnectionStatusStyled = styled.span`
    margin-left: 10px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${(props) => (props.connected ? '#30de92' : '#d80f4d')};
`;

const ConnectionStatus = () => {
  const [isConnected, setConnect] = React.useState(socket.connected);

  const toggleSetConnect = () => {
    setConnect(!isConnected);
  };

  React.useEffect(() => {
    socket.on('connect', toggleSetConnect);
    socket.on('disconnect', toggleSetConnect);

    return () => {
      socket.removeListener('connect', toggleSetConnect);
      socket.removeListener('disconnect', toggleSetConnect);
    };
    // eslint-disable-next-line
    }, [isConnected])

  return <ConnectionStatusStyled connected={isConnected} id="connection-dot" />;
};

export default ConnectionStatus;
