/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, {
  useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  ImgWrapper,
  DescriptionContainer, Viewed,
  PageWrapper, Text, LinksContainer, LinkWrapper, LogoContainer, PlayIcon, Logo,
} from './VideoElephantComponents/style';
import AdPlayer from '../views/AdLoop/AdPlayer';
import {
  getMrssContent,
} from '../api';
import { generateCode } from '../utils/helper';
import { requestVistar } from '../views/AdLoop/RequestVistar';
import useContent from '../context/content';
import { requestHivestack } from '../views/AdLoop/RequestHivestack';
import Loading from '../components/Loading';

const mapItemTypeToFunction = {
  vistar: (_, incrementProgrammatic) => requestVistar(incrementProgrammatic),
  hivestack: (_, incrementProgrammatic) => requestHivestack(incrementProgrammatic),
};

const VideoElephant = () => {
  const location = useLocation();
  const { incrementProgrammatic } = useContent();

  const [video, setVideo] = useState(undefined);
  const [channelDetails, setChannelDetails] = useState([]);
  const [index, setIndex] = useState(0);
  const [viewedChannels, setViewedChannels] = useState([]);
  const [ads, setAds] = useState([]);
  const [playAd, setPlayAd] = useState(true);
  const [adIndex, setAdIndex] = useState(0);
  const [loadingAds, setLoadingAds] = useState(true);

  const getAd = async (adType) => {
    const requestAdFunction = mapItemTypeToFunction[adType];

    try {
      const vistarAdData = await requestAdFunction({}, incrementProgrammatic);

      if (!vistarAdData.error && !vistarAdData.empty) {
        const {
          mediaFile, duration, pop, adPartner, component,
        } = vistarAdData;

        const newVistarAd = {
          id: generateCode(4),
          component,
          mediaFile,
          duration,
          pop,
          adPartner,
        };
        return newVistarAd;
      }
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const initializeAds = async () => {
    setAdIndex(0);
    let newAds = [];
    const keys = Object.keys(mapItemTypeToFunction);

    for (let i = 0; i < keys.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const ad = await getAd(keys[i]);
      if (ad !== null) {
        newAds = [...newAds, ad];
      }
    }
    setAds(newAds);
    setLoadingAds(false);
    if (newAds.length === 0 && playAd) {
      setPlayAd(false);
    }
  };

  useEffect(() => {
    setLoadingAds(true);
    initializeAds();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVideoElephant = async () => {
    const items = await getMrssContent(location.data.mrssLink);
    for (let i = 0; i < 4; i += 1) {
      const channel = channelDetails;
      channel.push(items.item[i]);
      setChannelDetails(channel);
    }
    const newState = {
      id: generateCode(4),
      blobURL: channelDetails[index]?.link?._text,
    };
    setVideo(newState);
  };

  useEffect(() => {
    getVideoElephant();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const setVideoViewed = () => {
    const channel = viewedChannels.find((item) => item.id === channelDetails[index]?.guid._text);
    if (!channel) {
      setViewedChannels(
        [...viewedChannels, { id: channelDetails[index]?.guid._text, viewed: true }],
      );
    }
  };

  const jumpToNextVideo = (nextVideo) => {
    if (playAd) {
      /**
       * check to see if there are remaining ads. if not get the next set of ads
       */
      if (adIndex >= ads.length - 1) {
        /**
         * there are no ads left to play
         */
        initializeAds();
      } else {
        fetch(ads[adIndex].pop);
        setAdIndex(adIndex + 1);
      }
    } else {
      setVideoViewed();
      if (!nextVideo) {
        if (index < 3) {
          setIndex(index + 1);
        } else {
          setIndex(0);
        }
      }
      if (nextVideo === 'second') {
        if (index + 1 === 3) {
          setIndex(0);
        } else {
          setIndex(index + 2);
        }
      }
      if (nextVideo === 'third') {
        if (index + 2 === 3) {
          setIndex(0);
        } else {
          setIndex(index + 3);
        }
      }
    }
    setPlayAd(!playAd);
  };

  if (channelDetails.length === 0) { return null; }

  return (
    <PageWrapper>
      <ImgWrapper>
        {loadingAds && ads.length === 0
          ? <Loading />
          : (
            <AdPlayer
              mediaFile={playAd && ads[adIndex] ? ads[adIndex].mediaFile : video}
              videoElephant
              onEnded={() => jumpToNextVideo()}
              onError={(error) => console.log(error)}
            />
          )}
      </ImgWrapper>
      <DescriptionContainer>
        <Text size="25px" lineHeight={1.4} bold>
          {channelDetails && channelDetails[index]?.title?._cdata}
        </Text>
        <Text size="25px" lineHeight={1.4}>
          {location.data?.description}
        </Text>
      </DescriptionContainer>
      <LinksContainer>
        <LinkWrapper src={channelDetails[index === 3 ? 0 : index + 1]?.['media:thumbnail']?._attributes?.url} onClick={() => jumpToNextVideo()}>
          <PlayIcon src="/assets/portl-spotlight/play.png" />
          {
            viewedChannels.find((item) => item.id === channelDetails[index === 3 ? 0 : index + 1]?.guid._text)
            && <Viewed src="/checked.png" />
          }
        </LinkWrapper>
        <LinkWrapper src={channelDetails[index + 1 === 3 ? 0 : index + 2]?.['media:thumbnail']?._attributes?.url} onClick={() => jumpToNextVideo('second')}>
          <PlayIcon src="/assets/portl-spotlight/play.png" />
          {
            viewedChannels.find((item) => item.id === channelDetails[index + 1 === 3 ? 0 : index + 2]?.guid._text)
            && <Viewed src="/checked.png" />
          }
        </LinkWrapper>
        <LinkWrapper src={channelDetails[index + 2 === 3 ? 0 : index + 3]?.['media:thumbnail']?._attributes?.url} onClick={() => jumpToNextVideo('third')}>
          <PlayIcon src="/assets/portl-spotlight/play.png" />
          {
            viewedChannels.find((item) => item.id === channelDetails[index + 2 === 3 ? 0 : index + 3]?.guid._text)
            && <Viewed src="/checked.png" />
          }
        </LinkWrapper>
      </LinksContainer>
      <LogoContainer>
        <Logo src={location?.data?.logo} />
      </LogoContainer>
    </PageWrapper>
  );
};

export default VideoElephant;
