import React, { useState, useEffect } from 'react';
import ordinal from 'ordinal';

import Loading from '../../views/Loading';
import { socket } from '../../socket';
import {
  Title, Score, TableItem, TableTitle,
} from './style';

const QuizLeaderboardTemplate = ({
  isSocket, info, rank, themeColor,
}) => {
  const [position, setPosition] = useState(0);
  const [sortedTop10, setSortedTop10] = useState();

  useEffect(() => {
    let top10 = [info];
    let riderPosition = 0;

    if (!(rank.error || rank.length === 0)) {
      rank.push(info);
      const sortedRank = rank.sort((a, b) => (b.score - a.score));
      top10 = sortedRank.slice(0, 10);

      riderPosition = sortedRank
        .findIndex(({ score, name }) => (info.score === score && info.name === name));
      setPosition(riderPosition);
    }

    setSortedTop10(top10);

    if (isSocket) socket.emit('leaderboard', { top10, position: riderPosition });

    // eslint-disable-next-line
    }, []);

  return (sortedTop10)
    ? (
      <>
        <Title color={themeColor}>
          You Rank
          {' '}
          {ordinal(position + 1)}
          !
        </Title>
        <Score color={themeColor}>
          Score:
          {' '}
          {info.score}
        </Score>
        <TableTitle>
          <div>Rank</div>
          <div>Name</div>
          <div>Score</div>
        </TableTitle>
        {sortedTop10.map((element, i) => (
          <TableItem key={element.score + element.name} highlight={i === position}>
            <div>{ordinal(i + 1)}</div>
            <div>{element.name}</div>
            <div>{element.score}</div>
          </TableItem>
        ))}
      </>
    )
    : <Loading />;
};

export default QuizLeaderboardTemplate;
