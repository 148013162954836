import React from 'react';
import { PlayControlsContainer, IconContainer, Text } from './style';

const PlayControls = () => (
  <PlayControlsContainer>
    <IconContainer src="/assets/portl-spotlight/play.png" />
    <Text bold color="#efefef" size="57px" padding="10px">
      A message for riders.
    </Text>
  </PlayControlsContainer>
);

export default PlayControls;
