/* eslint-disable import/prefer-default-export */
import React from 'react';
import QRCode from 'qrcode.react';
import {
  Container, Title, Address, DescriptionContainer, Description, Image,
  Content, QR, ScanText, ExitModalDetails,
} from './style';

export const DetailsModal = (props) => {
  const { details, setModalVisible } = props;

  return (
    <Container color={details?.color}>
      <Title>{details?.title}</Title>
      <Address>{details?.address}</Address>
      <Content>
        <DescriptionContainer>
          <Description>
            {details?.description}
          </Description>
        </DescriptionContainer>
        <DescriptionContainer>
          <Image src={details?.imgUrl} />
          {
            details?.qrLink && (
            <>
              <QR>
                <QRCode
                  value={details?.qrLink}
                  size={98}
                  level="M"
                  renderAs="svg"
                />
              </QR>
              <ScanText>Scan to send to your phone</ScanText>
            </>
            )
          }
        </DescriptionContainer>
      </Content>
      <ExitModalDetails src="/assets/exit.png" onClick={() => setModalVisible(false)} />
    </Container>
  );
};
