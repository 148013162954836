import React from 'react';
import Carousel from 'react-multi-carousel';
import {
  ArtistCarouselName, TileContainer, CarouselWrapper,
} from './style';

const responsive = {
  all: {
    breakpoint: {
      max: 2000,
      min: 0,
    },
    items: 2,
    partialVisibilityGutter: 135,
  },
};

const Tile = ({ name, background, onClick }) => (
  <TileContainer src={background} onClick={onClick}>
    <ArtistCarouselName>
      {name}
    </ArtistCarouselName>
  </TileContainer>
);

// eslint-disable-next-line import/prefer-default-export
export const CarouselComp = React.memo(({ artists, handleArtistClick }) => (
  <CarouselWrapper>
    <Carousel
      responsive={responsive}
      partialVisible
      infinite
    >
      {artists && artists.map(({ id, name, bio_page_img: bioPageImg }) => (
        <Tile
          key={id + name}
          name={name}
          background={bioPageImg}
          onClick={() => handleArtistClick(id, name)}
        />
      ))}
    </Carousel>
  </CarouselWrapper>
));
