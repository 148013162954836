import React from 'react';
import { useHistory } from 'react-router-dom';
import { trackMixpanel } from '../../analytics';
import { Arrow } from './right-arrow';
import {
  Container, Info, Divider, Logo, LogoContainer,
} from './style';

const BroadcastNotification = ({ closeToast, broadcast }) => {
  const history = useHistory();

  const handleFeedbackClick = () => {
    trackMixpanel('Broadcast', { name: broadcast.title });
    closeToast();
    history.push(broadcast.OnClickUrl);
  };

  return (
    <Container>
      <Info>
        {broadcast.title}
      </Info>
      <Divider />
      <LogoContainer onClick={handleFeedbackClick}>
        <Logo src={broadcast.imgUrl} />
        <Arrow color="#fff" />
      </LogoContainer>
    </Container>
  );
};

export default BroadcastNotification;
