import React from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { sendAuthSMSToDriver, createZendeskTicket } from '../../api';
import { serverUrl } from '../../config';

import ConnectionStatus from '../../components/ConnectionStatus';
import { phone } from '../../utils/validationSchema';

const Panel = styled.div`
    display: flex;
    width: 1280px;
    height: 800px;
    margin: 0 auto;
    padding-left: 78px;
    border-top: solid 15px #1fb6ff;
    background: ${({ theme }) => theme.welcomeBgColor};
`;

const Header = styled.h2`
    color: #1fb6ff;
    margin: 66px 0 0;
    font-size: 56px;
    font-weight: 800;
    line-height: 1.33;
    text-align: left;
`;

const Logo = styled.h2`
    font-size: 54px;
    font-weight: 700;
    margin: 37px 0 0;
    letter-spacing: -2.16px;
    color: #ffffff;
`;

const Text = styled.p`
    color: ${(props) => props.theme.primary};
    width: 638px;
    margin: 32px 0 0;
    font-size: 28px;
    line-height: 1.61;
`;

const HelpText = styled.div`
    width: 570px;
    font-size: 21px;
    line-height: 1.62;
    color: #ffffff;
    text-decoration: underline;
`;

const Space = styled.div`
    height: 80px;
`;

const QRContainer = styled.div`
    background: white;
    width: 251px;
    height: 251px;
    border-radius: 5px;
    padding: 14px;
    margin-bottom: 19px;
`;

const Content = styled.div`
    width: 725px;
`;

const ActionContainer = styled.div`
    align-self: flex-end;
    margin-left: 123px;
`;

const Button = styled.button`
    display: block;
    width: 251px;
    height: 75px;
    border-radius: 10px;
    box-shadow: none;
    background: ${(props) => (props.disabled ? '#d6d6d6' : '#1fb6ff')};
    font-weight: 600;
    margin-bottom: 42px;
    outline: none;
    disabled: ${(props) => props.disabled};
    font-size: 21px;
    line-height: 1.19;
    color: #f9f9f9;
`;

const Input = styled.input`
    display: block;
    width: 251px;
    height: 75px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid ${(props) => ((props.error && props.touched) ? '#d80f4d' : '#d6d6d6')};
    background-color: ${(props) => ((props.error && props.touched) ? '#fae5ec' : '#ebebeb')};
    outline: none;
    font-size: 21px;
    margin-bottom: 14px;
`;

const InputError = styled.div`
    color: #d80f4d;
    font-size: 12px;
    text-align: left;
    padding-top: 3px;
    margin-bottom: 10px;
    line-height: 1.25;
`;

const Form = styled.form`
    margin-bottom: 76px;
`;

const DebugText = styled(Text)`
    color: #1fb6ff;
`;

const validationSchema = Yup.object().shape({
  phone,
});

const PhotoConfirmation = ({ id, setDriver }) => {
  const [QRMode, setQRMode] = React.useState(true);
  const [phoneView, setPhoneView] = React.useState(undefined);
  const [showDebug, setShowDebug] = React.useState(process.env.REACT_APP_ENV !== 'production');
  const version = window.DeviceApi
    && window.DeviceApi.getAppVersion && window.DeviceApi.getAppVersion();

  const handlePhoneSubmit = async ({ phone: phoneNum }, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const code = '1';
    const phoneNumber = code + phoneNum;
    const response = await sendAuthSMSToDriver(phoneNumber, id);
    setSubmitting(false);
    resetForm();

    if (response.error) {
      console.log('Error sending sms: ', response.error);
      createZendeskTicket({ email: 'tablet@portlmedia.com' }, 'Error sending sms', { phone: phoneNum, error: response.error });
      return setPhoneView('error');
    }
    return setPhoneView('success');
  };

  let logoCount = 0;
  const handleLogoClick = () => {
    logoCount += 1;
    if (logoCount >= 5) setShowDebug(!showDebug);
  };

  const renderPhoneText = () => {
    switch (phoneView) {
      case 'success': {
        return (
          <Text>
            We sent you a text message with a link to the setup screen. Please check your phone!
          </Text>
        );
      }
      case 'error': {
        return (
          <>
            <Text>
              We&apos;re currently having an issue texting you the link.
              Our team is looking into the problem.
            </Text>
            <Text>Please try scanning the QR code instead.</Text>
          </>
        );
      }
      default: {
        return (
          <>
            <Text>
              Input your phone number and we&apos;ll text you a link that
              will return you to the setup screen.
            </Text>
          </>
        );
      }
    }
  };

  return (
    <Panel>
      <Content>
        <Logo onClick={handleLogoClick}>portl</Logo>
        <Header>{!QRMode && phoneView === 'error' ? 'Oops.' : 'Refer to the install guide on your phone.'}</Header>
        {QRMode
          ? (
            <Text>
              You can scan the QR code again to return
              to the setup screen on your phone at any time.
            </Text>
          )
          : renderPhoneText()}
        <Text>
          Once you&apos;ve completed the setup process your tablet will
          automatically activate the Portl experience!
        </Text>

        {showDebug
          ? (
            <DebugText>
              v:
              {process.env.REACT_APP_GIT_SHA}
              {' '}
              [
              {process.env.REACT_APP_ENV || 'localhost'}
              ]
              {` wb: ${version}`}
              <ConnectionStatus />
            </DebugText>
          )
          : <Space />}

        <HelpText onClick={() => setDriver(undefined)}>
          {/* eslint-disable-next-line max-len */}
          If you&apos;ve completed the setup process and are still seeing this screen, please click here.
        </HelpText>
      </Content>
      <ActionContainer>
        {QRMode
          ? (
            <QRContainer>
              <QRCode
                value={`${serverUrl}/login/${id}`}
                size={223}
                level="Q"
                renderAs="svg"
                imageSettings={{
                  src: '/logo.webp',
                  height: 35,
                  width: 82.55,
                  excavate: true,
                }}
              />
            </QRContainer>
          )
          : (
            <Formik
              initialValues={{ phone: '' }}
              validationSchema={validationSchema}
              onSubmit={handlePhoneSubmit}
            >
              {(formProps) => (
                <Form onSubmit={formProps.handleSubmit}>
                  <Input
                    onChange={formProps.handleChange('phone')}
                    onBlur={formProps.handleBlur('phone')}
                    name="phone"
                    id="phone"
                    value={formProps.values.phone}
                    error={formProps.errors.phone}
                    touched={formProps.touched.phone}
                    placeholder="888-888-8888"
                  />
                  {formProps.errors.phone
                    && formProps.touched.phone
                    && <InputError>{formProps.errors.phone}</InputError>}
                  <Button type="submit" disabled={formProps.isSubmitting}>{formProps.isSubmitting ? 'Submitting..' : 'Submit'}</Button>
                </Form>
              )}
            </Formik>
          )}
        <Button onClick={() => setQRMode(!QRMode)}>{QRMode ? 'QR Not working? Click here.' : 'Scan QR code instead'}</Button>
      </ActionContainer>
    </Panel>
  );
};

export default PhotoConfirmation;
