import React from 'react';
import { Header, SubHeader } from './style';

// eslint-disable-next-line import/prefer-default-export
export const QuizHeader = ({
  score, questions, qIndex, children,
}) => (
  <>
    <Header>
      Question
      {' '}
      {(qIndex + 1)}
      {' '}
      /
      {questions.length}
    </Header>
    <SubHeader withSpace>
      Time:
      {' '}
      {' '}
      {children}
    </SubHeader>
    <SubHeader>
      Score:
      {' '}
      {score}
    </SubHeader>
  </>
);
