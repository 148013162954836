import React, { useState, useEffect } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

import { trackMixpanel } from '../analytics';
import { getAllStrapiArtists } from '../api';
import {
  ANIMATION_TIME, CSSTransitionWrapper, Background, PortlSpotlightTitle,
  ArtistContainer, ArtistInfoContainer, ArtistJobText, ArtistName, Divider,
  ButtonAndInfoContainer, CheckoutArtistButton, MoreArtistsTitle,
} from './SpotlightSelectionComponents/style';
import { CarouselComp } from './SpotlightSelectionComponents/CarouselComp';

const SpotlightSelection = ({ onEnded }) => {
  const [artists, setArtists] = useState();
  // will need a link as well
  const [showCaseArtist, setShowCaseArtist] = useState();
  const [currentBackground, setCurrentBackground] = useState(0);
  const history = useHistory();

  // get list of artists on first render
  useEffect(() => {
    let isMounted = true;

    const getArtists = async () => {
      const response = await getAllStrapiArtists();
      // do something with error
      if (response.error) {
        onEnded();
        return;
      }
      if (isMounted) {
        setArtists(response);
        setShowCaseArtist(response[currentBackground]);
      }
    };
    getArtists();

    return () => { isMounted = false; };
    // eslint-disable-next-line
    }, []);

  // For setting up interval, background image change
  useEffect(() => {
    const handleChangeBackground = () => {
      let newBackground = 0;
      const numArtists = artists.length;

      if (currentBackground !== numArtists - 1) {
        newBackground = currentBackground + 1;
      }

      setShowCaseArtist(artists[newBackground]);
      setCurrentBackground(newBackground);
    };

    const interval = setInterval(handleChangeBackground, 5000);

    return () => clearInterval(interval);
  }, [artists, currentBackground]);

  const handleArtistClick = (id, artistName) => {
    history.push(`/app/spotlight/${id}`);
    trackMixpanel('AppStart', { name: 'Spotlight', artistName, spotlightId: id });
  };

  return (
    <>
      <CSSTransitionWrapper>
        <TransitionGroup component={null}>
          <CSSTransition
            key={showCaseArtist && showCaseArtist.id}
            timeout={ANIMATION_TIME}
            classNames="transition"
            appear
          >
            <Background src={showCaseArtist && showCaseArtist.intro_video_img}>
              <PortlSpotlightTitle>Portl Spotlight</PortlSpotlightTitle>
              <ArtistContainer>
                <ButtonAndInfoContainer>
                  <ArtistInfoContainer>
                    <ArtistJobText>{showCaseArtist && showCaseArtist.occupation}</ArtistJobText>
                    <Divider />
                    <ArtistName>{showCaseArtist && showCaseArtist.name}</ArtistName>
                  </ArtistInfoContainer>
                  {showCaseArtist
                  && (
                    <CheckoutArtistButton
                      onClick={() => handleArtistClick(showCaseArtist.id, showCaseArtist.name)}
                    >
                      Check out this artist
                    </CheckoutArtistButton>
                  )}
                </ButtonAndInfoContainer>
                <MoreArtistsTitle>More Artists</MoreArtistsTitle>
              </ArtistContainer>
            </Background>
          </CSSTransition>
        </TransitionGroup>
      </CSSTransitionWrapper>
      {artists && <CarouselComp artists={artists} handleArtistClick={handleArtistClick} />}
    </>
  );
};

export default SpotlightSelection;
