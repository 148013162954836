import React from 'react';
import { useLocation } from 'react-router-dom';
import * as dayjs from 'dayjs';
import QRCodeWithData from '../components/QRCodeWithData';
import {
  Background, BgOverlay, FadeImg, Logo, Blue, Content, ArticleContent, Title,
  SubTitle, TimeText, QRContainer, Sidebar, QRText, QRTextBold, Text,
} from './NewsArticleComponents/style';

const advancedFormat = require('dayjs/plugin/advancedFormat');

dayjs.extend(advancedFormat);

const NewsPage = ({ onEnded }) => {
  const { state } = useLocation();

  if (!state || !state.source) {
    onEnded();
    return null;
  }

  const {
    title, source, urlToImage, publishedAt, content, url,
  } = state;

  const hoursAgo = publishedAt && dayjs(publishedAt).format('k');

  return (
    <Background>
      <FadeImg src={urlToImage} opacityTransition={0.3} />
      <BgOverlay />
      <Logo>
        <Blue>portl</Blue>
        news
      </Logo>
      <Content>
        <ArticleContent>
          <SubTitle>
            {source.name}
            <TimeText>{`${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`}</TimeText>
          </SubTitle>
          <Title>{title}</Title>
          <Text>{content.split('[')[0]}</Text>
        </ArticleContent>
        <Sidebar>
          <QRContainer>
            <QRCodeWithData
              name="NewsArticle"
              redirectUrl={url}
              size={150}
            />
          </QRContainer>
          {/* <TouchIcon /> */}
          <QRTextBold>Scan code</QRTextBold>
          <QRText>to continue reading</QRText>
        </Sidebar>
      </Content>
      {state.title}
    </Background>
  );
};

export default NewsPage;
