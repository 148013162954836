import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import mixpanel from 'mixpanel-browser';

import Loading from '../views/Loading';

import { socket } from '../socket';
import { generateCode } from '../utils/helper';
import { getLastLocation } from '../store/location';
import useAuth from '../context/auth';
import { QuizInfo } from './NothingQuizComponents/QuizInfo';
import QuizGame from './NothingQuizComponents/QuizGame';
import { StartQuiz, GAME_NAME } from './NothingQuizComponents/StartQuiz';

const NothingQuiz = ({ onEnded, clearTimer }) => {
  const parsed = queryString.parse(window.location.search);

  const privateCode = useRef(parsed.remoteControl || generateCode(15));
  const [isSocket, setSocket] = useState(false);
  const [step, setStep] = useState(0);
  const [questions, setQuestions] = useState();

  const isMounted = useRef(true);
  const { driver } = useAuth();
  const IMEI = localStorage.getItem('IMEI');

  const handleStepChange = () => {
    setStep((prev) => prev + 1);
  };

  useEffect(() => {
    // Sockets config

    socket.on(GAME_NAME, ({ code }) => {
      if (privateCode.current !== code) {
        return socket.emit('gameStartStatus', 'code');
      }
      return socket.emit('gameStartStatus', 'active');
    });
    socket.once('connected', (code) => {
      if (privateCode.current === code) {
        socket.emit('tabletConnected');
        setSocket(true);
        handleStepChange();
      } else {
        socket.emit('wrongCode');
        onEnded();
      }
    });

    return () => {
      socket.off(GAME_NAME);
      socket.off('connected');
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickMixpanel = async () => {
    handleStepChange();
    const position = await getLastLocation();

    mixpanel.track('QuizNothingTabletStart', {
      $latitude: position.lat,
      $longitude: position.lng,
      email: driver.email,
    });
  };

  useEffect(() => () => { isMounted.current = false; }, []);

  switch (step) {
    case 1:
      return (
        <QuizInfo
          onStepChange={handleClickMixpanel}
          setQuestions={setQuestions}
          _isMounted={isMounted.current}
          clearTimer={clearTimer}
          onEnded={onEnded}
        />
      );
    case 2:
      return (questions)
        ? (
          <QuizGame
            questions={questions}
            onEnded={onEnded}
            isSocket={isSocket}
            email={driver.email}
          />
        )
        : <Loading />;
    default:
      return (
        <StartQuiz
          onStepChange={handleStepChange}
          privateCode={privateCode.current}
          IMEI={IMEI}
        />
      );
  }
};

export default NothingQuiz;
