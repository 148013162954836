import { IMEI } from '../../socket';
import { getProviderAdsRequest } from '../../api';
import { AdType } from '../../config';

// eslint-disable-next-line import/prefer-default-export
export const requestBroadsign = async (incrementProgrammatic) => {
  incrementProgrammatic('broadsignAdReq');
  const width = 1920;
  const height = 1080;
  const deviceId = IMEI;

  const broadsignAd = await getProviderAdsRequest({
    deviceId, adType: AdType.Broadsign, data: { width, height },
  });

  return broadsignAd;
};
