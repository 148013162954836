import styled from 'styled-components';

const PINK = '#E23C84';
const BLUE = '#169aff';
export const RED = '#cd0e11';

export const Background = styled.div`
  text-align: center;
  position: relative;
  background: url('/assets/love-to-win/love-to-win-bg.webp') center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  font-family: 'NeonTubes2';
  ${({ disableClick }) => disableClick && 'pointer-events: none;'}
`;

export const HowToPlayHeader = styled.h1`
  font-size: 60px;
  padding-top: 200px;
  color: #ffffff;
  text-shadow: 0 0 10px ${PINK};
  -webkit-text-stroke: 2px #ffffff;
`;

export const HowToPlaySubheader = styled.p`
  font-family: 'NeonTubes2';
  font-size: 40px;
  padding-top: 30px;
  color: #ffffff;
  text-shadow: 0 0 10px ${PINK};
  -webkit-text-stroke: 0.5px ${PINK};
  max-width: 900px;
  margin: 0 auto;
`;

export const NeonButton = styled.button`
  height: 80px;
  box-shadow: 0 3px 6px 0 ${({ color }) => color || BLUE};
  border: solid 2px ${({ color }) => color || BLUE};
  font-size: 40px;
  color: #ffffff;
  line-height: 80px;
  text-shadow: 0 3px 6px ${({ color }) => color || BLUE};
  -webkit-text-stroke: 1px ${({ color }) => color || BLUE};
  margin: 40px auto 0;
  background: none;
  border-radius: 10px;
  width: ${({ width }) => width || 260}px;
`;

export const BoxContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GameGrid = styled.div`
  position: relative;
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(4, 1fr);
  width: 680px;
  margin: 0 auto;
  padding-top: 180px;
`;

export const DealbreakerOverlay = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.85);
  ${(props) => props.open && 'transition: opacity 0.3s ease 4s;'}
  visibility: ${(props) => !props.open && 'hidden'};
  opacity: ${(props) => (props.open ? '1' : '0')};
`;

export const TouchText = styled.p`
  font-size: 40px;
  color: #fff;
  line-height: 1.25;
  font-size: 40px;
  -webkit-text-stroke: 0.5px #ffffff;
`;

export const TouchTextContainer = styled.div`
  width: 200px;
  position: absolute;
  bottom: 200px;
  left: 60px;
  text-align: left;
  > svg {
    position: relative;
    top: -15px;
    left: -30px;
  }
`;

export const UncoverTextContainer = styled.div`
  width: 200px;
  position: absolute;
  top: 200px;
  right: 40px;
  text-align: left;
`;

export const WarningText = styled.p`
  position: absolute;
  transform: rotate(5deg);
  bottom: 25px;
  right: 10px;
  width: 260px;
  height: 160px;
  text-shadow: 0 0 10px #dd65ba;
  -webkit-text-stroke: 0.5px #dd65ba;
  font-size: 25px;
  line-height: 1.6;
  text-align: left;
  color: #ffffff;
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const DealbreakerTitle = styled.h1`
  font-size: 88px;
  color: #ffffff;
  text-shadow: 0 3px 6px ${RED};
  -webkit-text-stroke: 3px ${RED};
  padding-top: 40px;
`;

export const DealbreakerText = styled.p`
  width: 348px;
  text-shadow: 0 3px 6px rgba(255, 255, 255, 0.16);
  font-size: 30px;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding-bottom: 40px;
`;

export const DBContainer = styled.div`
  padding-left: 275px;
  padding-top: 30px;
  text-align: left;
`;

export const PhoneImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 180px;
`;

export const BackgroundImg = styled.img`
  display: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
`;
