import styled from 'styled-components';

export const Container = styled.div`
  background-image: linear-gradient(to bottom, #4d475b, #676177);
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const Info = styled.p`
  font-size: 32px !important;
  color: #eee;
  text-align: left;
  margin-right: 10px;
`;

export const Divider = styled.div`
  border-left: 1px solid #eee;
  margin: 20px 0;
`;

export const Logo = styled.img`
  width: 70px;
  height: 70px;
  margin: 14px 0 10px 15px;
`;

export const LogoContainer = styled.div`
  align-items: center;
  text-align: center;
  width: 158px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
