import styled from 'styled-components';
import QuizBackground from '../../components/QuizBackground';

export const THEMECOLOR = '#cd0e11';

export const QRWrapper = styled.div`
  width: 137px;
  height: 137px;
  background: #ffffff;
  padding: 5px;
  position: absolute;
  bottom: 17px;
  right: 199px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const StartButton = styled.div`
  width: 137px;
  height: 137px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${THEMECOLOR};
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: 'Mountains of Christmas', cursive;
  font-size: 85px;
  font-weight: bold;
  color: #ffffff;
  padding: 12px;
  position: absolute;
  bottom: 169px;
  right: 199px;
`;

export const QuizBackgroundWrapper = styled(QuizBackground)`
    position: relative;
`;

export const LeaderboardMessage = styled.div`
    position: absolute;
    color: ${THEMECOLOR};
    width: 354px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #e8f5ff;
    text-align: center;
    font-size: 41px;
    font-weight: bold;
    padding-top: 31px;
    left: 26px;
    bottom: 31px;
`;

export const Message = styled.div`
    font-size: 36px;
    font-weight: 400;
    margin: 20px 24px 31px;
`;

export const PlayAgainButton = styled.div`
    width: 354px;
    height: 102px;
    border-radius: 10px;
    background-color: ${THEMECOLOR};
    font-size: 47px;
    font-weight: bold;
    line-height: 2.13;
    text-align: center;
    color: #fafafa;
`;
