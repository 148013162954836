import styled from 'styled-components';

export const ANIMATION_TIME = 700;

export const CSSTransitionWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  // CSS for Ad Loop Transition
  & .transition-appear {
    opacity: 0;
  }
  & .transition-appear-active {
      opacity: 1;
      transition: opacity ${ANIMATION_TIME}ms ease;
  }
  & .transition-enter {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  & .transition-enter-active {
    opacity: 1;
    transition: opacity ${ANIMATION_TIME}ms ease;
  }
  & .transition-exit {
    opacity: 1;
  }   
  & .transition-exit-active {
    opacity: 0;
    transition: opacity ${ANIMATION_TIME}ms ease;
  }
`;

export const Background = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px 0 25px 34px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.81) 76%), url("${({ src }) => src}");
  background-size: cover;
  display: flex;
  flex-direction: column;
  font-family: Lora;
  text-align: left;
  color: #efefef;
`;

export const PortlSpotlightTitle = styled.div`
  width: fit-content;
  font-weight: bold;
  font-size: 30px;
`;

export const ArtistContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ArtistInfoContainer = styled.div`
  width: fit-content;
  margin-top: 138px;
  display: flex;
  align-items: center;
`;

export const ArtistJobText = styled.div`
  font-size: 45px;
  margin-right: 23px;
`;

export const Divider = styled.div`
  height: 50px;
  width: 5px;
  background: #e7a729;
`;

export const ArtistName = styled(ArtistJobText)`
  font-weight: bold;
  margin-right: 0;
  margin-left: 23px;
`;

export const ButtonAndInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const CheckoutArtistButton = styled.button`
  margin-top: 25px;
  margin-bottom: 100px;
  width: 100%;
  height: 60px;
  background-color: #efefef;
  border-radius: 10px;
  font-size: 26px;
  color: #262525;
  text-align: center;
  line-height: 60px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const MoreArtistsTitle = styled.div`
  font-size: 26px;
  margin-bottom: 15px;
`;

export const TileContainer = styled.div`
  width: 345px;
  height: 194px;
  font-size: 26px;
  margin-right: 20px;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 53%, #000000), url("${({ src }) => src}");
  background-size: cover;
  border-radius: 10px;
`;

export const ArtistCarouselName = styled.div`
  width: 100%;
  color: #efefef;
  position: absolute;
  bottom: 15px;
  text-align: center;
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 100px;
  z-index: 1;
  padding: 0px 34px;

  & .react-multi-carousel-item {
    width: 345px !important;
    margin-right: 20px !important;
  }
`;
