/* eslint-disable import/prefer-default-export */
export const data = [
  {
    id: 1,
    introImg: 'https://portl-assets.s3.amazonaws.com/corus_entertainment/csivegas.jpg',
    title: 'CSI: VEGAS – NEW SEASON THURSDAYS ON GLOBAL',
    description: 'Last Fall`s #1 new program returns. As new threats enshroud the neon streets of Sin City, the CSI team continues to use the latest forensic techniques, and follow the evidence, in order to preserve and serve justice in the neon-lit streets of Vegas.',
    channelColor: '#161e35',
    blobURL: 'https://portl-assets.s3.amazonaws.com/corus_entertainment/Portl15CSIVegas.mp4',
    videos: [
      {
        id: 1,
        img: '/assets/CSI.png',
        video: 'CSI',
      },
      {
        id: 2,
        img: '/assets/SoHelpMeTodd.png',
        video: 'SoHelpMeTodd',
      }, {
        id: 3,
        img: '/assets/FireCountry.png',
        video: 'FireCountry',
      },
    ],
  },
  {
    id: 2,
    introImg: 'https://portl-assets.s3.amazonaws.com/corus_entertainment/sohelpmetodd.jpg',
    title: 'SO HELP ME TODD – NEW SERIES THURSDAYS ON GLOBAL',
    description: 'Todd is a good private detective, but the rest of his directionless life is a bit of an unsolved mystery. He reluctantly agrees when his uber-successful lawyer mom offers him a job as an in-house investigator at her firm as a way for him to get his act together.',
    channelColor: '#161e35',
    blobURL: 'https://portl-assets.s3.amazonaws.com/corus_entertainment/Portl15SHMT.mp4',
    videos: [
      {
        id: 1,
        img: '/assets/SoHelpMeTodd.png',
        video: 'SoHelpMeTodd',
      }, {
        id: 2,
        img: '/assets/FireCountry.png',
        video: 'FireCountry',
      },
      {
        id: 3,
        img: '/assets/CSI.png',
        video: 'CSI',
      },
    ],
  },
  {
    id: 3,
    introImg: 'https://portl-assets.s3.amazonaws.com/corus_entertainment/firecountry.jpg',
    title: 'FIRE COUNTRY – NEW SERIES FRIDAYS ON GLOBAL',
    description: 'The heat is turned up when Bode Donovan is assigned to an unconventional prison-release program in his rural Northern California hometown – the place where all his troubles began.',
    channelColor: '#161e35',
    blobURL: 'https://portl-assets.s3.amazonaws.com/corus_entertainment/Portl15FireCountry.mp4',
    videos: [
      {
        id: 1,
        img: '/assets/FireCountry.png',
        video: 'FireCountry',
      },
      {
        id: 2,
        img: '/assets/CSI.png',
        video: 'CSI',
      },
      {
        id: 3,
        img: '/assets/SoHelpMeTodd.png',
        video: 'SoHelpMeTodd',
      },
    ],
  },
];
