import React, {
  useState, useEffect,
} from 'react';
import { ProgressContainer, ProgressFill } from './style';

const ProgressBar = ({ duration, id }) => {
  const [time, setTime] = useState(0); // in seconds

  useEffect(() => {
    // reset if changes
    setTime(0);
    const timerId = setTimeout(() => setTime(100), 0.1);
    return () => clearTimeout(timerId);
  }, [id]);

  return (
    <ProgressContainer>
      <ProgressFill duration={duration} width={time} />
    </ProgressContainer>
  );
};
// eslint-disable-next-line import/prefer-default-export
export const ProgressBarMemo = React.memo(ProgressBar);
