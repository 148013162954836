import io from 'socket.io-client';
import { serverUrl } from '../config';
import indexedDB from '../indexedDB';
// eslint-disable-next-line import/no-self-import
import * as thisModule from './index';

// eslint-disable-next-line import/no-mutable-exports
let driverConnected = false;

// Create websocket connection to PortlServer-env
const wsServer = serverUrl.indexOf('localhost') >= 0
  ? 'http://localhost:5000'
  : `${serverUrl}:5000`;

const socket = io(wsServer, {
  forceNew: true,
  transports: ['websocket'],
});

const getObject = (key) => {
  const value = localStorage.getItem(key);
  return value && JSON.parse(value);
};

const MAX_BRIGHTNESS = 255;
const MAX_VOLUME = 15;

// webview functions

const changeTabletVolume = (percent) => {
  if (window.DeviceApi && window.DeviceApi.adjustVolume) {
    const currentLevel = window.DeviceApi.getVolume();
    const volumeTo = (percent * MAX_VOLUME) / 100;

    if (currentLevel < volumeTo) {
      for (let i = currentLevel; i < volumeTo; i += 1) {
        window.DeviceApi.adjustVolume(true); // raise by relative amount
      }
    } else {
      for (let i = currentLevel; i > volumeTo; i -= 1) {
        window.DeviceApi.adjustVolume(false); // lower to 0
      }
    }

    if (driverConnected) socket.emit('reChangeVolume', percent);
  }
};

const changeTabletBrightness = (percent) => {
  if (window.DeviceApi && window.DeviceApi.setBrightness) {
    const brightness = (percent * MAX_BRIGHTNESS) / 100;
    const res = window.DeviceApi.setBrightness(brightness); // 0 - 255

    if (res !== 'Success') {
      return console.log('Error executing window.DeviceApi.setBrightness(): ', res);
    }

    if (driverConnected) socket.emit('reChangeBrightness', percent);
  }
  return null;
};

const getTabletBrightness = () => {
  if (window.DeviceApi && window.DeviceApi.getBrightness) {
    return Math.round((window.DeviceApi.getBrightness() / MAX_BRIGHTNESS) * 100);
  }
  return undefined;
};
const getTabletVolume = () => {
  if (window.DeviceApi && window.DeviceApi.getVolume) {
    return Math.round((window.DeviceApi.getVolume() / MAX_VOLUME) * 100);
  }
  return undefined;
};

// Socket functions
// Clear old clientId storage
localStorage.removeItem('clientId');
// eslint-disable-next-line import/no-mutable-exports
let IMEI = localStorage.getItem('IMEI');

// Device Information
if (window.DeviceApi && window.DeviceApi.getImei) {
  IMEI = window.DeviceApi.getImei();
  localStorage.setItem('IMEI', IMEI);
}

const streamSetting = { isStreaming: false, IMEI: null };

const getDeviceInformation = async () => {
  let webviewVersion;
  let batteryLevel;
  let batteryCharging;
  let deviceStorage;
  let downloadSpeed = 0;
  let uploadSpeed = 0;
  let iccid;
  const brightnessLevel = getTabletBrightness();
  const volumeLevel = getTabletVolume();

  const commitHash = process.env.REACT_APP_GIT_SHA;
  const driver = getObject('driver');

  try {
    deviceStorage = navigator.storage
      && navigator.storage.estimate
      && (await navigator.storage.estimate());
    webviewVersion = window.DeviceApi.getAppVersion();
    batteryLevel = window.DeviceApi.getBatteryLevel();
    batteryCharging = window.DeviceApi.getBatteryCharging();
    downloadSpeed = window.DeviceApi.getNetworkDownloadSpeed();
    uploadSpeed = window.DeviceApi.getNetworkUploadSpeed();
    iccid = window.DeviceApi.getDeviceICCID();
  } catch (error) {
    webviewVersion = webviewVersion || 'undefined';
    batteryLevel = batteryLevel || 'unknown';
    batteryCharging = batteryCharging || 'unknown';
  }

  return {
    name: window.navigator.userAgent,
    commitHash,
    webviewVersion,
    batteryLevel,
    batteryCharging,
    deviceStorage,
    downloadSpeed,
    uploadSpeed,
    brightnessLevel,
    volumeLevel,
    iccid,
    driverEmail: driver && driver.email,
  };
};

window.onbeforeunload = () => {
  // Force server to disconnect before refreshing
  console.log('Before windows unload - Emitting socket disconnect');
  socket.disconnect(true);
};

// Sockets - Driver App ---------------------------

socket.on('changeVolume', (percent) => changeTabletVolume(percent));

socket.on('changeBrightness', (percent) => changeTabletBrightness(percent));

socket.on('driverConnected', async () => {
  console.log('driver connected');
  driverConnected = true;
  // emit current device stats

  const deviceInfo = await getDeviceInformation();
  socket.emit('deviceInfo', deviceInfo);
});

socket.on('changeURL', (newUrl) => {
  console.log('Overriding url to: ', newUrl);
  window.location.href = newUrl;
});

socket.on('driverDisconnected', () => {
  console.log('driver disconnected');
  driverConnected = false;
});

// Sockets - Admin Dashboard ----------------------

socket.on('tabletInfo', async () => {
  // console.warn(`Admin ${requester} requested for tablet information.`);
  // requiring thisModule to be able to test getDeviceInformation function with jest
  const tabletInfo = await thisModule.getDeviceInformation();
  socket.emit('infoPayload', { ...tabletInfo, IMEI });
});

socket.on('startStreaming', (id) => {
  streamSetting.isStreaming = true;
  streamSetting.IMEI = id;
  console.log('...begin streaming to Admin Panel....');
});

socket.on('stopStreaming', () => {
  streamSetting.isStreaming = false;
  streamSetting.IMEI = null;
  console.log('...stopping stream...');
});

socket.on('getLogs', async (msg) => {
  console.log('Log Request Recieved: ', new Date());
  const { logAmount } = msg;
  let results;
  if (typeof logAmount === 'number') {
    results = await indexedDB.getLastXLogs(logAmount);
  } else {
    results = await indexedDB.getAllLogs();
  }

  socket.emit('logsPayload', [...results]);
});

socket.on('deleteLogs', async (action) => {
  const { email } = action && action.admin;
  let results;
  if (email.includes('portlmedia.com')) {
    results = await indexedDB.clearAllLogs();
  }
  socket.emit('actionCompleted', { results });
});

// Sockets - Server -------------------------------

socket.on('connect', async () => {
  console.log('Connected to server with socket id: ', socket.id);
  const driver = getObject('driver');

  const deviceInfo = await getDeviceInformation();
  socket.emit('id', IMEI, deviceInfo, driver && driver.email);
  console.log('done emiting id');
});

socket.on('disconnect', async (reason) => {
  console.log('Disconnected from server, reason: ', reason);
  if (reason === 'io server disconnect') {
    // the disconnection was initiated by the server, you need to reconnect manually
    socket.connect();
  }
  // else the socket will automatically try to reconnect
  const deviceInfo = await getDeviceInformation();
  const driver = getObject('driver');
  socket.emit('id', IMEI, deviceInfo, driver && driver.email);
});

socket.on('error', (err) => {
  console.log('An error occured, firing from client ', err);
});

socket.on('sockErr', () => {
  console.error(
    'An error occurred while connecting to the server. Please refresh your page and try again. If you have this site open in multiple tabs, please close all other tabs before refreshing.',
  );
});

export {
  driverConnected,
  changeTabletVolume,
  changeTabletBrightness,
  getTabletBrightness,
  getTabletVolume,
  socket,
  streamSetting,
  IMEI,
  getDeviceInformation,
};
