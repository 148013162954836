import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const OptionBoxSvg = ({ fill, letter }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={83} height={72} viewBox="0 0 83 72" style={{ margin: '0 30px' }}>
    <defs>
      <filter id={letter} x={17} y={13} width={51} height={51} filterUnits="userSpaceOnUse">
        <feOffset dx={5} dy={4} input="SourceAlpha" />
        <feGaussianBlur result="blur" />
        <feFlood floodOpacity="0.89" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_2544" data-name="Group 2544" transform="translate(-658 -367)">
      <rect id="Rectangle_4123" data-name="Rectangle 4123" width={78} height={66} transform="translate(659 370)" fill={fill || '#ffc100'} />
      <rect id="Rectangle_4124" data-name="Rectangle 4124" width={72} height={3} transform="translate(661 367)" />
      <rect id="Rectangle_4127" data-name="Rectangle 4127" width={72} height={3} transform="translate(661 436)" />
      <rect id="Rectangle_4125" data-name="Rectangle 4125" width={7} height={7} transform="translate(664 374)" />
      <rect id="Rectangle_4128" data-name="Rectangle 4128" width={7} height={7} transform="translate(726 374)" />
      <rect id="Rectangle_4129" data-name="Rectangle 4129" width={7} height={6} transform="translate(726 427)" />
      <rect id="Rectangle_4126" data-name="Rectangle 4126" width={7} height={6} transform="translate(664 427)" />
      <rect id="Rectangle_4122" data-name="Rectangle 4122" width={66} height={3} transform="translate(661 370) rotate(90)" />
      <g transform="matrix(1, 0, 0, 1, 658, 367)" filter={`url(#${letter})`}>
        <text id={`${letter}-2`} data-name={letter} transform="translate(17 59)" fill="#e07e00" fontSize={46} fontFamily="PressStart2P-Regular, 'Press Start \x1A P'"><tspan x={0} y={0}>{letter}</tspan></text>
      </g>
      <rect id="Rectangle_4130" data-name="Rectangle 4130" width={66} height={4} transform="translate(741 370) rotate(90)" />
    </g>
  </svg>
);
