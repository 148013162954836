import React from 'react';
import { OverlayTextContainer, Text, Divider } from './style';

const OverlayTextWrapper = ({ artistData }) => (
  <OverlayTextContainer>
    <Text bold color="#efefef" padding="34px">
      Portl Spotlight
    </Text>
    <Divider vertical />
    <Text color="#efefef" padding="34px">
      {artistData && artistData.occupation}
    </Text>
    <Divider vertical />
    <Text bold color="#efefef" padding="34px">
      {artistData && artistData.name}
    </Text>
  </OverlayTextContainer>
);

export default OverlayTextWrapper;
