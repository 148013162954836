import {
  getProviderAdsRequest,
} from '../../api';
import { IMEI } from '../../socket';
import { AdType } from '../../config';

// eslint-disable-next-line import/prefer-default-export
export const requestVistar = async (incrementProgrammatic) => {
  incrementProgrammatic('vistarAdReq');

  const deviceId = IMEI;
  const vistarAd = await getProviderAdsRequest({
    deviceId, adType: AdType.Vistar,
  });

  return vistarAd;
};
