import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  background-color: ${(props) => (props.color)};
  width: 666px;
  top: 30px;
  right: 50px;
  border-radius: 20px;
  border: solid 2px #043650;
  padding: 25px 33px 30px 33px;
`;

export const Title = styled.p`
  font-size: 75px;
  font-weight: bold;
  line-height: 1.05;
  letter-spacing: -3px;
  color: #eee;    
`;

export const Address = styled.p`
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: -1.6px;
  color: #eee;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.p`
  font-size: 26px;
  font-weight: bold;
  color: #eee;
  letter-spacing: -1.04px;
  line-height: 1.04;
  margin-bottom: 10px;
`;

export const Image = styled.img`
  height: 186px;
  width: 278px;
  border-radius: 8px;
  border: solid 2px #043650;
  margin-left: 10px;
`;

export const QR = styled.div`
  width: 116px;
  height: 116px;
  border-radius: 5px;
  align-self: center;
  margin-top: 38px;
  background-color: white;
  padding: 10px 8px;
`;

export const ScanText = styled.p`
  font-size: 21px;
  margin-top: 5px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.84px;
  color: #eee;
  align-self: center;
`;

export const LegendContainer = styled.div`
  position: absolute;
  top:  558px;
  width: 775px;
  height: 135px;
  border-radius: 25px;
  border: solid 2px #043650;
  background-color: #fff;
  padding: 10px 50px 10px 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 120px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LegendText = styled.p`
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: -1.25px;
  text-align: left;
  color: #043650;
  margin-left: 6px;
`;

export const ExitModal = styled.img`
  heigth: 30px;
  width: 30px;
  position: absolute;
  right: 12px;
`;

export const ExitModalDetails = styled(ExitModal)`
  top: 20px;
`;

export const QuestionMark = styled.div`
  width: 65px;
  height: 65px;
  padding: 5px 20px 5px 19px;
  border: solid 2px #043650;
  background-color: #fff;
  position: absolute;
  border-radius: 33px;
  top: 600px;
  left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Question = styled.p`
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.62;
  color: #043650;
  font-family: Montserrat;
`;
