import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import QuizNameInput from '../../components/QuizNameInput';
import SubmitLoadingContainer from '../../components/SubmitLoadingContainer';
import { feedbackQuestions } from '../../config';
import {
  Container, BoardTitle, PersonalScore, BoardName, BoardInfo, ScoreOptContainer, ContentOption,
  SelectArrow, HighScoreBtn,
} from './style';

// eslint-disable-next-line import/prefer-default-export
export const ScorePage = ({
  score, name, handleNameChange, handleSelect, selectedContent, onSubmit, submitScoreLoading,
}) => (
  <Container>
    <BoardTitle>
      Complete!
    </BoardTitle>
    <PersonalScore>
      Score:
      {score}
    </PersonalScore>
    <BoardName>
      Enter your name
    </BoardName>
    <QuizNameInput name={name} handleNameChange={handleNameChange} />
    <BoardInfo>
      What type of content would you like to see more of on the tablet?
    </BoardInfo>
    <ScoreOptContainer width={900}>
      <ContentOption onClick={() => handleSelect(feedbackQuestions[0])}>
        {selectedContent === feedbackQuestions[0] && <SelectArrow id={feedbackQuestions[0]} />}
        {feedbackQuestions[0]}
      </ContentOption>
      <ContentOption onClick={() => handleSelect(feedbackQuestions[1])}>
        {selectedContent === feedbackQuestions[1] && <SelectArrow id={feedbackQuestions[1]} />}
        {feedbackQuestions[1]}
      </ContentOption>
    </ScoreOptContainer>
    <ScoreOptContainer width={900}>
      <ContentOption onClick={() => handleSelect(feedbackQuestions[2])}>
        {selectedContent === feedbackQuestions[2] && <SelectArrow id={feedbackQuestions[2]} />}
        {feedbackQuestions[2]}
      </ContentOption>
      <ContentOption onClick={() => handleSelect(feedbackQuestions[3])}>
        {selectedContent === feedbackQuestions[3] && <SelectArrow id={feedbackQuestions[3]} />}
        {feedbackQuestions[3]}
      </ContentOption>
    </ScoreOptContainer>
    {(submitScoreLoading)
      ? (
        <SubmitLoadingContainer>
          <ClipLoader color="#ffffff" loading={submitScoreLoading} size={35} />
        </SubmitLoadingContainer>
      )
      : <HighScoreBtn onClick={onSubmit} />}
  </Container>
);
