import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background: black;
    height: 100vh;
`;

const Title = styled.div`
    font-weight: 600;
    color: white;
    background: #0289F2;
    font-size: 72px;
    padding: 20px 40px;
`;

const DeprecatedTextBlock = styled.div`
    background: black;
    font-size: 48px;
    color: #0289F2;
    font-weight: 300;
    width: 90%;
    padding: 40px;
`;

const Space = styled.span`
    display: block;
    height: 30px;
`;

const DeprecatedTablet = () => (
  <Container>
    <Title>Tablet upgrade required.</Title>
    <DeprecatedTextBlock>
      Hello, we have recently made some improvements to our system.
      You will need to upgrade your tablet to continue.
      <Space />
      Please log into your driver account and go to the Contact section to request a new tablet.
      <Space />
      And don&apos;t worry! This is a
      {' '}
      <span style={{ fontWeight: '700' }}>free</span>
      {' '}
      upgrade!
    </DeprecatedTextBlock>
  </Container>
);

export default DeprecatedTablet;
