import styled from 'styled-components';

export const Background = styled.div`
    width: 100%;
    height: 100%;
    background-color: #e7e7fb;
`;

export const Header = styled.div`
    font-size: 50px;
    font-weight: bold;
    color: #000000;
    padding: 8px 0 11px 28px;
`;

export const ItemContainer = styled.div`
    font-size: 26px;
    line-height: 1.58;
    width: 443px;
    margin-left: 28px;
`;

export const Image = styled.img`
    width: 100%;
    height: 377px;
    border-radius: 18px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 30px;
`;

export const Title = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
`;
