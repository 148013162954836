// import { getObject } from '../utils/helper';

const getObject = (key) => {
  const value = localStorage.getItem(key);
  return value && JSON.parse(value);
};

const defaultLocation = {
  lat: '',
  lng: '',
};

const driver = getObject('driver');
const province = driver ? driver.province : 'ON';

// Set default time zone based on driver's Province
switch (province) {
  // If driver is located in BC, set default location to Vancouver
  case 'BC':
    defaultLocation.lat = 49.263566;
    defaultLocation.lng = -123.138572;
    break;
  // Default location is set to Ontario (Downtown Toront)
  default:
    defaultLocation.lat = 43.62426;
    defaultLocation.lng = -79.3871;
}

export const defaultLat = defaultLocation.lat;
export const defaultLng = defaultLocation.lng;

const lastLocation = {
  lat: undefined,
  lng: undefined,
};

const updateLocation = () => new Promise((resolve) => {
  const success = ({ coords }) => {
    // console.log('coords: ', coords);
    lastLocation.lat = coords.latitude;
    lastLocation.lng = coords.longitude;
    resolve(coords);
  };
  const error = (err) => {
    const msg = `geolocation.getCurrentPosition error: ${err.message}`;
    resolve({ error: msg });
  };
  if (!navigator.geolocation) {
    const msg = 'Device doesnt have position';
    // console.log(msg);
    resolve({ error: msg });
  }
  // console.log('updating locaion');
  const options = {
    timeout: 10000,
    enableHighAccuracy: true,
  };
  navigator.geolocation.getCurrentPosition(success, error, options);
});

setInterval(updateLocation, 1000); // update every second

export const getLastLocation = async () => {
  if (!lastLocation.lat || !lastLocation.lng) {
    const location = await updateLocation();
    if (location.error) {
      // console.log('getLastLocation error: ', location.error);
      return defaultLocation;
    }
  }
  return lastLocation;
};

export const isLocationDefault = () => (
  lastLocation.lat === defaultLat && lastLocation.lng === defaultLng
);

// class Location {
//     constructor(params) {
//         if (params.lat || params.lng) {
//             this.state = {
//                 realLoc: false
//             }
//             const coords = this.getLocationFromParams(params)
//             this.lat = coords.lat
//             this.lng = coords.lng
//         } else {
//             this.state = {
//                 realLoc: true
//             }
//             this.updateLocation()
//         }
//     }

//     getLocationFromParams = (coords) => {
//         if (coords && this.isValidLat(coords.lat) && this.isValidLng(coords.lng)) {
//             return { lat: Number(coords.lat), lng: Number(coords.lng) }

//         } else if (coords && this.isValidLat(coords.lat)) {
//             // Entered valid latitude but invalid longitude
//             return { lat: Number(coords.lat), lng: defaultLng }

//         } else if (coords && this.isValidLng(coords.lng)) {
//             // Entered valid longitude but invalid latitude
//             return { lat: defaultLat, lng: Number(coords.lng) }

//         } else { // Default location: Alcatraz
//             return { lat: defaultLat, lng: defaultLng }
//         }
//     }

//     isValidLat = (lat) => {
//         return (lat !== null && lat !== undefined && !isNaN(lat) && Math.abs(lat) <= 90);
//     }

//     isValidLng = (lng) => {
//         return (lng !== null && lng !== undefined && !isNaN(lng) && Math.abs(lng) <= 180);
//     }

//     getRegion = async () => {
//         const { lat, lng } = await this.getCoords();

//         if (((-114.75 <= lng) && (lng <= -113.5)) && ((50.5 <= lat) && (lat <= 51.75))) {
//             // bottom right lat: 50.5 long: -113.5
//             // upper left lat: 51.75 long: -114.75
//             return "Calgary"

//         } else if (((-79.5 <= lng) && (lng <= -79.3)) && ((43.3 <= lat) && (lat <= 43.7))) {
//             // upper right lat: 43.647721 long: -79.398928 // Toronto
//             // bottom left lat: 43.631724 long: -79.419109
//             return "Toronto"

//         } else if (((-83.9 <= lng) && (lng <= -82.7)) && ((42.0 <= lat) && (lat <= 42.8))) {
//             // upper right lat: 42.8 long: -82.7
//             // bottom left lat: 42.0 long: -83.9
//             return "Detroit"

//         } else {
//             return undefined
//         }
//     }

//     getCoords = async () => {
//         if (this.lat && this.lng) {
//             console.log('getCoords1: ', { lat: this.lat, lng: this.lng });

//             return { lat: this.lat, lng: this.lng }
//         } else {
//             await this.updateLocation()
//             console.log('getCoords2: ', { lat: this.lat, lng: this.lng });
//             return { lat: this.lat, lng: this.lng }
//         }
//     }

//     updateLocation = () => {
//         if (this.state.realLoc) {
//             this.getCurrentPosition()
//                 .then(({ latitude, longitude }) => {
//                     this.lat = latitude;
//                     this.lng = longitude;
//                 })
//                 .catch((err) => {
//                     console.log(err)
//                     this.lat = defaultLat
//                     this.lng = defaultLng
//                 })
//         }
//     }

//     getCurrentPosition = () => {
//         return new Promise((resolve, reject) => {
//             const success = ({ coords }) => {
//                 console.log('coords: ', coords);
//                 resolve(coords);
//             }
//             const error = (err) => {
//                 const msg = `geolocation.getCurrentPosition error: ` + err;
//                 console.log(msg);
//                 reject(msg);
//             }
//             if (!navigator.geolocation) {
//                 const msg = "Device doesnt have position";
//                 console.log(msg);
//                 reject(msg)
//             }
//             navigator.geolocation.getCurrentPosition(success, error);
//         })
//     }
// }

// const searchParams = new URLSearchParams(window.location.search)
// const lat = searchParams && searchParams.has('lat') ? searchParams.get('lat') : undefined;
// const lng = searchParams && searchParams.has('lng') ? searchParams.get('lng') : undefined;

// const location = new Location({ lat, lng })
// console.log('locaiton instance: ', location);

// export default location
