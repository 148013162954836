import React from 'react';
import { getMedia } from '../../api';

const LazyLoadImage = (src) => {
  const [sourceLoaded, setSourceLoaded] = React.useState(false);

  React.useEffect(() => {
    const img = new Image();

    const loadImgFromCache = async () => {
      const blob = await getMedia(src);
      if (blob.error) {
        img.src = src;
        img.onload = () => setSourceLoaded(src);
        return;
      }
      img.src = blob;
      img.onload = () => setSourceLoaded(blob);
    };
    loadImgFromCache();
  }, [src]);

  return sourceLoaded;
};

export default LazyLoadImage;
