import React from 'react';
import {
  Background, RulesViewContainer, TestYourLuckText, RulesContainer, RuleDiv,
  StartButton, StartText, ThreeColverImg, RuleNumber, RuleImg, CircularRuleImg, RuleText,
} from './style';

// eslint-disable-next-line import/prefer-default-export
export const HowToPlay = ({ handlePlayClick }) => (
  <Background>
    <RulesViewContainer>
      <TestYourLuckText>
        Test your luck with the beer coaster flipping game
      </TestYourLuckText>
      <RulesContainer>
        <RuleDiv>
          <RuleNumber>1</RuleNumber>
          <CircularRuleImg
            width="140px"
            src="/assets/four-leaf-fortune/coaster_back.webp"
          />
          <RuleText width="355px">
            There are 18 coasters on the table.
            Tap a coaster to flip it over.
          </RuleText>
        </RuleDiv>
        <RuleDiv>
          <RuleNumber>2</RuleNumber>
          <RuleImg
            width="195px"
            src="/assets/four-leaf-fortune/mug_coaster.webp"
          />
          <RuleText width="365px">
            If your coaster has a clover, you&apos;ll drain some beer!
            You need three (3) Clovers to win.
          </RuleText>
        </RuleDiv>
        <RuleDiv>
          <RuleNumber>3</RuleNumber>
          <RuleImg
            width="192px"
            src="/assets/four-leaf-fortune/leap_mug.webp"
          />
          <RuleText width="290px">
            Uncover the Leprechaun and it&apos;s GAME OVER
          </RuleText>
        </RuleDiv>
      </RulesContainer>
    </RulesViewContainer>
    <StartButton onClick={handlePlayClick}>
      <StartText>Start</StartText>
      <ThreeColverImg src="/assets/four-leaf-fortune/threeclovers.webp" />
    </StartButton>
  </Background>
);
