import styled from 'styled-components';

export const HowToPlayImage = styled.div`
  background-image: url('/assets/easter/easterHowToPlay.webp');
  background-size: cover;
  height: 100%;
  width: 100%;
`;

export const GameComponentBackground = styled.div`
  background-image: url('/assets/easter/easterBackground.webp');
  background-size: cover;
  height: 100%;
  width: 100%;
  ${({ disableClick }) => disableClick && 'pointer-events: none;'};
  color: #fafafa;
  position: relative;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 13px 0 0 30px;
  ${({ disableClick }) => disableClick && 'pointer-events: none;'}
`;

export const Title = styled.p`
  font-family: 'Dela Gothic One', cursive;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -webkit-text-stroke: 3px #89d26a;
  font-size: 54px;
  z-index: 1;
  position: relative;
`;

export const ChooseWording = styled.p`
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  margin-top: 8px;
  z-index: 1;
  position: relative;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FlipContainer = styled.div`
  width: 900px;
  height: 535px;
  display: inline-grid;
  grid-template: repeat(5, 1fr) / repeat(5, 1fr);
  margin-top: 10px;
`;

export const Description = styled.div`
  width: 350px;
  display: inline-block;
  border: solid 1px #707070;
  background-color: rgba(47, 47, 47, 0.42);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  text-align: center;
  padding: 5px 0 15px;
`;

export const Subtitle = styled(Title)`
  -webkit-text-stroke: 1px #e23c84;
  font-size: 34px;
`;

export const Instruction = styled.p`
  font-size: 32px;
  font-weight: 500;
  margin-top: 20px;
  line-height: 39px;

  & > span {
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const Coaster = styled.div`
  background: url('${({ src }) => src}') no-repeat center;
  background-size: contain;
  width: 145px;
  height: 145px;
  margin: 18px 0px;
  ${({ isEmpty }) => isEmpty && 'opacity: 0;'}
`;

export const LoserOverlay = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  padding-right: 50px;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, #272a2c 50%, rgba(0, 0, 0, 0.8) 50%);
  ${(props) => props.open && 'transition: visibility 0.3s ease 4s, opacity 4s;'}
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? '1' : '0')};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template: 719px / 1fr 639px;
`;

export const Image = styled.div`
  background: url('${({ src }) => src}') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 311px;
  margin: 100px auto 0;
`;

export const Text = styled.div`
  width: fit-content;
  margin: 0 auto;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 500;
  text-align: right;
  ${({ margin }) => `margin: ${margin}`}
`;

export const OverText = styled.div`
  width: fit-content;
  margin: 20px auto;
  -webkit-text-stroke: 2px ${({ color }) => color};
  font-family: 'Dela Gothic One', cursive;
  font-size: 68px;
`;

export const PlayAgain = styled.div`
  background: url('/assets/easter/playAgain.webp') no-repeat center;
  background-size: cover;
  width: 348px;
  height: 172px;
  margin: 14px 0 0 auto;
`;

export const CloudsEffect = styled.div`
  width: 630px;
  height: 120px;
  background: url('/assets/easter/clouds.webp') no-repeat center;
  background-size: contain;
  position: absolute;
  top: 40px;
  animation: 3s infinite alternate fadeIn;
  animation: 10s infinite alternate move;

  animation-name: fadeIn, move;
  animation-duration: 3s, 20s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  @keyframes fadeIn {
    0% {
      opacity: .5;
    }
    100% {
      opacity: .1;
    }
  }

   @keyframes move {
    0% {
      left: 0px;
    }
    100% {
      left: 720px;
    }
  }
`;

export const WinContainer = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/easter/easterBackground.webp');
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  color: #fafafa;
`;

export const CongratsText = styled.div`
  -webkit-text-stroke: 3px #dd65ba;
  width: fit-content;
  padding-top: 22px;
  font-size: 68px;
  font-family: 'Dela Gothic One', cursive;
  margin: 0 auto;
`;

export const QRTextWrapper = styled.div`
  width: 632px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-left: 630px;
  margin-top: 35px;

  & > p {
    font-size: 40px;
  }
`;

export const QRCodeContainer = styled.div`
    padding-top: 10px;
    border-radius: 5px;
    border: solid 1px #707070;
    width: 280px;
    height: 280px;
    background-color: #ffffff;
    margin: 25px auto;
`;

export const ThreeBasket = styled.div`
  width: 675px;
  height: 425px;
  background-image: url('/assets/easter/threeBasket.webp');
  background-size: cover;
  position: absolute;
  top: 190px;
`;

export const Space = styled.div`
  height: 80px;
`;
