import React from 'react';
import ordinal from 'ordinal';
import {
  Background, Header, SubHeader, ScoreHeader, TableItem, TableItemSpacer, PlayAgainImg, StarSvg,
} from './style';

const StarIcon = ({ left }) => (
  <StarSvg xmlns="http://www.w3.org/2000/svg" height={20} viewBox="0 0 24 24" width={20} left={left}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill="#e5d2be" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
  </StarSvg>
);

// eslint-disable-next-line import/prefer-default-export
export const LeaderboardPage = ({
  position, score, sortedTop10, handlePlayAgain,
}) => (
  <Background src="/assets/movie-quiz-bg.webp">
    <Header>
      You rank
      {' '}
      {ordinal(position + 1)}
      !
    </Header>
    <SubHeader>
      Score:
      {' '}
      {score}
    </SubHeader>
    <ScoreHeader>In order of score</ScoreHeader>
    {sortedTop10.map(({ score: sortedTop10Score, name }, i) => (
      <TableItem key={sortedTop10Score + name} highlight={i === position}>
        {i === position && <StarIcon left />}
        {name}
        <TableItemSpacer />
        {sortedTop10Score}
        {i === position && <StarIcon />}
      </TableItem>
    ))}
    <PlayAgainImg src="/assets/movie-quiz-playagain.webp" onClick={handlePlayAgain} />
  </Background>
);
