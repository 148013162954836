import React from 'react';
import {
  Wrapper, Title, Container, PlayerScore, BoardInfo, InputContainer, InputName,
  Line, ScoreOptContainer, ContentOption, NextButton,
} from './style';

const QuizScoreTemplate = ({
  themeColor, info, setInfo, stdColor = '#1f2639', onSubmit,
}) => {
  const handleChange = ({ target }) => {
    let userName = target.value;
    if (target.value.length > target.maxLength) {
      userName = target.value.slice(0, target.maxLength);
    }
    setInfo({ ...info, name: userName.toUpperCase() });
  };

  const handleSelect = (elemId) => {
    setInfo({ ...info, content: elemId });
  };

  return (
    <Wrapper color={stdColor}>
      <Title color={themeColor}>
        Results
      </Title>
      <Container color={themeColor}>
        <PlayerScore>
          Correctly Answered:
          {info.correct}
        </PlayerScore>
        <PlayerScore>
          Score:
          {info.score}
        </PlayerScore>
      </Container>
      <BoardInfo>
        Please enter your initials:
      </BoardInfo>
      <InputContainer>
        <InputName
          id="scoreName"
          type="text"
          maxLength={3}
          autoComplete="off"
          onChange={handleChange}
          value={info.name}
        />
        <Line color={stdColor} left={4} />
        <Line color={stdColor} left={35} />
        <Line color={stdColor} left={66} />
      </InputContainer>
      <BoardInfo>
        What type of content would you like to see more of on the tablet?
      </BoardInfo>
      <ScoreOptContainer>
        <ContentOption onClick={() => handleSelect('games')} checked={info.content === 'games'}>
          Games
        </ContentOption>
        <ContentOption onClick={() => handleSelect('news')} checked={info.content === 'news'}>
          News
        </ContentOption>
        <ContentOption onClick={() => handleSelect('sports')} checked={info.content === 'sports'}>
          Sports
        </ContentOption>
      </ScoreOptContainer>
      <ScoreOptContainer>
        <ContentOption onClick={() => handleSelect('giveawaysAndPromos')} checked={info.content === 'giveawaysAndPromos'}>
          Giveaways and promos
        </ContentOption>
        <ContentOption onClick={() => handleSelect('riderGeneratedContent')} checked={info.content === 'riderGeneratedContent'}>
          Rider generated content
        </ContentOption>
      </ScoreOptContainer>
      <NextButton color={themeColor} stdColor={stdColor} onClick={onSubmit}>
        See Your Ranking!
      </NextButton>
    </Wrapper>
  );
};

export default QuizScoreTemplate;
