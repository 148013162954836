// eslint-disable-next-line import/no-mutable-exports
let indexDBDisabled = false;

const serverUrl = process.env.REACT_APP_SERVER_URL;
const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const profileUrl = process.env.REACT_APP_DRIVER_URL;
const riderUrl = process.env.REACT_APP_RIDER_URL;
const isProduction = process.env.REACT_APP_ENV === 'production';
const isStaging = process.env.REACT_APP_ENV === 'staging';

export const disableIndexedDB = () => {
  indexDBDisabled = true;
};

export const feedbackQuestions = [
  "Choose # of Q's",
  'Add difficulty levels',
  'More Quizzes!',
  'Other Games',
];

export const AdType = {
  Hivestack: 'hivestack',
  Broadsign: 'broadsign',
  Vistar: 'vistar',
};

export const placeName = null; // "McDonalds" // Uncomment to activate McDonald's branding
export const adsEnabled = false;
export {
  serverUrl, strapiUrl, profileUrl, indexDBDisabled, riderUrl, isProduction, isStaging,
};
