import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import useAuth from '../../context/auth';
import { serverUrl } from '../../config';
import { getLastLocation, isLocationDefault } from '../../store/location';

const QRCodeWithData = ({
  name, size, redirectUrl, fileName,
}) => {
  const { driver } = useAuth();
  const email = driver && driver.email;
  const [position, setPosition] = useState();
  const redirect = redirectUrl ? encodeURIComponent(redirectUrl) : fileName;

  useEffect(() => {
    let isMounted = true;
    const fetchPosition = async () => {
      const { lat, lng } = await getLastLocation();
      if (isLocationDefault()) return;
      if (isMounted) setPosition({ lat, lng });
    };
    fetchPosition();

    return () => { isMounted = false; };
  }, []);

  const query = `redirect=${redirect}&email=${email}&position=${encodeURI(JSON.stringify(position))}`;

  return (
    <QRCode
      value={`${serverUrl}/campaign/${name}?${query}`}
      size={size || 160}
      level="M"
      renderAs="svg"
    />
  );
};

export default React.memo(QRCodeWithData);
