import React from 'react';
import {
  RED, NeonButton, DealbreakerOverlay,
  DealbreakerText, DealbreakerTitle, DBContainer,
  PhoneImg,
} from './style';

const generatePhonePhoto = () => (
  Math.floor(Math.random() * 4)
);

// eslint-disable-next-line import/prefer-default-export
export const DealbreakerScreen = ({ open, onButtonClick }) => (
  <DealbreakerOverlay open={open}>
    <DealbreakerTitle>Dealbreaker!</DealbreakerTitle>
    <DBContainer>
      <DealbreakerText>
        Oops! Looks like
        you’ve uncovered a
        dealbreaker…
      </DealbreakerText>
      <DealbreakerText>
        It’s game over.
      </DealbreakerText>
      <NeonButton onClick={onButtonClick} color={RED} width={280}>Play Again</NeonButton>
    </DBContainer>
    <PhoneImg src={`/assets/love-to-win/love-to-win-phone-${generatePhonePhoto()}.webp`} />
  </DealbreakerOverlay>
);
