import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as dayjs from 'dayjs';
import { getTimeZone } from '../../utils/helper';

const utc = require('dayjs/plugin/utc'); // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const Container = styled.div`    
    padding-left: 15px;
    padding-top: 10px;
    color: #ffffff;
`;

const ClockText = styled.p`
    margin-top: -17px;
    font-size: 59px;
    font-weight: 400;
    letter-spacing: 1.83px;
`;
const DateText = styled.p`
    font-size: 23px;
    font-weight: 300;
`;

const Clock = () => {
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const timeZone = useRef();

  const updateTime = async () => {
    const now = dayjs().tz(timeZone.current);
    // const date = dayjs();
    const dateString = now.format('ddd, MMM D');

    setTime(now.format('hh:mm'));

    if (date !== dateString) setDate(dateString);
  };

  useEffect(() => {
    let id;

    const setUpClock = async () => {
      timeZone.current = await getTimeZone();
      updateTime();
      // Update the clock every minute. -- CONS: a minute diff
      id = setInterval(updateTime, 60 * 1000);
    };

    setUpClock();

    return () => clearInterval(id);
    // eslint-disable-next-line
    }, []);

  return (
    <Container>
      <DateText>{date}</DateText>
      <ClockText>
        {time}
      </ClockText>
    </Container>
  );
};

export default React.memo(Clock);
