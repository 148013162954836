import React, { useState, useRef } from 'react';
import { generateCode } from '../utils/helper';

import { socket } from '../socket';
import useCodeConfirmation from '../utils/customHooks';
import {
  Background, HowToPlayHeader, HowToPlaySubheader, NeonButton,
} from './LoveToWinComponents/style';
import Game from './LoveToWinComponents/Game';

const eventName = 'LoveToWin';

const LoveToWin = ({ resetTimer }) => {
  const [view, setView] = useState('');
  const code = useRef(generateCode(5));

  const handlePlayClick = () => {
    resetTimer();
    setView('game');
  };

  useCodeConfirmation(eventName, code.current, () => {
    socket.emit('eventCodeConfirmation');
    socket.off(eventName);
  });

  switch (view) {
    case 'game': return <Game setView={setView} resetTimer={resetTimer} />;
    case 'win': return (
      <Background>
        <HowToPlayHeader>Congratulations!</HowToPlayHeader>
        <HowToPlaySubheader>
          You won! Portl is developing a new variation of this game for
          the week leading up the St. Paddy&apos;s day. Stay tuned!
        </HowToPlaySubheader>
      </Background>
    );

    default: return (
      <Background>
        <HowToPlayHeader>How to play</HowToPlayHeader>
        <HowToPlaySubheader>
          Uncover as many roses as you can before you hit a Dealbreaker.
        </HowToPlaySubheader>
        <NeonButton onClick={handlePlayClick}>Play Now</NeonButton>
      </Background>
    );
  }
};

export default LoveToWin;
