/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import axios from 'axios';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import jwt from 'jsonwebtoken';
import gql from 'graphql-tag';
import he from 'he';
import { xml2json } from 'xml-js';
import { getAnalyticsInfo } from './analytics';
import { getLastLocation, isLocationDefault } from './store/location';
import { serverUrl, strapiUrl } from './config';

const getObject = (key) => {
  const value = localStorage.getItem(key);
  return value && JSON.parse(value);
};

export const axiosConfig = {
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
  timeout: 5000, // 5s timeout (default is 0)
};

const CACHE_NAME = 'global-cache';
// 50% of storage quota  // Decided 50% because the size of a cache vary a lot
const CACHE_MAX_PERC = 0.5;
// 538mb used to cache 9 url
const MAX_NUMB_CACHE = 60;

const createZendeskTicket = async (driver, subject, commentBody) => {
  const env = process.env.NODE_ENV;
  if (env !== 'production' && env !== 'staging' && env !== 'test') {
    // only localhost has it undefined;
    return console.warn('Disabling zendesk tickets for localhost...');
  }
  const fullSubject = `[${env}] ${subject}`;
  let stringBody;

  const replaceErrors = (errorKey, errorVal) => {
    if (errorVal instanceof Error) {
      const error = {};

      Object.getOwnPropertyNames(errorVal).forEach((errKey) => {
        error[errorKey] = errorVal[errKey];
      });
      return error;
    }
    return errorVal;
  };

  try {
    if (commentBody.error) {
      // TODO: check error object access
      stringBody = JSON.stringify(commentBody.error, replaceErrors);
    } else {
      stringBody = JSON.stringify(commentBody);
    }
  } catch (e) {
    stringBody = commentBody;
  }
  try {
    const body = {
      request: {
        requester: {
          name: 'Tablet Error',
          email: 'edson.zhu@portlmedia.com',
        },
        subject: fullSubject,
        comment: {
          body: `${stringBody}email: ${driver.email} --- ${window.location}`,
        },
      },
    };
    await axios.post(
      'https://portlmedia.zendesk.com/api/v2/requests.json',
      body,
      {
        withCredentials: false,
      },
    );
  } catch (error) {
    console.warn('Error creating Zendesk ticket: ', error);
  }
  return null;
};

const evictCache = async (cache) => {
  let isCacheFull = false;
  const cacheArray = await cache.keys();

  if (navigator.storage && navigator.storage.estimate) {
    try {
      const response = await navigator.storage.estimate();
      isCacheFull = response.usage > response.quota * CACHE_MAX_PERC;
    } catch (error) {
      console.log('Error getting browser storage quota/usage. - evictCache()');
    }
  } else {
    isCacheFull = cacheArray.length >= MAX_NUMB_CACHE;
  }

  if (isCacheFull) {
    const deleteResponse = await cache.delete(cacheArray[0].url);
    console.log('Delete cache item: ', cacheArray[0].url, deleteResponse);
    await evictCache(cache);
  }
};

const getMedia = async (url, name) => {
  let response;
  const request = new Request(url);
  const cache = await caches.open(CACHE_NAME);

  response = await cache.match(request); // If no match is found, the Promise resolves to undefined. => https://developer.mozilla.org/en-US/docs/Web/API/Cache/match

  if (!response) {
    let fetchResponse;
    try {
      // Fetch is being used here instead of axios because
      // we want to cache the actual response which will be transformed to blob later.
      // If we use axios, the response will not come in a format that can be
      // transformed in a blob later on. Axios only gives you a transformed response
      // in axios instead of a response which can be transformed in a blob.
      fetchResponse = await fetch(request);
      await evictCache(cache);
    } catch (error) {
      return { error };
    }

    if (fetchResponse.ok) {
      try {
        await cache.put(request, fetchResponse.clone());
      } catch (err) {
        const title = 'Error Caching Media! (put method) - getMedia()';
        const msg = {
          error: {
            err,
            url: fetchResponse.url,
            status: fetchResponse.status,
            headers: fetchResponse.headers,
          },
        };
        console.log(title, msg);
        // createZendeskTicket({ email }, title, msg);
      }
      response = fetchResponse;
      // console.log('Fetched Response Url and Status: ', fetchResponse.url, fetchResponse.status);
    } else {
      const driver = getObject('driver');
      const email = driver && driver.email;
      const title = 'Error fetching Media! - getMedia() ';
      createZendeskTicket({ email }, title, fetchResponse);
      return { error: title };
    }
  }

  try {
    const buf = await response.arrayBuffer();
    const blob = new Blob([buf]);
    return URL.createObjectURL(blob);
  } catch (err) {
    const driver = getObject('driver');
    const email = driver && driver.email;
    const deleteResponse = await cache.delete(request);
    const title = `[Tablet] Error converting the ${name || ''} media file to blob! - getMedia() `;
    const body = {
      error: {
        err,
        url,
        deleteResponse,
        status: response.status,
        headers: response.headers,
      },
    };
    // console.log(title);
    createZendeskTicket({ email }, title, body);
    return { error: title };
  }
};

const getStrapiToken = async () => {
  try {
    const { data } = await axios.get(
      `${serverUrl}/strapi/portl-client`,
      axiosConfig,
    );
    return data.token;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

const checkAuth = async () => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/driver/auth`,
      undefined,
      axiosConfig,
    );
    return data.result;
  } catch (error) {
    console.error('Error checking authentication: ', error);
    return false;
  }
};

const getDriverInfo = async () => {
  try {
    const { data } = await axios.get(`${serverUrl}/driver/info`, axiosConfig);
    return data;
  } catch (error) {
    console.log('Error fetching driver info: ', error.response);
    return { error };
  }
};

const getHiveStackUUID = async (imei) => {
  // Check if exists in localStorage:
  const uuid = localStorage.getItem('HivestackUuid');
  if (uuid) {
    return uuid;
  }

  // Get from the server and store in localStorage:
  try {
    const { data } = await axios.get(
      `${serverUrl}/tablet/${imei}/uuid`,
      axiosConfig,
    );
    console.log('data: ', data);
    if (data.uuid) {
      localStorage.setItem('HivestackUuid', data.uuid);
    }
    return data.uuid;
  } catch (error) {
    console.error(
      'Error retrieving Hivestack UUID from Portl Server: ',
      error.response,
    );
    return { error };
  }
};

const getHivestackAd = async () => {
  let uuid;
  let adRequestUrl;
  if (
    process.env.REACT_APP_ENV === 'production'
    || process.env.REACT_APP_ENV === 'staging'
  ) {
    const imei = localStorage.getItem('IMEI');
    if (!imei) {
      return { error: 'Could not find IMEI' };
    }
    uuid = await getHiveStackUUID(imei);
    if (!uuid) {
      console.log('Error finding hivestack id of tablet: ', imei);
      return { error: 'Could not find Hivestack UUID' };
    }
    adRequestUrl = 'https://apps.hivestack.com/nirvana/api/v1';
  } else {
    uuid = '123bf677-9a13-43de-a0ff-f62792bf8f31'; // demo ads
    adRequestUrl = 'https://uat.hivestack.com/nirvana/api/v1';
  }

  adRequestUrl += `/units/${uuid}/schedulevast`;
  const adConfig = {
    withCredentials: false,
  };

  try {
    const { data } = await axios.get(adRequestUrl, adConfig);
    return data;
  } catch (error) {
    console.error('Error fetching Hivestack ad: ', error);
    return { error };
  }
};

const getAdomniAd = async () => {
  const imei = localStorage.getItem('IMEI');
  if (!imei) {
    return { error: 'Could not find IMEI' };
  }
  const { lat, lng } = await getLastLocation();

  const OWNER_KEY = '1eWK1xYZnVkpgx';
  let adRequestUrl = `https://neon.adomni.com/v0/integration/vast/v2_0/owner/${OWNER_KEY}?integrationKeys=${imei}`;
  // send device lat & lng
  if (!isLocationDefault()) adRequestUrl += `&lat=${lat}&lng=${lng}`;

  try {
    const { data } = await axios.get(adRequestUrl, { withCredentials: false });
    return data;
  } catch (error) {
    console.error('Error fetching Adomni ad: ', error);
    return { error };
  }
};

// const getVistarVenueId = async (imei) => {
// // Check if exists in localStorage:
// let venueId = undefined;//localStorage.getItem('vistar.venueId');
// if (venueId) {
// return venueId;
// }

// //Get from the server and store in localStorage:
// try {
// const { data } = await axios.get(serverUrl + `/tablet/${imei}/vistar_id`, axiosConfig);
// console.log('data: ', data);
// if (data.venue_id) {
// localStorage.setItem('vistar.venueId', data.venue_id);
// }
// return data.venue_id;
// } catch (error) {
// console.error('Error retrieving Vistar venue_id from Portl Server: ', error.response);
// return { error };
// }
// }

const getProviderAdsRequest = async ({
  deviceId, adType, count, data,
}) => {
  try {
    const response = await axios.post(`${serverUrl}/tablet/ads`, {
      deviceId, adType, count: count || 1, data,
    },
    axiosConfig);
    return response.data?.length > 0 ? response.data[0] : response.data;
  } catch (error) {
    return [];
  }
};

const getImeiByEmail = async () => {
  try {
    const { data } = await axios.get(`${serverUrl}/driver/imei`, axiosConfig);
    console.log('data: ', data);
    return data.imei;
  } catch (error) {
    console.error(
      'Error retrieving Hivestack UUID from Portl Server: ',
      error.response,
    );
    return { error };
  }
};

const getStrapiAds = async () => {
  try {
    const { data } = await axios(`${strapiUrl}/welcomeitems`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getTourGuideCategories = async () => {
  try {
    const { data } = await axios(`${strapiUrl}/tourguidecategories`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getTourGuideItems = async () => {
  try {
    const { data } = await axios(`${strapiUrl}/tourguideitems`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const sendAuthSMSToDriver = async (phone, IMEI) => {
  try {
    const res = await axios.get(`${serverUrl}/auth0sms/${phone}/${IMEI}`);
    return res;
  } catch (error) {
    return { error };
  }
};

const localTabletLogin = async (email) => {
  try {
    const res = await axios(`${serverUrl}/local/login/${email}`);
    return res;
  } catch (error) {
    return { error };
  }
};

const query = gql`
  query {
    microsites {
      id
      url
      name
      image
      isVideo
      type
      location
      importance
      active
      children {
        id
      }
      parent {
        id
      }
    }
  }
`;

const queryCms = async () => {
  // get strapi auth token:
  let strapiToken = localStorage.getItem('strapiToken');
  // 1 week from today converted to NumericDate format
  const weekAhead = (Date.now() + 6.04e8) / 1000;
  const decoded = jwt.decode(strapiToken);
  if (!strapiToken || !decoded || decoded.exp < weekAhead) {
    console.log('Requesting Strapi token');
    strapiToken = await getStrapiToken();
    if (strapiToken) {
      localStorage.setItem('strapiToken', strapiToken);
    } else {
      localStorage.removeItem('strapiToken');
    }
  }

  const httpLink = createHttpLink({ uri: `${strapiUrl}/graphql` });
  // return the headers to the context so httpLink can read them
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${strapiToken}`,
    },
  }));
  const gqlClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return gqlClient.query({ query });
};

const getStrapiPresets = async () => {
  try {
    const { data } = await axios(`${strapiUrl}/preset-locations`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getLocalNews = async (category) => {
  try {
    let dynamicUrl = '/news';
    if (category && category !== '') dynamicUrl += `/${category}`;

    const { data } = await axios.get(serverUrl + dynamicUrl, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getStrapiQuiz = async (id) => {
  try {
    const { data } = await axios(`${strapiUrl}/quizes/${id}`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getStrapiQuizByName = async (name) => {
  try {
    const { data } = await axios(`${strapiUrl}/quizes?name=${name}`, {
      withCredentials: false,
    });
    return data[0] || { error: 'Empty response' };
  } catch (error) {
    return { error };
  }
};

const getQuizScore = async (name, userInfo) => {
  const quizName = name === 'Quizzle' ? 'Game' : name;
  const payload = {
    ...userInfo,
    quizName,
  };
  try {
    const { data } = await axios.post(
      `${serverUrl}/tablet/quiz/score`,
      payload,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

const getPicturesContest = async (name, size) => {
  let url = `${serverUrl}/tablet/picture/voting/${name}`;

  if (size) url += `?quantity=${size}`;

  try {
    const { data } = await axios(url, axiosConfig);
    return data;
  } catch (error) {
    return { error };
  }
};

const getQuizQuestionsOpenTrivia = async (category, amount = 10) => {
  const CategoriesId = {
    'General Knowledge': 9,
    'Entertainment: Books': 10,
    'Entertainment: Film': 11,
    'Entertainment: Music': 12,
    'Entertainment: Musicals & Theatres': 13,
    'Entertainment: Television': 14,
    'Entertainment: Video Games': 15,
    'Entertainment: Board Games': 16,
    'Science & Nature': 17,
    'Science: Computers': 18,
    'Science: Mathematics': 19,
    Mythology: 20,
    Sports: 21,
    Geography: 22,
    History: 23,
    Politics: 24,
    Art: 25,
    Celebrities: 26,
    Animals: 27,
    Vehicles: 28,
    'Entertainment: Comics': 29,
    'Science: Gadgets': 30,
    'Entertainment: Japanese Anime & Manga': 31,
    'Entertainment: Cartoon & Animations': 32,
  };

  try {
    const { data } = await axios(
      `https://opentdb.com/api.php?amount=${amount}&category=${CategoriesId[category]}&type=multiple`,
      { withCredentials: false },
    );

    data.results = data.results
      .filter(({ question, correct_answer }) => question && correct_answer)
      .map((element) => {
        element.question = he.decode(element.question);
        element.correct_answer = he.decode(element.correct_answer);

        element.incorrect_answers = element.incorrect_answers.map((answer) => he.decode(answer));

        return element;
      });

    return data;
  } catch (error) {
    return { error };
  }
};

const updateStrapiQuiz = async (payload) => {
  try {
    const { data } = await axios.put(
      `${strapiUrl}/quizes/${payload.id}`,
      payload,
      {
        withCredentials: false,
      },
    );
    return data;
  } catch (error) {
    return { error };
  }
};

const getTiles = async () => {
  try {
    const { data } = await axios(`${strapiUrl}/welcometiles`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getStrapiArtist = async (id) => {
  try {
    const { data } = await axios.get(`${strapiUrl}/spotlights/${id}`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getAllStrapiArtists = async () => {
  try {
    const { data } = await axios.get(`${strapiUrl}/spotlights/`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getVideoElephantChannels = async () => {
  try {
    const { data } = await axios(`${strapiUrl}/videoelephantchannels`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getBroadcasts = async () => {
  try {
    const { data } = await axios(`${strapiUrl}/broadcast`, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const getMrssContent = async (link) => {
  try {
    const { data } = await axios.get(`${link}`, {
      withCredentials: false,
    });
    const items = xml2json(data, { compact: true, spaces: 4 });
    const result = JSON.parse(items);
    return result.rss.channel;
  } catch (error) {
    return { error };
  }
};

const getActiveStrapiShorts = async () => {
  try {
    const { data } = await axios.get(`${strapiUrl}/shorts/`, {
      withCredentials: false,
    });
    const activeData = data.filter((video) => video.Active);
    return { activeData };
  } catch (error) {
    return { error };
  }
};

const updateStrapiShorts = async (id, updates) => {
  try {
    const response = await axios.put(`${strapiUrl}/shorts/${id}`, updates, {
      withCredentials: false,
    });
    return response;
  } catch (error) {
    return { error };
  }
};

// send access token with request
const getMagniteAd = async () => {
  try {
    const { lat, lng } = await getLastLocation();

    const body = {
      userAgent: navigator.userAgent,
      lat,
      lng,
    };
    const { data } = await axios.post(`${serverUrl}/tablet/magnite`, body);
    return data;
    // response will be {ad: {...}}
  } catch (error) {
    return { error };
  }
};

const getStrapiThunderDungeons = async () => {
  try {
    const { data } = await axios.get(`${strapiUrl}/thunder-dungeons/`, {
      withCredentials: false,
    });
    const activeData = data.filter((video) => video.Active);
    return { activeData };
  } catch (error) {
    return { error };
  }
};

const updateStrapiThunderDungeons = async (id, updates) => {
  try {
    const response = await axios.put(
      `${strapiUrl}/thunder-dungeons/${id}`,
      updates,
      { withCredentials: false },
    );
    return response;
  } catch (error) {
    return { error };
  }
};

const reportImpressions = async (impressions) => axios.post(
  `${serverUrl}/tablet/impressions`,
  { impressions },
  axiosConfig,
);

const reportHeartbeat = async (duration, data) => {
  const {
    driverId, driver, imei, portlWebviewVersion, commit,
  } = await getAnalyticsInfo();

  const newItem = {
    driverId,
    driver,
    IMEI: imei,
    webview: portlWebviewVersion,
    commit,
    duration,
    data,
  };

  return axios.post(
    `${serverUrl}/tablet/heartbeats`,
    { heartbeats: newItem },
    axiosConfig,
  );
};

export {
  getStrapiToken,
  checkAuth,
  getDriverInfo,
  getHivestackAd,
  getProviderAdsRequest,
  getMedia,
  getImeiByEmail,
  getVideoElephantChannels,
  createZendeskTicket,
  getStrapiAds,
  getTourGuideCategories,
  getTourGuideItems,
  sendAuthSMSToDriver,
  localTabletLogin,
  queryCms,
  getStrapiPresets,
  getLocalNews,
  getStrapiQuiz,
  getStrapiQuizByName,
  getQuizScore,
  getPicturesContest,
  updateStrapiQuiz,
  getQuizQuestionsOpenTrivia,
  getAdomniAd,
  getTiles,
  getStrapiArtist,
  getActiveStrapiShorts,
  updateStrapiShorts,
  getAllStrapiArtists,
  getMagniteAd,
  getStrapiThunderDungeons,
  updateStrapiThunderDungeons,
  getMrssContent,
  getBroadcasts,
  reportImpressions,
  reportHeartbeat,
};
