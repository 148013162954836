import styled from 'styled-components';

export const Title = styled.div`
  padding-top: 10px;
  text-align: center;
  font-size: 65px;
  font-weight: bold;
  ${({ color }) => color && { color }}
`;

export const Score = styled.div`
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  ${({ color }) => color && { color }}
`;

export const TableItem = styled.div`
  display: grid;
  grid-template-columns: 84px 153px 186px;
  text-align: right;
  margin: 0 auto;
  width: fit-content;
  font-family: 'Lato', sans-serif;
  font-size: 30px;
  color: #1f2639;

  ${({ highlight }) => highlight && `
    color: #c40000;
    font-weight: 900;
  `}
`;

export const TableTitle = styled(TableItem)`
  margin-top: 15px;
  font-weight: bold;
`;
