/* eslint-disable import/prefer-default-export */
import React from 'react';
import Snowfall from 'react-snowfall';
import QRCode from 'qrcode.react';
import {
  QRWrapper, StartButton,
} from './style';
import { serverUrl } from '../../config';
import QuizBackground from '../../components/QuizBackground';

export const GAME_NAME = 'ChristmasQuiz';

export const QuizHome = ({ beginQuiz, privateCode, IMEI }) => (
  <QuizBackground src="/assets/christmasHome.webp" onClick={beginQuiz}>
    <Snowfall snowflakeCount={300} />
    <StartButton>
      GO!
    </StartButton>
    <QRWrapper>
      <QRCode
        value={`${serverUrl}/rider${GAME_NAME}/${IMEI}/${privateCode}`}
        size={127}
        level="Q"
        renderAs="svg"
      />
    </QRWrapper>
  </QuizBackground>
);
