import React, { useState, useEffect, useRef } from 'react';

// Components
import QuizOption from '../../components/QuizOption';
import CountdownTimer from '../../components/CountdownTimer';
import {
  Wrapper, ScoreStyled, Container, CounterContainer, PictureContainer,
} from './style';

// Helpers
import { socket } from '../../socket';

const SECONDS = 10;

const PictureQuizTemplate = ({
  score,
  setInfo,
  data,
  isSocket,
  optColor,
  optBck,
  color,
  optFontSize,
  totalQuestions,
  nextQuestion,
}) => {
  const isMounted = useRef(true);
  const scorePerQuestion = useRef();

  const [showAnswer, setShowAnswer] = useState(false);
  const [selected, setSelected] = useState();

  const delay = 2000;

  const gotNextQuestions = () => {
    setShowAnswer(false);
    setSelected();
    nextQuestion();
  };

  const checkAnswer = (i) => {
    setSelected(i);
    setShowAnswer(true);
    const scorePoints = scorePerQuestion.current.getScorePoints();

    if (data.options[i].isCorrect) {
      setInfo((prev) => ({ ...prev, score: prev.score + scorePoints, correct: prev.correct + 1 }));
    }
  };

  const handleClickSelection = (i) => {
    if (showAnswer || selected) return;

    checkAnswer(i);

    setTimeout(() => {
      if (isMounted.current) {
        gotNextQuestions();
      }
    }, delay);
  };

  useEffect(() => () => { isMounted.current = false; }, []);

  useEffect(() => {
    if (!isSocket) return null;

    socket.once('answerQuestion', ({ opt }) => {
      handleClickSelection(opt);
    });

    return () => socket.off('answerQuestion');
  });

  const handleTimerComplete = () => {
    setShowAnswer(true);

    setTimeout(() => {
      if (isMounted.current) gotNextQuestions();
    }, delay);
    return [true, delay];
  };

  return (
    <Wrapper color={color}>
      <ScoreStyled>
        Score:
        {score}
      </ScoreStyled>
      <Container>
        <CounterContainer>
          {data.number + 1}
          /
          {totalQuestions}
        </CounterContainer>
        <PictureContainer src={data.url} key={data.url} />
        <CountdownTimer
          ref={scorePerQuestion}
          duration={SECONDS}
          onComplete={handleTimerComplete}
          timerId={data.url}
          color={color}
          circleTimer
        />
      </Container>
      <>
        {
                data.options.map((opt, i) => (
                  <QuizOption
                    key={`key-${opt.text}`}
                    text={opt.text}
                    isCorrect={opt.isCorrect}
                    fontSize={optFontSize}
                    i={i}
                    showAnswer={showAnswer}
                    optColor={optColor}
                    optBck={optBck}
                    selected={selected}
                    onClickSelection={handleClickSelection}
                  />
                ))
            }
      </>
    </Wrapper>
  );
};

export default PictureQuizTemplate;
