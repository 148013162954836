import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
    position: relative;
    width: fit-content;
    margin: 0 auto;
`;

const InputName = styled.input`
    letter-spacing: ${(props) => (props.large ? '90px' : '10px')};
    text-indent: ${(props) => (props.large ? '45px' : '10px')};
    background: transparent;
    width: ${(props) => (props.large ? '350px' : '105px')};
    border: none;
    font-size: ${(props) => (props.large ? '36px' : '23px')};
    color: ${(props) => props.color || '#ffffff'};
    text-transform: uppercase;

    &:focus {
        outline: none;
    }
`;

const WhiteLine = styled.div`
    width: ${(props) => (props.large ? '89px' : '27px')};
    height: ${(props) => (props.large ? '3px' : '2px')};
    background-color: ${(props) => props.color || '#ffffff'};
    position: absolute;
    bottom: -4px;
    left: ${({ left }) => left}px;
`;

const QuizNameInput = ({
  name, handleNameChange, large, color,
}) => (
  <InputContainer>
    <InputName
      id="scoreName"
      type="text"
      maxLength={3}
      onChange={handleNameChange}
      value={name}
      large={large}
      color={color}
      autoComplete="off"
      role="presentation"
    />
    <WhiteLine left={4} large={large} color={color} />
    <WhiteLine left={large ? 130 : 37} large={large} color={color} />
    <WhiteLine left={large ? 260 : 71} large={large} color={color} />
  </InputContainer>
);

export default QuizNameInput;
