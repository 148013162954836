import React, { useState } from 'react';

import VideosGrid from '../components/VideosGrid';
import { data } from './CorusEntertainment/components';

const SoHelpMeTodd = () => {
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <VideosGrid data={data[1]} fullScreen={fullscreen} link="/assets/SHMT.mp4" setFullscreen={setFullscreen} currentVideo="SoHelpMeTodd" />
  );
};
export default SoHelpMeTodd;
