import styled from 'styled-components';

export const ANIMATION_TIME = 700;

export const AdContainer = styled.div`
  width: 100%;
  height: 100%;
  // background-color: #343434;
  background-color: white;

  // CSS for Ad Loop Transition
  & .ad-loop-appear {
    opacity: 0;
  }
  & .ad-loop-appear-active {
    opacity: 1;
    transition: opacity ${ANIMATION_TIME}ms ease;
  }
  & .ad-loop-enter {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  & .ad-loop-enter-active {
    opacity: 1;
    transition: opacity ${ANIMATION_TIME}ms ease;
  }
  & .ad-loop-exit {
    opacity: 1;
  }
  & .ad-loop-exit-active {
    opacity: 0;
    transition: opacity ${ANIMATION_TIME}ms ease;
  }
`;

export const LoadingContainer = styled.div`
  text-align: center;
  padding-top: 23%;
`;

export const BrandedImage = styled.img`
  height: 101%;
  width: 101%;
`;

export const ProgressContainer = styled.div`
  position: absolute;
  bottom: 0px;
  background-color: #464646;
  height: 9px;
  width: 100%;
  opacity: ${({ hide }) => (hide ? 0 : 0.8)};
  border-radius: 0 0 10px 10px;
  overflow: hidden;
`;

export const ProgressFill = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  border-radius: 10px;
  transition: width linear ${(props) => props.duration}s;
  width: ${(props) => props.width}%;
  ${(props) => props.width === 0 && 'transition: none;'}
`;
